import React from 'react';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Reports',
    path: '/reports',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Customers',
    path: '/customers',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Products',
    path: '/products',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Orders',
    path: '/sales-pending-order',
    icon: <IoIcons.IoMdPeople/>,
    cName: 'nav-text'
  },
  {
    title: 'Sales',
    path: '/sales-list',
    icon: <IoIcons.IoMdPeople/>,
    cName: 'nav-text'
  },
  {
    title: 'Exit',
    path: '/delivery-pending',
    icon: <IoIcons.IoMdPeople/>,
    cName: 'nav-text'
  },
  {
    title: 'Expenses',
    path: '/expense-list',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Stock',
    path: '/stock-in',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }
    ,
  {
    title: 'Payments',
    path: '/payments',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }

  ,
  {
    title: 'Cashing',
    path: '/cashing',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }

  ,
  {
    title: 'Credit',
    path: '/sales-pending-order-deposit',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  },
  {
    title: 'Users',
    path: '/users',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }
  ,
  {
    title: 'Settings',
    path: '/settings',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }
  ,
  {
    title: 'Container',
    path: '/container',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }
  ,
  {
    title: 'MAC access',
    path: '/access',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }

  ,
  {
    title: 'Otp',
    path: '/access-otp',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }

  ,
  {
    title: 'Stock Keeper',
    path: '/stockkeeper-pending-request',
    icon: <IoIcons.IoMdPeople />,
    cName: 'nav-text'
  }
   
  
];
