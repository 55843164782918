import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import VideService from "../../services/vide.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import { List } from "@material-ui/core";
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const VideLevelView = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stockname: "",
    current_stock_qty: "",
    pid:"",
  };

  const [product1, setProduct1] = useState("");
  const [pid1, setPid1] = useState("");
  const [code1, setCode1] = useState("");
  const [color1, setColor1] = useState("");
  const [size1, setSize1] = useState("");

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [currentDataChanged, setCurrentDataChanged] = useState(false);
  const [currentDataStock, setcurrentDataStock] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);


  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategorySelectedValueTransferDestination, setOptionCategorySelectedValueTransferDestination] = useState("");
  const [optionCategorySelectedValueTransferSource, setOptionCategorySelectedValueTransferSource] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }

  const handleChangeoptionCategoriesTransferDestination = (event) => {
    setOptionCategorySelectedValueTransferDestination(event.target.value);

  }

  const handleChangeoptionCategoriesTransferSource = (event) => {
    setOptionCategorySelectedValueTransferSource(event.target.value);

  }



  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };



  const newData = () => {
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };





  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {



    const params = getRequestParams(searchTitle, page, pageSize);

    ProductsService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getSelectOptionTransfer();

    openObject();

    getMemberData();


  };

  const getMemberData=()=>{

    MemberService.getMemberdetails(currentUser.username)
    .then((response) => {
    
        setMemberDetails(response.data);
         
        
    })
    .catch((e) => {
        console.log(e);
    });

}

  const getSelectOption = () => {
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = () => {

    VideService.getStockLevelById(props.match.params.id)
      .then(response => {
        setcurrentData(response.data);
         
        //console.log(response.data);
        
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const openObjectStock = (rowIndex) => {

    setCurrentDataChanged(true);

    const id = tutorialsRef.current[rowIndex].id;

    ProductsService.get(id)
      .then(response => {
        setcurrentDataStock(response.data);
        //setSearchedProductDetails(response.data);
        setProduct1(response.data.name);
        setCode1(response.data.code);
        setColor1(response.data.color);
        setSize1(response.data.size);
        setPid1(response.data.id);


      })
      .catch(e => {
        console.log(e);
      });



  };



  const updateData = () => {


    var data = {
      id: currentData.id,
      stockname: currentData.stockname,
      current_stock_qty: currentData.current_stock_qty,
      
    };

    if (currentDataChanged == false) {

      if (currentData.current_stock_qty == null || currentData.current_stock_qty == "") {

        //console.log("========qty=====" + currentData.current_stock_qty);

        setMessage("You can't save emply qty!");


      } else {


        VideService.updateStockLevel(currentData.pid, currentData)
          .then(response => {
            //console.log(response.data);
            setMessage("The Data was updated successfully!");
          })
          .catch(e => {
            console.log(e);
          });
      }



    } else {

      VideService.update(currentData.id, data)
        .then(response => {
          //console.log(response.data);
          setMessage("The Data was updated successfully!");
        })
        .catch(e => {
          console.log(e);
        });

    }


  };

 
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const add=()=>{
    props.history.push("/vide-new");
  }

  const list=()=>{
    props.history.push("/vide");
  }

  const stockLevel = () => {
    props.history.push("/vide-level");
  }

  


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle}  id="search" type="text" name="search" placeholder="Product code" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table>
            <tr>
              <td><span className="page-title-img"><FaIcons.FaWarehouse /> </span></td>
              <td><span className="page-title"> VIDE</span></td>
            </tr>
          </table>

        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
          <tr>
              <td class="tg-0lax"><button onClick={list} className="button-tools">Vide</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools-red">New</button></td>
              <td class="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
            </tr>
            <tr>
            <td class="tg-0lax"></td>
            </tr>
          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">

<center>
         
          <div className="details-small">
            <form className="form form-horizontal ">
              <div className="form-body">
                <div className="row">


                  <div class="col-md-4">
                    <label>PRODUCT</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="product" class="form-control" name="product" placeholder="Product" onChange={handleInputChangeEdit} validations={[required]} value={currentData.name} />
                  </div>

                  <div class="col-md-4">
                    <label>CODE</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="code" class="form-control" name="code" placeholder="Code" onChange={handleInputChangeEdit} validations={[required]} value={currentData.code} />
                  </div>

                  <div class="col-md-4">
                    <label>SIZE</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="size" class="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={currentData.size} />
                  </div>
                  <div class="col-md-4">
                    <label>COLOR</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="color" class="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={currentData.color} />
                  </div>
                  <div class="col-md-4">
                    <label>STOCK</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <select id="stand" name="stand" className="form-control" onChange={handleInputChangeEdit} >
                      <option>{currentData.stockname}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.stockname}</option>
                      ))}

                    </select>
                  </div>

                  <div class="col-md-4">
                    <label>CURRENT QTY</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" id="current_stock_qty" class="form-control" name="current_stock_qty" placeholder="CURRENT QTY" onChange={handleInputChangeEdit} validations={[required]} value={currentData.current_stock_qty} />
                  </div>

                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <label className="col-md-8 danger-message">{message}</label>

                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <div className="col-md-8 form-group">



                    <button onClick={updateData}
                      type="button" className="button-form"><ImFloppyDisk /> Save</button>

                    <button
                      type="button" onClick={stockLevel} className="button-form"><ImCancelCircle /> Close</button>

                  </div>







                </div>
              </div>
            </form>

          </div>
          </center>
        </div>
      </div>

    </div>
  );
});

export default VideLevelView;
