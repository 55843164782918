import React, { useState, useRef,useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
 import { format } from 'date-fns';
 import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();


const StockListReturn = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_STOCK")) {
    return <Redirect to="/unauthorized" />;

}

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",

    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",




    username: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }

 

  const handleExportToExcel = event => {
     console.log("=======data export=====");
  };

  
 

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchedProductDetails, setSearchedProductDetails] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeSearchProduct = (e) => {
    const searchProduct = e.target.value;
    setSearchProduct(searchProduct);

  };





  const retrieveData = () => {



    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "RETURNED"
    }

    StockService.getStockIn(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    getSelectOption();
    getSelectOptionTransfer();
    getMemberData();
  };

  const getMemberData=()=>{

    MemberService.getMemberdetails(currentUser.username)
    .then((response) => {
    
        setMemberDetails(response.data);
         
        
    })
    .catch((e) => {
        console.log(e);
    });

}

  const getSelectOption = () => {
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "IN",
      text4: searchTitle
    }

    StockService.getStockInSearch(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  const openObjectWithProduct = (id) => {
    setcurrentData(initialDataState);

    //const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/stock-returned/" + id);
  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };




  
   
  const logOut = () => {
    AuthService.logout();
  }




  const handleCallback = (data) => {

    setSearchedProductDetails(data);

  }

  const handleChangeEndDate = (date) => {
    
    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  

  const openObject = (rowIndex) => {
    
    // const id = tutorialsRef.current[rowIndex].id;

    // props.history.push("/product/" + id);

    setcurrentData(initialDataState);

    const id = tutorialsRef.current[rowIndex].id;


    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const stockOut=()=>{
    props.history.push("/stock-out");
  }

  const add=()=>{
    props.history.push("/stock-returned-new");
  }

  const stockLevel=()=>{
    props.history.push("/stock-level");
  }

  const returned=()=>{
    props.history.push("/stock-returned");
  }


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="customer" placeholder="Product name" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table className="table table-tools">

            <tr>
              <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
              <td>From</td>
              <td><DatePicker className="form-control datePicker-style"
                selected={startDate}
                onChange={handleChangeStartDate}
                name="startDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
            </tr>
            <tr>
              <td>To</td>
              <td ><DatePicker className="form-control datePicker-style"
                selected={endDate}
                onChange={handleChangeEndDate}
                name="endDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
            </tr>

          </table>




        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
          <tr>
              <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
              <td class="tg-0lax"><button onClick={returned} className="button-tools">Return</button></td>
            </tr>
            <tr>
              <td class="tg-0lax"><button onClick={stockLevel}  className="button-tools">Actual</button></td>
              <td class="tg-0lax"><button onClick={stockOut} className="button-tools">Out</button></td>
            </tr>
          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">
          <div className="row pagination-box">

            <div className="col-md-3 pagination-box">{"Items per Page : "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select></div>

            <div className="col-md-3 table-title">  RETURNED STOCK </div>

            <div className="col-md-4"><Pagination

              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />

            </div>



          </div>
          <div className="details">

            <div className="table-wrap">

              <table className="table ">
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>STAND</th>
                    <th>DATE</th>
                    <th>ENTRY ID</th>
                    <th>PID</th>
                    <th>PRODUCT</th>
                    <th>CODE</th>
                    <th>SIZE</th>
                    <th>QTY</th>
                    <th>STOCK KEEPER</th>
                    <th>DESCRIPTION</th>
                    <th>COMMENT</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.stand}</td>
                        <td>{tutorial.date1}</td>
                        <td>{tutorial.stockEntryId}</td>
                        <td>{tutorial.pid}</td>
                        <td>{tutorial.product}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.size}</td>
                        <td>{tutorial.returned}</td>
                        <td>{tutorial.stockkeeper}</td>
                        <td>{tutorial.description}</td>
                        <td>{tutorial.comment}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObjectWithProduct(tutorial.id)}
                          > <AiTwotoneEdit />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
});

export default StockListReturn;
