import React, { useState }  from 'react';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import './App.css';
import './style/forms.css';
import './style/AppLogin.css';
import './style/main.css';
import './style/table.css';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthService from './services/auth.service';
import Home from './components/home/Home';
import Login from './components/authentication/Login';
import ProductList from './components/product/Products-list';
import ProductView from './components/product/product-view';
import ProductNew from './components/product/product-new';
import Saleslist from './components/sales/Sales-summary';
//import Saleslist2 from './components/sales/Sales-summary2';
import SalesPendingOrders from './components/sales/sales-pending-orders';
import SalesPendingOrderSaller from './components/sales/sales-pending-order-saller';
import SalesPendingOrderDeposit from './components/sales/sales-pending-order-deposit';
import SalesOrderNew from './components/sales/Sales-orders-new';
import SalesOrderSuccess from './components/sales/sales-order-new-success';
import SalesReceipt from './components/sales/Sales-receipt';
import ExpenseList from './components/expenses/expense-list';
import ExpenseNew from './components/expenses/expense-new';
import ExpenseView from './components/expenses/expense-view';
//import StockListIn from './components/stock/stock-list-in';
import StockListInPages from './components/stock/stock-list-in-pages';
import StockInNew from './components/stock/stock-in-new';
import StockInView from './components/stock/stock-in-view';
import StockOutView from './components/stock/stock-out-view';
import StockListOut from './components/stock/stock-list-out';
import StockOutNew from './components/stock/stock-out-new';

import StockListLevel from './components/stock/stock-level-list';
import StockLevelViewTransfer from './components/stock/stock-level-view-transfer';
import StockLevelViewDelete from './components/stock/stock-level-view-delete';
import StockLevelView from './components/stock/stock-level-view';
import StockListReturn from './components/stock/stock-returned-list';
import StockReturnNew from './components/stock/stock-returned-new';
import StockReturnedView from './components/stock/stock-returned-view';
import StockListInInspect from './components/stock/stock-list-in-inspect';
import StockListOutInspect from './components/stock/stock-list-out-inspect';

import StockkeeperListSummary from './components/stockkeeper/stockkeper-list-summary';
import StockkeeperStockRequestForm from './components/stockkeeper/stockkeeper-stock-request-form';
import StockkeeperPendingRequest from './components/stockkeeper/stockkeeper-pending-request';

import PaymentsAll from './components/payment/paymentAll';
import PaymentForm from './components/payment/payment-form';
import PaymentsReport from './components/payment/payment-report';

import UsersLIst from './components/users/users-list';
import MemberView from './components/members/members-view';

import UserRegistration from './components/users/registration';

import CustomersList from './components/customers/customers-list';
import CustomerNew from './components/customers/customer-new';
import CustomerView from './components/customers/customer-view';
import CustomerSearch from './components/customers/customers-search';

import CashingList from './components/cashing/cashing-list';

import DeliveryListAll from './components/delivery/delivery-list-all';
import DeliveryView from './components/delivery/delivery-view';
import DeliveryReport from './components/delivery/delivery-report';
import DeliveryReportReste from './components/delivery/delivery-report -reste';
import DeliveryReportPending from './components/delivery/delivery-report -pending';
import DeliveryReportCompleted from './components/delivery/delivery-report -completed';
import DeliveryNoteAll from './components/delivery/delivery-note-all';
import DeliveryNoteReste from './components/delivery/delivery-note-reste';
import DeliveryListDelivered from './components/delivery/delivery-list-delivered';

import VideList from './components/vide/vide-list';
import VideNew from './components/vide/vide-new';
import VideView from './components/vide/vide-view';
import VideListLevel from './components/vide/vide-level-list';
import VideLevelView from './components/vide/vide-level-view';

import UnauthorizedAccess from './components/home/Unauthorized-access';
import UnauthorizedAccessDisabledAccount from './components/home/Unauthorized-access-disabled';

//import ShippingList from './components/shipping/Shipping-list';
//import ShippingNew from './components/shipping/Shipping-new';

import Reports from './components/report/reports-cashier';
import ReportsSales from './components/report/reports-sales';
import PrintReportCashier from './components/report/print-report-cashier';
import ReportSallers from './components/report/reports-sallers';
import ReportSallersPerformance from './components/report/reports-sallers-perfromance';
import ReportsJournal from './components/report/reports-journal';
import ReportsCashing from './components/report/reports-cashing';
import ReportStand from './components/report/reports-stand';
import ReportStandProfit from './components/report/reports-stand-profit';
import SettingList from './components/settings/settings-list';

import AccessList from './components/access-control/access-list';
import AccessOtpList from './components/access-control/access-otp-list';
import StockInitialList from './components/stock/stock-initial-list';
import StockKeeperReceipt from './components/stockkeeper/stockkeeper-receipt';


const currentUser = AuthService.getCurrentUser();

var isAuth=false;
var isAdmin=false;



 
const check =()=>{

  if (JSON.parse(localStorage.getItem("user")) == null) {

    isAuth = false;
    
  } else {
    isAuth = true;
    
    let user = currentUser;

    let decodedToken = jwt_decode(user.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

      isAuth = false;
      
    } else {
      isAuth = true;
      

    }
  }
 

}

  

function App() {


    check();

    
  return (
    <>
      <Router>
      {isAuth?(<Navbar />):("")}
        <Switch>
        <Route path='/' exact component={Login} />
          <Route path='/home' exact component={Home} />
          <Route path='/login' exact component={Login} />
          <Route path='/unauthorized' exact component={UnauthorizedAccess} />
          <Route path='/unauthorized-disabled' exact component={UnauthorizedAccessDisabledAccount} />
         
          {}
          <Route path='/products' component={ProductList} />
          <Route exact path="/product/:id" component={ProductView} />
          <Route path='/products-new' component={ProductNew} />

          <Route path='/customers' component={CustomersList} />
          <Route path='/customers-search' component={CustomerSearch} />
          <Route path='/customers-new' component={CustomerNew} />
          <Route path='/customer/:id' component={CustomerView} />


          <Route path='/sales-list' component={Saleslist} />
          {/* <Route path='/sales-list2' component={Saleslist2} /> */}
          <Route exact path="/sales-pending-order" component={SalesPendingOrders} />
          <Route exact path="/sales-pending-order-deposit" component={SalesPendingOrderDeposit} />
          <Route exact path="/sales-order-new" component={SalesOrderNew} />
          <Route exact path="/sales-order-success" component={SalesOrderSuccess} />
          <Route exact path="/sales-receipt" component={SalesReceipt} />

          <Route exact path="/expense-list" component={ExpenseList} />
          <Route exact path="/expense-new" component={ExpenseNew} />
          <Route exact path="/expense/:id" component={ExpenseView} />

          <Route exact path="/stock-in" component={StockListInPages} />
          {/* <Route exact path="/stock-in-report" component={StockListIn} /> */}
          <Route exact path="/stock-in-new" component={StockInNew} />
          <Route exact path="/stock-in/:id" component={StockInView} />
          <Route exact path="/stock-out/:id" component={StockOutView} />
          <Route exact path="/stock-out" component={StockListOut} />
          <Route exact path="/stock-out-new" component={StockOutNew} />
          <Route exact path="/stock-level" component={StockListLevel} />
          <Route exact path="/stock-level/:id" component={StockLevelView} />
          <Route exact path="/stock-level-transfer/:id" component={StockLevelViewTransfer} />
          <Route exact path="/stock-level-delete/:id" component={StockLevelViewDelete} />
          <Route exact path="/stock-returned" component={StockListReturn} />
          {/* <Route exact path="/stock-returned-new" component={StockReturnNew} /> */}
          {/* <Route exact path="/stock-returned/:id" component={StockReturnedView} /> */}
          <Route exact path="/stock-in-inspect" component={StockListInInspect} />
          <Route exact path="/stock-out-inspect" component={StockListOutInspect} />
          <Route exact path="/stock-initial" component={StockInitialList} />


          <Route exact path="/stockkeeper-request-form" component={StockkeeperStockRequestForm} />
          <Route exact path="/stockkeeper-list" component={StockkeeperListSummary} />
          <Route exact path="/stockkeeper-pending-request" component={StockkeeperPendingRequest} />
          <Route exact path="/stockkeeper-receipt" component={StockKeeperReceipt} />

          <Route exact path="/payments" component={PaymentsAll} />
          <Route exact path="/payments-report" component={PaymentsReport} />
          <Route exact path="/payment-form" component={PaymentForm} />

          <Route exact path="/cashing" component={CashingList} />
          {/* <Route exact path="/cashing-new" component={CashingNew} /> */}
          {/* <Route exact path="/cashing/:id" component={CashingView} /> */}

          <Route exact path="/users" component={UsersLIst} />
          <Route exact path="/member/:id" component={MemberView} />
          <Route exact path="/registration" component={UserRegistration} />

           <Route exact path="/deliveries" component={DeliveryListAll} />
          <Route exact path="/deliveries/:id" component={DeliveryView} />
          <Route exact path="/delivery-report" component={DeliveryReport} />
          <Route exact path="/delivery-reste" component={DeliveryReportReste} />
          <Route exact path="/delivery-pending" component={DeliveryReportPending} />
          <Route exact path="/delivery-completed" component={DeliveryListDelivered} />
          <Route exact path="/delivery-note" component={DeliveryNoteAll} />
          <Route exact path="/delivery-note-reste" component={DeliveryNoteReste} />
          <Route exact path="/vide" component={VideList} />
          <Route exact path="/vide-new" component={VideNew} />
          <Route exact path="/vide-view/:id" component={VideView} />
          <Route exact path="/vide-level" component={VideListLevel} />
          <Route exact path="/vide-level/:id" component={VideLevelView} /> 

          {/* <Route exact path="/container" component={ShippingList} /> */}
          {/* <Route exact path="/container-new" component={ShippingNew} /> */}
          <Route exact path="/reports" component={Reports} /> 
          <Route exact path="/reports-cashier" component={PrintReportCashier} />
          <Route exact path="/reports-sallers" component={ReportSallers} />
          <Route exact path="/reports-stand" component={ReportStand} />
          <Route exact path="/reports-stand-profit" component={ReportStandProfit} />
          <Route exact path="/reports-sales" component={ReportsSales} />
          <Route exact path="/reports-journal" component={ReportsJournal} />
          <Route exact path="/reports-cashing" component={ReportsCashing} />
          <Route exact path="/reports-performance" component={ReportSallersPerformance} />
          <Route exact path="/settings" component={SettingList} />
          <Route exact path="/access" component={AccessList} />
          <Route exact path="/access-otp" component={AccessOtpList} />
        </Switch>
      </Router>
    </>
  );
}

export default App;