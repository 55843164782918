import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CashingService from "../../services/cashing.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();


const CashingList = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_CASHIER")) {
    return <Redirect to="/unauthorized" />;

  }


  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);


  const initialDataState = {
    id: null,
    date: "",
    cashier: "",
    amount: "",
    headCashier: "",
    headCashierAmount: "",
    comment: "",
    fiveThousands: "",
    twoThousands: "",
    oneThousands: "",
    fiveHundred: "",
    onehundred: "",
    fifty: "",
    twenty: "",
    ten: "",
    five: "",
    two: "",
    one: "",

    euro: 0,
    euroRate: 0,
    euro2: 0,
    euroRate2: 0,
    euro3: 0,
    euroRate3: 0,
    euro4: 0,
    euroRate4: 0,

    dollar: 0,
    dollarRate: 0,
    dollar2: 0,
    dollarRate2: 0,
    dollar3: 0,
    dollarRate3: 0,
    dollar4: 0,
    dollarRate4: 0,
    caisseInit: 0,
    pointerName: "",
    pointerAmount: "",
    receivedBy: "",



    username: "",

  };

  const [tutorial, setData] = useState(initialDataState);
  // const [submitted, setSubmitted] = useState(false);
  const [memberdetails, setMemberDetails] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [currentData, setcurrentData] = useState([]);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [amountValidated, setAmountValidated] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [isHeadCashier, setIsHeadCashier] = useState(false);

  const [tutorials, setDatas] = useState([]);
  const tutorialsRef = useRef();

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [cashierName, setCashierName] = useState("");

  const [page, setPage] = useState(1);
  const [count] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 500, 1000];

  tutorialsRef.current = tutorials;


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }


  const retrieveData = () => {
    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);

    } else {
      setCashierName(currentUser.username);
    }


    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: cashierName
    }


    CashingService.getAll(data)
      .then((response) => {

        setDatas(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    getMemberData();
    getSelectOption();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

    if (currentUser.roles.includes("ROLE_HEADCASHIER")) {
      setIsHeadCashier(true);

    }

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  };


  useEffect(retrieveData, [page, pageSize]);



  const refreshList = () => {
    setPage(1);
    retrieveData();
  };


  const openObject = (id) => {
    setPage(1);

    setMessage("");

    setcurrentData(initialDataState);
    // const id = tutorialsRef.current[rowIndex].id;


    CashingService.get(id)
      .then(response => {
        setcurrentData(response.data);
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  };



  const validateData = () => {
    setPage(1);

    if (tutorial.amount === null || tutorial.amount === "") {
      setMessage("Amount missing");
    }
    else if (tutorial.fiveThousands === null || tutorial.fiveThousands === "") {
      setMessage("5000 missing");
    }
    else if (tutorial.twoThousands === null || tutorial.twoThousands === "") {
      setMessage("2000 missing");
    }
    else if (tutorial.oneThousands === null || tutorial.oneThousands === "") {
      setMessage("1000 missing");
    }
    else if (tutorial.fiveHundred === null || tutorial.fiveHundred === "") {
      setMessage("500 missing");
    }
    else if (tutorial.onehundred === null || tutorial.onehundred === "") {
      setMessage("100 missing");
    }
    else if (tutorial.fifty === null || tutorial.fifty === "") {
      setMessage("50 missing");
    }
    else if (tutorial.twenty === null || tutorial.twenty === "") {
      setMessage("20 missing");
    }
    else if (tutorial.ten === null || tutorial.ten === "") {
      setMessage("10 missing");
    }
    else if (tutorial.five === null || tutorial.five === "") {
      setMessage("5 missing");
    }
    else if (tutorial.two === null || tutorial.two === "") {
      setMessage("2 missing");
    }
    else if (tutorial.one === null || tutorial.one === "") {
      setMessage("1 missing");
    }

    else if (tutorial.dollar === null || tutorial.dollar === "") {
      setMessage("Dollar 1 is missing.");
    }
    else if (tutorial.dollarRate === null || tutorial.dollarRate === "") {
      setMessage("Dollar Rate1 is missing.");
    }

    else if (tutorial.dollar2 === null || tutorial.dollar2 === "") {
      setMessage("Dollar 2 is missing.");
    }
    else if (tutorial.dollarRate2 === null || tutorial.dollarRate2 === "") {
      setMessage("Dollar 2 rate is missing.");
    }
    else if (tutorial.dollar3 === null || tutorial.dollar3 === "") {
      setMessage("Dollar 3 is missiong.");
    }
    else if (tutorial.dollarRate3 === null || tutorial.dollarRate3 === "") {
      setMessage("Dollar Rate 3 is missing.");
    }
    else if (tutorial.dollar4 === null || tutorial.dollar4 === "") {
      setMessage("dollar 4 is missing.");
    }
    else if (tutorial.dollarRate4 === null || tutorial.dollarRate4 === "") {
      setMessage("Dollar 4 rate is missiong.");
    }

    else {

      var data = {
        id: null,
        date: tutorial.date,
        cashier: currentUser.username,
        amount: tutorial.amount,
        headCashier: tutorial.headCashier,
        headCashierAmount: tutorial.headCashierAmount,
        comment: tutorial.comment,
        fiveThousands: tutorial.fiveThousands,
        twoThousands: tutorial.twoThousands,
        oneThousands: tutorial.oneThousands,
        fiveHundred: tutorial.fiveHundred,
        onehundred: tutorial.onehundred,
        fifty: tutorial.fifty,
        twenty: tutorial.twenty,
        ten: tutorial.ten,
        five: tutorial.five,
        two: tutorial.two,
        one: tutorial.one,

        euro: tutorial.euro,
        euroRate: tutorial.euroRate,
        euro2: tutorial.euro2,
        euroRate2: tutorial.euroRate2,
        euro3: tutorial.euro3,
        euroRate3: tutorial.euroRate3,
        euro4: tutorial.euro4,
        euroRate4: tutorial.euroRate4,

        dollar: tutorial.dollar,
        dollarRate: tutorial.dollarRate,
        dollar2: tutorial.dollar2,
        dollarRate2: tutorial.dollarRate2,
        dollar3: tutorial.dollar3,
        dollarRate3: tutorial.dollarRate3,
        dollar4: tutorial.dollar4,
        dollarRate4: tutorial.dollarRate4,
        caisseInit: tutorial.caisseInit,
        pointerName: tutorial.pointerName,
        pointerAmount: tutorial.pointerAmount,
        receivedB: tutorial.receivedB,

        username: currentUser.username
      };

      CashingService.validate(data)
        .then(response => {

          setAmountValidated(response.data);
          setIsValidated(true);


        })
        .catch(e => {
          console.log(e);
        });

    }

  }

  const saveData = () => {

    setLgShow(false);

    setPage(1);

    if (tutorial.amount === null || tutorial.amount === "") {
      setMessage("Amount missing");
    }
    else if (tutorial.fiveThousands === null || tutorial.fiveThousands === "") {
      setMessage("5000 missing");
    }
    else if (tutorial.twoThousands === null || tutorial.twoThousands === "") {
      setMessage("2000 missing");
    }
    else if (tutorial.oneThousands === null || tutorial.oneThousands === "") {
      setMessage("1000 missing");
    }
    else if (tutorial.fiveHundred === null || tutorial.fiveHundred === "") {
      setMessage("500 missing");
    }
    else if (tutorial.onehundred === null || tutorial.onehundred === "") {
      setMessage("100 missing");
    }
    else if (tutorial.fifty === null || tutorial.fifty === "") {
      setMessage("50 missing");
    }
    else if (tutorial.twenty === null || tutorial.twenty === "") {
      setMessage("20 missing");
    }
    else if (tutorial.ten === null || tutorial.ten === "") {
      setMessage("10 missing");
    }
    else if (tutorial.five === null || tutorial.five === "") {
      setMessage("5 missing");
    }
    else if (tutorial.two === null || tutorial.two === "") {
      setMessage("2 missing");
    }
    else if (tutorial.one === null || tutorial.one === "") {
      setMessage("1 missing");
    }
    // else if(amountValidated !== tutorial.amount){
    //   setMessage("Amount not matching!");
    // }

    else {

      var data = {
        id: null,
        date: tutorial.date,
        cashier: currentUser.username,
        amount: tutorial.amount,
        headCashier: tutorial.headCashier,
        headCashierAmount: tutorial.headCashierAmount,
        comment: tutorial.comment,
        fiveThousands: tutorial.fiveThousands,
        twoThousands: tutorial.twoThousands,
        oneThousands: tutorial.oneThousands,
        fiveHundred: tutorial.fiveHundred,
        onehundred: tutorial.onehundred,
        fifty: tutorial.fifty,
        twenty: tutorial.twenty,
        ten: tutorial.ten,
        five: tutorial.five,
        two: tutorial.two,
        one: tutorial.one,

        euro: tutorial.euro,
        euroRate: tutorial.euroRate,
        euro2: tutorial.euro2,
        euroRate2: tutorial.euroRate2,
        euro3: tutorial.euro3,
        euroRate3: tutorial.euroRate3,
        euro4: tutorial.euro4,
        euroRate4: tutorial.euroRate4,

        dollar: tutorial.dollar,
        dollarRate: tutorial.dollarRate,
        dollar2: tutorial.dollar2,
        dollarRate2: tutorial.dollarRate2,
        dollar3: tutorial.dollar3,
        dollarRate3: tutorial.dollarRate3,
        dollar4: tutorial.dollar4,
        dollarRate4: tutorial.dollarRate4,
        caisseInit: tutorial.caisseInit,
        pointerName: tutorial.pointerName,
        pointerAmount: tutorial.pointerAmount,
        receivedB: tutorial.receivedB,

        username: currentUser.username
      };

      CashingService.create(data)
        .then(response => {

          // setSubmitted(true);
          setMessage("Saved successfully.");
          refreshList();
          //console.log(response.data);

        })
        .catch(e => {
          console.log(e);
        });

    }

  }

  const updateData = () => {
    setPage(1);

    if (currentData.headCashierAmount === null || currentData.headCashierAmount === "") {

      setMessage("Head cashier amount is empty");

    } else if (currentData.comment === null || currentData.comment === "") {

      setMessage("Comment is empty");

    }


    CashingService.update(currentData.id, currentData)
      .then(response => {

        // setMessage("Updated successfully")
        retrieveData();
      })
      .catch(e => {
        console.log(e);
      });

  }



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const confirm = () => {

    setMessage("");

    var data = {
      text1: currentData.id,
      text2: currentData.headCashierAmount,
      text3: currentData.comment,
      text4: currentData.receivedBy,
      text5: currentUser.username
    }

    if (currentData.headCashierAmount === null || currentData.headCashierAmount === "") {
      setMessage("Amount is empty!");
    } else if (currentData.headCashierAmount < 1) {
      setMessage("Amount is 0");
    } else if (currentData.comment === null || currentData.comment === "") {
      setMessage("Comment is empty");
    } else {

      CashingService.confirm(data)
        .then(response => {

          const { message } = response.data;
          setMessage(message);
          retrieveData();
        })
        .catch(e => {
          console.log(e);
        });


    }


  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td>{memberdetails.account}</td>
                  </tr>
                </tbody>
              </div>
            </table>

          </div>
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>

                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                {isManager ? (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>) : (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option key={currentUser.username}>{currentUser.username}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>)}

              </tbody>
            </table>

          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><Button onClick={() => setLgShow(true)} className="button-tools-red">Add</Button></td>
                </tr>
              </tbody>

            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  CASHING </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>DATE</th>
                      <th>AMOUNT</th>
                      <th>CASHIER</th>
                      <th>HEAD CASHIER</th>
                      <th>H. CASHIER AMOUNT</th>
                      <th>COMMENT</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.date}</td>
                          <td>{tutorial.amount}</td>
                          <td>{tutorial.cashier}</td>
                          <td>{tutorial.headCashier}</td>
                          <td>{tutorial.headCashierAmount}</td>
                          <td>{tutorial.comment}</td>

                          <td><div className="table-action-button">

                            <button
                              type="button" className="btn table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <span onClick={() => setLgShow2(true)}>  <AiFillFolderOpen /> </span>
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            New Cashing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>Validation</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="amountValidated" className="form-control" name="amountValidated" placeholder="Amount validated" onChange={handleInputChange} validations={[required]} value={amountValidated} />
                      </div>

                      <div className="col-md-4">
                        <label>Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChange} validations={[required]} />
                      </div>



                      <div className="col-md-4">
                        <label>5000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="fiveThousands" className="form-control" name="fiveThousands" placeholder="5000" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>2000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="twoThousands" className="form-control" name="twoThousands" placeholder="2000" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4">
                        <label>1000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="oneThousands" className="form-control" name="oneThousands" placeholder="1000" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>500</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="fiveHundred" className="form-control" name="fiveHundred" placeholder="500" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>100</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="onehundred" className="form-control" name="onehundred" placeholder="100" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>50</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="fifty" className="form-control" name="fifty" placeholder="50" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>20</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="twenty" className="form-control" name="twenty" placeholder="20" onChange={handleInputChange} />
                      </div>

                      <div className="col-md-4">
                        <label>10</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="ten" className="form-control" name="ten" placeholder="10" onChange={handleInputChange} />
                      </div>

                      <div className="col-md-4">
                        <label>5</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="five" className="form-control" name="five" placeholder="5" onChange={handleInputChange} />
                      </div>

                      <div className="col-md-4">
                        <label>2</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="two" className="form-control" name="two" placeholder="2" onChange={handleInputChange} />
                      </div>

                      <div className="col-md-4">
                        <label>1</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="one" className="form-control" name="one" placeholder="1" onChange={handleInputChange} />
                      </div>

                      <div className="col-md-4">
                        <label>Ayaraye muri caisse </label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="caisseInit" className="form-control" name="caisseInit" placeholder="Ayaraye muri caisse" onChange={handleInputChange} />
                      </div>

                      <label className="col-md-8 danger-message">{message}</label>

                      <div className="col-md-6">

                        {isValidated ? (<button onClick={saveData}
                          type="button" className="button-tools-green"> Save  </button>) : (<button onClick={validateData}
                            type="button" className="button-tools-red">Validate</button>)}

                      </div>
                      <div className="col-md-6">
                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form">Close</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </center>

          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cashing Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChange} validations={[required]} value={currentData.amount} />
                      </div>


                      <div className="col-md-4">
                        <label>5000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="fiveThousands" className="form-control" name="fiveThousands" placeholder="5000" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fiveThousands} />
                      </div>

                      <div className="col-md-4">
                        <label>2000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="twoThousands" className="form-control" name="twoThousands" placeholder="200" onChange={handleInputChangeEdit} validations={[required]} value={currentData.twoThousands} />
                      </div>
                      <div className="col-md-4">
                        <label>1000</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="number" id="oneThousands" className="form-control" name="oneThousands" placeholder="1000" onChange={handleInputChangeEdit} validations={[required]} value={currentData.oneThousands} />
                      </div>

                      <div className="col-md-4">
                        <label>500</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="number" id="fiveHundred" className="form-control" name="fiveHundred" placeholder="500" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fiveHundred} />
                      </div>

                      <div className="col-md-4">
                        <label>100</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="onehundred" className="form-control" name="onehundred" placeholder="100" onChange={handleInputChangeEdit} validations={[required]} value={currentData.onehundred} />
                      </div>

                      <div className="col-md-4">
                        <label>50</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="fifty" className="form-control" name="fifty" placeholder="50" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fifty} />
                      </div>

                      <div className="col-md-4">
                        <label>20</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="twenty" className="form-control" name="twenty" placeholder="20" onChange={handleInputChangeEdit} value={currentData.twenty} />
                      </div>

                      <div className="col-md-4">
                        <label>10</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="ten" className="form-control" name="ten" placeholder="10" onChange={handleInputChangeEdit} value={currentData.ten} />
                      </div>

                      <div className="col-md-4">
                        <label>5</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="five" className="form-control" name="five" placeholder="5" onChange={handleInputChangeEdit} value={currentData.five} />
                      </div>

                      <div className="col-md-4">
                        <label>2</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="two" className="form-control" name="two" placeholder="2" onChange={handleInputChangeEdit} value={currentData.two} />
                      </div>

                      <div className="col-md-4">
                        <label>1</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="one" className="form-control" name="one" placeholder="1" onChange={handleInputChangeEdit} value={currentData.one} />
                      </div>

                      <div className="col-md-4">
                        <label>Ayaraye muri caisse</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="caisseInit" className="form-control" name="caisseInit" placeholder="Ayaraye muri caisse" onChange={handleInputChangeEdit} value={currentData.caisseInit} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-4">
                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form">Close</button>
                      </div>

                    </div>
                  </div>
                </form>

              </div>

            </center>

          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default CashingList;
