import React, { useState, useEffect, useRef } from "react";
import { withRouter } from 'react-router';
import {  Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import { ImPencil,ImCancelCircle } from "react-icons/im";



const currentUser = AuthService.getCurrentUser();

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };



const MemberView = withRouter((props) => {


    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        email: "",
        firstname: "",
        lastname: "",
        account: "",
        phone: "",
        joiningdate: "",
         
        
    };

    
    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");

     

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

     

    

    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    
    const [pageSize, setPageSize] = useState(100);
 
    

    tutorialsRef.current = tutorials;

    
     
    const retrieveData = () => {
        setPage(1);

            openObject();
    };

    useEffect(retrieveData, [page, pageSize]);

     
    

    const openObject = (rowIndex) => {
        setPage(1);

        setcurrentData(initialDataState);

       // const id = tutorialsRef.current[rowIndex].id;
     

        MemberService.get(props.match.params.id)
            .then(response => {
                setcurrentData(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        //props.history.push("/member/" + id);
    };

    const updateData = () => {
        setPage(1);
        console.log("updating......");
        MemberService.update(currentData.id, currentData)
            .then(response => {
                console.log(response.data);
                setMessage("The Data was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteData = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;

        MemberService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    


    return (
        <div className="pages">

            {/*********************************************first row ******************************** *  */}


            
            {/********************************************* start   row ******************************** *  */}

            <div className="dataRow">

                <div className="details-small">
                  
                      

                            <span className="form-title">MEMBER DETAILS</span>

                            <hr></hr>


                            <div className="form-body-bg">

                                <center>
                                    <form className="form form-horizontal ">
                                        <div className="form-body">
                                            <div className="row">

                                            <div className="col-md-4">
                                                    <label>FIrst Name</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname}  />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Lastname</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="text" id="lastname" className="form-control" name="lastname" placeholder="Lastname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastname}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Phone</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Account</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Email</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="email" id="email" className="form-control" readonly="readonly" name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Join Date</label>
                                                </div>
                                                <div className="col-md-8 form-group">
                                                    <input type="text" id="joiningdate" className="form-control" name="joiningdate" placeholder="yyyy-mm-dd" onChange={handleInputChangeEdit} validations={[required]} value={currentData.joiningdate}/>
                                                </div>

                                                 
                                                <div className="col-md-4">
                                                    <label> </label>
                                                </div>
                                                <label className="col-md-8">{message}</label>

                                                <div className="col-md-4">
                                                    <label> </label>
                                                </div>

                                                <div className="col-md-8 form-group">
                                                    <button onClick={updateData}
                                                        type="button" className="btn form-buttons"><ImPencil/> Edit</button>
                                                     
                                                        <a href="/members"> 
                                                        <button
                                                        type="button" className="btn form-buttons"><ImCancelCircle/> Close</button>
                                                        </a>
                                                </div>





                                            </div>
                                        </div>
                                    </form>

                                </center>
                            </div>

                       
                </div>


            </div>
            {/********************************************* end 4th row ******************************** *  */}







        </div>
    );
});

export default MemberView;
