import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import { List } from "@material-ui/core";
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const StockReturnedView = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_STOCK")) {
    return <Redirect to="/unauthorized" />;

}

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",

    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    username: "",
    returned: "",

    published: false,

  };

  const [product1, setProduct1] = useState("");
  const [pid1, setPid1] = useState("");
  const [code1, setCode1] = useState("");
  const [color1, setColor1] = useState("");
  const [size1, setSize1] = useState("");

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [currentDataChanged, setCurrentDataChanged] = useState(false);
  const [currentDataStock, setcurrentDataStock] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategorySelectedValueTransferDestination, setOptionCategorySelectedValueTransferDestination] = useState("");
  const [optionCategorySelectedValueTransferSource, setOptionCategorySelectedValueTransferSource] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  
  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }

  const handleChangeoptionCategoriesTransferDestination = (event) => {
    setOptionCategorySelectedValueTransferDestination(event.target.value);

  }

  const handleChangeoptionCategoriesTransferSource = (event) => {
    setOptionCategorySelectedValueTransferSource(event.target.value);

  }



  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };



  const newData = () => {
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };





  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {



    const params = getRequestParams(searchTitle, page, pageSize);

    ProductsService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getSelectOptionTransfer();

    openObject();


    getMemberData();

  };

  const getMemberData = () => {

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (rowIndex) => {

    // const id = tutorialsRef.current[rowIndex].id;

    // props.history.push("/product/" + id);

    //setcurrentData(initialDataState);

    //const id = tutorialsRef.current[rowIndex].id;


    StockService.get(props.match.params.id)
      .then(response => {
        setcurrentData(response.data);
        //setSearchedProductDetails(response.data);
        //console.log(response.data);
        setProduct1(response.data.product);
        setCode1(response.data.code);
        setColor1(response.data.color);
        setSize1(response.data.size);
        setPid1(response.data.id);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const openObjectStock = (rowIndex) => {

    setCurrentDataChanged(true);

    const id = tutorialsRef.current[rowIndex].id;

    ProductsService.get(id)
      .then(response => {
        setcurrentDataStock(response.data);
        //setSearchedProductDetails(response.data);
        setProduct1(response.data.name);
        setCode1(response.data.code);
        setColor1(response.data.color);
        setSize1(response.data.size);
        setPid1(response.data.id);


      })
      .catch(e => {
        console.log(e);
      });



  };



  const updateData = () => {


    var data = {
      stand: currentData.stand,
      date1: currentData.date1,
      pid: pid1,
      product: product1,
      qty_in: currentData.qty_in,
      stockkeeper: currentUser.username,
      code: code1,
      size: size1,
      qty_out: currentData.qty_out,
      qty_transfer_in: currentData.qty_transfer_in,
      return: currentData.returned,
      qty_transfer_out: currentData.qty_transfer_out,
      qty_damage: currentData.qty_damage,
      stand_destination: currentData.stand_destination,
      stand_source: currentData.stand_source,
      description: currentData.description,
      comment: currentData.comment,
      transfer_type: currentData.transfer_type,
      color: color1
    };

    if (currentDataChanged == false) {

      if (currentData.returned == null || currentData.returned == "") {

        setMessage("You can't save emply qty!");


      } else {


        StockService.updateReturned(currentData.id, currentData)
          .then(response => {
            //console.log(response.data);
            setMessage("The Data was updated successfully!");
          })
          .catch(e => {
            console.log(e);
          });
      }



    } else {

      StockService.update(currentData.id, data)
        .then(response => {
          //console.log(response.data);
          setMessage("The Data was updated successfully!");
        })
        .catch(e => {
          console.log(e);
        });

    }


  };

  const deleteData = (rowIndex) => {
    const id = tutorialsRef.current[rowIndex].id;

    StockService.remove(id)
      .then((response) => {
        /// props.history.push("/product");

        let newDatas = [...tutorialsRef.current];
        newDatas.splice(rowIndex, 1);

        setDatas(newDatas);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };



  const stockOut = () => {
    props.history.push("/stock-out");
  }

  const add = () => {
    props.history.push("/stock-in-new");
  }

  const list = () => {
    props.history.push("/stock-returned");
  }

  const stockLevel = () => {
    props.history.push("/stock-level");
  }

  const returned=()=>{
    props.history.push("/stock-returned");
  }


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="search" placeholder="Product code" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table>
            <tr>
              <td><span className="page-title-img"><FaIcons.FaWarehouse /> </span></td>
              <td><span className="page-title"> STOCK SUPPY</span></td>
            </tr>
          </table>

        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
          <tr>
              <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
              <td class="tg-0lax"><button onClick={returned} className="button-tools">Return</button></td>
            </tr>
            <tr>
              <td class="tg-0lax"><button onClick={stockLevel}  className="button-tools">Actual</button></td>
              <td class="tg-0lax"><button onClick={stockOut} className="button-tools">Out</button></td>
            </tr>

          </table>
        </div>


        {/* end card box */}



        <div className="dataRow-with-two-column ">


          <div className="details">

            <div className="table-wrap">
              <table>
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>CODE</th>
                    <th>NAME</th>
                    <th>COLOR</th>
                    <th>SIZE</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.name}</td>
                        <td>{tutorial.color}</td>
                        <td>{tutorial.size}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiFillFolderOpen />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>
            </div>


          </div>

          <div className="details">
            <form className="form form-horizontal ">
              <div className="form-body">
                <div className="row">


                  <div class="col-md-4">
                    <label>Product</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="product" class="form-control" name="product" placeholder="Product" onChange={handleInputChangeEdit} validations={[required]} value={product1} />
                  </div>

                  <div class="col-md-4">
                    <label>Code</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="code" class="form-control" name="code" placeholder="Code" onChange={handleInputChangeEdit} validations={[required]} value={code1} />
                  </div>

                  <div class="col-md-4">
                    <label>Size</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="size" class="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={size1} />
                  </div>
                  <div class="col-md-4">
                    <label>Color</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="color" class="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={color1} />
                  </div>
                  <div class="col-md-4">
                    <label>Stock</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <select disabled='true' id="stand" name="stand" className="form-control" onChange={handleInputChangeEdit} >
                      <option disabled='true'>{currentData.stand}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>

                  <div class="col-md-4">
                    <label>QTY RETURNED</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" id="returned" class="form-control" name="returned" placeholder="returned" onChange={handleInputChangeEdit} validations={[required]} value={currentData.returned} />
                  </div>



                  <div class="col-md-4">
                    <label>Description</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="text" id="description" class="form-control" name="description" placeholder="Description" onChange={handleInputChangeEdit} validations={[required]} value={currentData.description} />
                  </div>

                  <div class="col-md-4">
                    <label>Comment</label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="text" id="comment" class="form-control" name="comment" placeholder="Comment" onChange={handleInputChangeEdit} validations={[required]} value={currentData.comment} />
                  </div>


                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <label className="col-md-8 danger-message">{message}</label>

                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <div className="col-md-8 form-group">



                    <button onClick={updateData}
                      type="button" className="button-form"><ImFloppyDisk /> Save</button>

                    <button
                      type="button" onClick={list} className="button-form"><ImCancelCircle /> Close</button>

                  </div>







                </div>
              </div>
            </form>

          </div>

        </div>
      </div>

    </div>
  );
});

export default StockReturnedView;
