import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { useReactToPrint } from 'react-to-print';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import PaymentsService from "../../services/payments.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';

const currentUser = AuthService.getCurrentUser();

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.getMemberData = this.getMemberData.bind(this);

    this.state = {

      currentOrderData: [],
      currentOrderDataSummary: [],
      firstname: "",
      lastname: "",
      branch: "",
      date1: props.order.date1,
      date2: props.order.date2

    };


  }



  getData(id) {
    

    var data = {
      text1: this.state.date1,
      text2: this.state.date2,
      text3:"imukunzi@gmail.com"
    }
    PaymentsService.journal(data).then((response) => {


      this.setState({
       currentOrderDataSummary:response.data,
         
      });


    })
      .catch((e) => {
        console.log(e);
      });;



  }

  componentDidMount() {
    this.getMemberData();
    this.getData();



  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getMemberData = () => {

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {


        this.setState({
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          branch: response.data.account
        });


      })
      .catch((e) => {
        console.log(e);
      });

  }

  render() {


    return (
      <div className="printing-page">
        <center>
          <h2>Golden Fashion</h2>


          <hr />
        </center>


        <div className="dataRow">
          <div className="details-printing">


            <table>
              <tr>
                <td><h5>Cashier :{this.state.firstname} {this.state.lastname} </h5></td>

              </tr>
              <tr>
                <td><h5>Branch :{this.state.branch}</h5></td>
              </tr>
              <tr>
                <td><h5>From :{this.state.date1} to {this.state.date2}</h5></td>
              </tr>

            </table>
            <hr />
            <center>
              <h4>Journal</h4>
            </center>

            <div className="table-wrap">




            </div>
          </div>

          <p>

            <div id="wrapper">

              <div id="c0">
                Cash : {this.state.currentOrderDataSummary.cash} <br /><hr />
                MoMo : {this.state.currentOrderDataSummary.momo}  <br /><hr />
                MoMoCharges : {this.state.currentOrderDataSummary.momoCharges}  <br /><hr />
                Visa : {this.state.currentOrderDataSummary.visa}  <br /><hr />
                Bank : {this.state.currentOrderDataSummary.transfer} <br /><hr />
                Cheque : {this.state.currentOrderDataSummary.cheque} <br /><hr />
                <br /> 
                 Total : {this.state.currentOrderDataSummary.total}  

              </div>

            </div>
          </p>

        </div>
      </div>
    );
  }
}




const PaymentsReport = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",


  };



  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [memberdetails, setMemberDetails] = useState([]);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }



  const pageSizes = [100, 500, 1000];


  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const [tutorials, setDatas] = useState([]);

  const tutorialsRef = useRef();

  tutorialsRef.current = tutorials;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleChangeSearchText = (event) => {

    setSearchText(event.target.value);

  }


  const retrieveOrderedData = () => {
    setPage(1);

    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
    }
    PaymentsService.getAll(data)
      .then((response) => {


        setcurrentData(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
//getSelectOption();

  }



  //useEffect(getMemberData);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };





  const openObject = (id) => {

    // props.history.push("/product/" + id);

    setcurrentData(initialDataState);

    // const id = tutorialsRef.current[rowIndex].id;


    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };



  const journal = (data) => {

  }


  const search = () => {

    var data = {
      text1: searchText,

    }
    PaymentsService.search(data)
      .then((response) => {


        setcurrentData(response.data);



      })
      .catch((e) => {
        console.log(e);
      });


  }

  const getSelectOption = () => {
    var category = "SALLER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveOrderedData);


  const pendingOrder = (data) => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const componentRef = useRef(null);
  const [lgShow, setLgShow] = useState(false);

  const componentRef2 = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef2.current,
  });

  var selectedData = {

    date1: format(startDate, 'yyyy-MM-dd'),
    date2: format(endDate, 'yyyy-MM-dd'),

  }
  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table table-tools">
              <tr>
                <td ><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                <td>Cashier</td> 
                <td> <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>
             
               
            </table>


          </div>
          <div className="col-md-3 tool-box">
            <table className="table table-tools">

              <tr>
                <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                <td>From</td>
                <td><DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>
              <tr>
                <td>To</td>
                <td ><DatePicker className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>

            </table>




          </div>

          <div className="col-md-3 tool-box">

            <table className="table table-tools">
              <tr>
                <td class="tg-0lax" rowspan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax"></td>
              </tr>
              <tr>
                <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                <td class="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">Journal</button></td>
              </tr>


            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  PAYMENTS </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>Order No</th>
                      <th>Customer</th>
                      <th>Time</th>
                      <th>Saller</th>
                      <th>Amount</th>
                      <th>Cash</th>
                      <th>MoMo</th>
                      <th>MoMoCharges</th>
                      <th>Cheque</th>
                      <th>Transfer</th>
                      <th>Visa</th>
                      <th>Cashier</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentData &&
                      currentData.map((tutorial, index) => (

                        <tr>
                          <td>{tutorial.orderno}</td>
                          <td>{tutorial.customer}</td>
                          <td>{tutorial.payment_time}</td>
                          <td>{tutorial.saller}</td>
                          <td>{tutorial.total_received}</td>
                          <td>{tutorial.cash}</td>
                          <td>{tutorial.momo}</td>
                          <td>{tutorial.momoCharges}</td>
                          <td>{tutorial.cheque}</td>
                          <td>{tutorial.transfer}</td>
                          <td>{tutorial.visa}</td>
                          <td>{tutorial.cashier}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>
      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">



              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div>
            <button onClick={handlePrint} className="button-form">Print</button>



            <ComponentToPrint ref={componentRef2} order={selectedData} />

          </div>


        </Modal.Body>
      </Modal>
    </>

  );
});

export default PaymentsReport;
