import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GrValidate } from 'react-icons/gr';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen,AiOutlinePhone } from 'react-icons/ai';
import { BsPersonBoundingBox } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ExpensesService from "../../services/expense.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ExpenseList = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_CASHIER")) {
    return <Redirect to="/unauthorized" />;

  }

  const initialDataState = {
    id: null,
    expenseType: "",
    amount: "",
    other: "",

    username: "",

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
 
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");


  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");

  const componentRef = useRef(null);

  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

 

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (isManager == true) {
      setRole("Manager");
    }

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (role) {
      params["role"] = role;
    }

    if (memberdetails.account) {
      params["location"] = memberdetails.account;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (currentUser.username) {
      params["username"] = currentUser.username;
    }

    if (optionCategorySelectedValue) {
      params["cashier"] = optionCategorySelectedValue;
    }

    if (isReport) {
      params["report"] = isReport;
    }

        

    return params;
  };


  const retrieveData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);
  
    }else{
      setCashierName(currentUser.username);
    }

     
    var data ={
      text1:format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2:format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3:memberdetails.account,
      text4:cashierName,
      text5:searchTitle
    }



    ExpensesService.getAll(data)
      .then((response) => {

        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });



   
    getSelectOption();
   
    getMemberData();
  };

  

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
    
  };

 

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setPage(1);

    props.history.push("/expense/" + id);
  };

  const add = () => {
    props.history.push("/expense-new");
  }




  const logOut = () => {
    AuthService.logout();
  }


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  

  const pendingOrder = () => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = () => {
    props.history.push("/sales-pending-order-deposit");
  }

  const newOrder = () => {
    props.history.push("/customers-search");
  }

  const receipt = (id) => {
    props.history.push(`/sales-receipt/?id=${currentOrderDataSummary.orderNo}`);
    setLgShow(false);
  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const searchOrder = () => {
    setPage(1);
   
  }

 

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

          <table className=" table-tools">
            <div className="table-account">
            <tbody>
              
              <tr>
                <td><div className="table-name">{memberdetails.firstname}</div></td>
                <td><div className="table-name">{memberdetails.lastname}</div></td>
              </tr>
              <tr>
                <td>Branch:</td>
                <td> <div className="table-name">{memberdetails.account}</div></td>
              </tr>
              </tbody>
              </div>
            </table>


          </div>
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>

              <tr>
                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                <td><DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
                <td><DatePicker className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>
              {isManager ? (<tr>
                <td>Cashier</td>
                <td>
                  <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>):(<tr>
                <td>Cashier</td>
                <td>
                  <div className="col-md-8 form-group">
                    <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                      <option>{currentUser.username}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>)}
             
</tbody>
            </table>




          </div>

          <div className="col-md-3 tool-box">

          <table className="table-tools">
            <tbody>
            <tr>
              <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
              <td className="tg-0lax"></td>
              <td className="tg-0lax"></td>
            </tr>
            <tr>
              <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
            </tr>
           
            </tbody>

          </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  EXPENSES * </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">
              <table className="table ">
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>DATE</th>
                    <th>EXPENSE TYPE</th>
                    <th>AMOUNT</th>
                    <th>OTHER</th>
                    <th>USERNAME</th>
                    <th>STATUS</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr key={tutorial.id}>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.date}</td>
                        <td>{tutorial.expenseType}</td>
                        <td>{tutorial.amount}</td>
                        <td>{tutorial.other}</td>
                        <td>{tutorial.username}</td>
                        <td><span className={"status " + tutorial.other}>{tutorial.other}</span></td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiFillFolderOpen />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">



              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div className="dataRow">
            <div className="details">


              <table>
                <tr>
                  <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>

                </tr>
                <tr>
                  <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                </tr>
                <tr>
                  <td><h5>Date :{currentOrderDataSummary.date}</h5></td>
                </tr>
                <tr>
                  <td><h5>Total :{currentOrderDataSummary.total}</h5></td>
                </tr>
                <tr>
                  <td><h5>Payed :{currentOrderDataSummary.payed}</h5></td>
                </tr>
                <tr>
                  <td><h5>Balance :{currentOrderDataSummary.balance}</h5></td>
                  <td><button onClick={receipt} className="button-form">Print</button></td>
                  <td><button onClick={() => setLgShow(false)} className="button-form">Close</button></td>
                </tr>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>PRICE</th>
                      <th>QTY</th>
                      <th>TOTAL</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.price}</td>
                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

                Cash:{cash} <br></br>
                MoMo:{momo} <br></br>
                Visa:{visa} <br></br>
                Cheque:{cheque} <br></br>
                Transfer:{transfer} <br></br>

              </div>
            </div>


          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default ExpenseList;
