import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/accesslist", {params, headers: authHeader() });
};

const get = (id) => {
  return http.get(`/accesslist/${id}`,{ headers: authHeader() });
};

const getAllowedAccess = (username) => {
  return http.get(`/accesslistuser/${username}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/newaccess", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/accesslist/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/accesslist/${id}`,{ headers: authHeader()});
};


const findByTitle = (title) => {
  return http.get(`/access?code=${title}`);
};

const AccessControlService = {
  getAll,
  get,
  getAllowedAccess,
  create,
  update,
  remove,
  findByTitle,
};

export default AccessControlService;
