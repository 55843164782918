import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import { List } from "@material-ui/core";
import MemberService from "../../services/members.service";
import AccessControlOtpService from "../../services/access-control-otp.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const StockLevelViewDelete = withRouter((props) => {


    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_STOCK")) {
        return <Redirect to="/unauthorized" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        stockname: "",
        current_stock_qty: "",
        pid: "",
        
    };


    const [product1, setProduct1] = useState("");
    const [pid1, setPid1] = useState("");
    const [code1, setCode1] = useState("");
    const [color1, setColor1] = useState("");
    const [size1, setSize1] = useState("");

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [access_code, setAccess_code] = useState("");
    const [currentData, setcurrentData] = useState(initialDataState);
    const [currentDataChanged, setCurrentDataChanged] = useState(false);
    const [currentDataStock, setcurrentDataStock] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);


    const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);

    const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
    const [optionCategorySelectedValueTransferDestination, setOptionCategorySelectedValueTransferDestination] = useState("");
    const [optionCategorySelectedValueTransferSource, setOptionCategorySelectedValueTransferSource] = useState("");
    const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);
    const [priceAuthorizationCode, setPriceAuthorizationCode] = useState("");
    const [messageAuthorization, setMessageAuthorization] = useState("");

    const [transfer_to, setTransfer_to] = useState("");
    const [qty_to_tansfer, setQty_to_tansfer] = useState("");
    
    const handleInputChangeAuthorization = (e) => {

        const code = e.target.value;
        setPriceAuthorizationCode(code);
        //console.log("======147====" + code);
    
      };


    const handleInputChangeAccessCode = (event) => {
        setAccess_code(event.target.value);

    }


    const handleInputChangeQty_to_tansfer = (event) => {
        setQty_to_tansfer(event.target.value);

    }


    const handleChangeoptionCategories = (event) => {
        setOptionCategorySelectedValue(event.target.value);

    }

    const handleChangeoptionCategoriesTransfer = (event) => {
        setOptionCategorySelectedValueTransfer(event.target.value);

    }

    const handleChangeoptionCategoriesTransferDestination = (event) => {
        setOptionCategorySelectedValueTransferDestination(event.target.value);

    }

    const handleChangeoptionCategoriesTransferSource = (event) => {
        setOptionCategorySelectedValueTransferSource(event.target.value);

    }



    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };



    const newData = () => {
        setData(initialDataState);

        setSubmitted(false);
        setMessage("");
    };

    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);



    const pageSizes = [100, 500, 1000];

    tutorialsRef.current = tutorials;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };





    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };

    const retrieveData = () => {

        setPage(1);

        const params = getRequestParams(searchTitle, page, pageSize);

        ProductsService.getAll(params)
            .then((response) => {
                const { tutorials, totalPages } = response.data;

                setDatas(tutorials);
                setCount(totalPages);


            })
            .catch((e) => {
                console.log(e);
            });

        getSelectOption();
        getSelectOptionTransfer();

        openObject();

        getMemberData();


    };

    const getMemberData = () => {

        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }

    const getSelectOption = () => {

        setPage(1);

        var category = "stock-name";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {

                setOptionCategoryArray(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getSelectOptionTransfer = () => {

        setPage(1);

        var category = "Stock-transfer-type";
        SelectOptionService.getAllSelectedOptionsTransferType(category)
            .then((response) => {


                setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(retrieveData, [page, pageSize]);

    const refreshList = () => {
        setPage(1);
        retrieveData();
    };



    const findByTitle = () => {
        setPage(1);
        retrieveData();
    };

    const openObject = () => {

        setPage(1);

        StockService.getStockLevelById(props.match.params.id)
            .then(response => {
                setcurrentData(response.data);

                //console.log(response.data);

            })
            .catch(e => {
                console.log(e);
            });

        // props.history.push("/stock/" + id);
    };

    const openObjectStock = (rowIndex) => {

        setPage(1);

        setCurrentDataChanged(true);

        const id = tutorialsRef.current[rowIndex].id;

        ProductsService.get(id)
            .then(response => {
                setcurrentDataStock(response.data);
                //setSearchedProductDetails(response.data);
                setProduct1(response.data.name);
                setCode1(response.data.code);
                setColor1(response.data.color);
                setSize1(response.data.size);
                setPid1(response.data.id);


            })
            .catch(e => {
                console.log(e);
            });



    };



    const deleteData = () => {

        setMessageAuthorization("Incorrect code!");
        setMessage("");
    
        //console.log("ID:"+selectedRowForAuthorization+" code:"+priceAuthorizationCode);        
    
        AccessControlOtpService.checkIfDeleteCodeExist(access_code)
          .then((response) => {
    
            const { isAuthorized, authorizedBy, authorizedBranch } = response.data;

             
            //console.log("ID:"+selectedRowForAuthorization+" code:"+priceAuthorizationCode+" response:"+response.data);
    
            if (isAuthorized === 1 & memberdetails.account === authorizedBranch) {    
              
    
              StockService.deleteStockLevel(currentData.id)
                .then(response => {
        
                  setMessage("Data deleted.");
                  setSubmitted(true);
    
                })
                .catch(e => {
                  console.log(e);
    
                });
    
    
            }else{
                setMessageAuthorization("Incorrect access code!");
                setMessage("Incorrect access code!");
            }
    
    
          })
          .catch((e) => {
            console.log(e);
          });
    
      }
    

     
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };



    const stockOut = () => {
        props.history.push("/stock-out");
    }

    const add = () => {
        props.history.push("/stock-in-new");
    }

    const list = () => {
        props.history.push("/stock");
    }

    const stockLevel = () => {
        props.history.push("/stock-level");
    }

    const returned = () => {
        props.history.push("/stock-returned");
    }


    

    return (
        <div className='pages'>

            <div className="row">



                {/* cards */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{memberdetails.firstname}</td>
                                <td>{memberdetails.lastname}</td>
                            </tr>
                            <tr>
                                <td>{memberdetails.account}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="search" placeholder="Product code" className="search-input" /> </td>
                                <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                <div className="col-md-3 tool-box">
                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FaIcons.FaWarehouse /> </span></td>
                                <td><span className="page-title"> STOCK SUPPY</span></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>

                                <td className="tg-0lax"><button onClick={list} className="button-tools">New</button></td>
                                <td className="tg-0lax"><button onClick={returned} className="button-tools">Return</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
                                <td className="tg-0lax"><button onClick={stockOut} className="button-tools">Out</button></td>
                            </tr>

                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>

                        <div className="details-small">
                            <form className="form form-horizontal ">
                                <div className="form-body">
                                    <div className="row">


                                        <div className="col-md-4">
                                            <label>PRODUCT </label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Product" onChange={handleInputChangeEdit} value={currentData.name} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>CODE</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChangeEdit} value={currentData.code} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>SIZE</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} value={currentData.size} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>COLOR</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} value={currentData.color} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>VALUE</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="number" id="price_value" className="form-control" name="price_value" placeholder="Color" onChange={handleInputChangeEdit} value={currentData.price} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>STOCK</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <select id="stand" disabled name="stand" className="form-control" onChange={handleInputChangeEdit} >
                                                <option key={currentData.stockname}>{currentData.stockname}</option>
                                                {optionCategoryArray.map((optionCategory) => (
                                                    <option key={optionCategory.value}>{optionCategory.value}</option>
                                                ))}

                                            </select>
                                        </div>

                                        <div className="col-md-4">
                                            <label>CURRENT QTY</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" disabled id="current_stock_qty" className="form-control" name="current_stock_qty" placeholder="CURRENT QTY" onChange={handleInputChangeEdit} value={currentData.current_stock_qty} />
                                        </div>


                                        <div className="col-md-4 danger-message">
                                            <label>ACCESS CODE</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="access_code" className="form-control" name="access_code" placeholder="ACCESS CODE" onChange={handleInputChangeAccessCode} value={access_code} />
                                        </div>



                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>

                                        <label className="col-md-8 danger-message">{message}</label>

                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>

                                        <div className="col-md-8 form-group">

                                        {submitted ? (""):(<button onClick={deleteData}
                                                type="button" className="button-form"> Delete</button>)}

                                            {/* <button onClick={deleteData}
                                                type="button" className="button-form"> Delete</button> */}

                                            <button
                                                type="button" onClick={stockLevel} className="button-form"> Close</button>

                                        </div>


                                    </div>
                                </div>
                            </form>

                        </div>
                    </center>
                </div>
            </div>

        </div>
    );
});

export default StockLevelViewDelete;
