import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";


import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import StockService from "../../services/stock.service";
import AuthService from "../../services/auth.service";
import VideService from "../../services/vide.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import { List } from "@material-ui/core";
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const VideNew = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_out: "",
    exitState:"",
    qty_returned: "",
    returnedState: "",
    stockkeeper: "",
    code: "",
    size: "",
    description: "",
    comment: "",
    stockEntryId: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [stockEntryId, setStockEntryId] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueVideState, setOptionCategorySelectedValueVideState] = useState("");
    const [optionCategoryArrayVideState, setOptionCategoryArrayVideState] = useState([]);

  const [memberdetails, setMemberDetails] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesVideState = (event) => {
    setOptionCategorySelectedValueVideState(event.target.value);

  }



  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const saveData = () => {



    var data = {
      //stand: optionCategorySelectedValue,
      stand: memberdetails.account,
      date1: format(new Date(), 'yyyy-MM-dd'),
      pid: currentData.id,
      product: currentData.name,
      stockkeeper: currentUser.username,
      code: currentData.code,
      size: currentData.size,
      qty_out: tutorial.qty_out,
      exitState: optionCategorySelectedValueVideState,
      qty_returned: tutorial.qty_returned,
      returnedState: tutorial.returnedState,
      description: tutorial.description,
      comment: tutorial.comment,
      stockEntryId: stockEntryId
    };

 


    if (data.product === null || data.product === "") {
      setMessage("Product details are missing!");
    } else if (data.stand == null || data.stand == "" || data.stand == "Select") {
      setMessage("Select valid stock or stand!");
    }else if(data.qty_out == null || data.qty_out==""){
      setMessage("Invalid qty!");

    }else if(data.exitState===null || data.exitState===""){
      setMessage("Select valid vide state!");
    }
    else {

      VideService.create(data)
        .then(response => {
          setData({
            stand: response.data.stand,
            date1: response.data.date1,
            pid: response.data.id,
            product: response.data.product,
            stockkeeper: response.data.stockkeeper,
            code: response.data.code,
            size: response.data.size,
            qty_out: response.data.qty_out,
            exitState: response.data.exitState,
            qty_returned: response.data.qty_returned,
            returnedState: response.data.returnedState,
            description: response.data.description,
            comment: response.data.comment,
            stockEntryId: response.data.stockEntryId

          });
          setSubmitted(true);
          setMessage("Data saved successfully.");
          //console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });

    }


  };

  const newData = () => {
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };





  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {



    const params = getRequestParams(searchTitle, page, pageSize);

    ProductsService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);


        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
     
    getStockEntryId();
    getMemberData();
  };

  const getMemberData=()=>{

    MemberService.getMemberdetails(currentUser.username)
    .then((response) => {
    
        setMemberDetails(response.data);
         
        
    })
    .catch((e) => {
        console.log(e);
    });

}

  const getSelectOption = () => {
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

      var category1 = "VIDE-STATE";
    SelectOptionService.getAllSelectedOptions(category1)
      .then((response) => {

        setOptionCategoryArrayVideState(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  
  const getStockEntryId = () => {

    StockService.getStockEntryCode()
      .then((response) => {

        setStockEntryId(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setcurrentData(initialDataState);

    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const updateData = () => {
    StockService.update(currentData.id, currentData)
      .then(response => {
        //console.log(response.data);
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });

  };

  const deleteData = (rowIndex) => {
    const id = tutorialsRef.current[rowIndex].id;

    StockService.remove(id)
      .then((response) => {
        /// props.history.push("/product");

        let newDatas = [...tutorialsRef.current];
        newDatas.splice(rowIndex, 1);

        setDatas(newDatas);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const add=()=>{
    props.history.push("/vide-new");
  }

  const list=()=>{
    props.history.push("/vide");
  }

  const stockLevel=()=>{
    props.history.push("/vide-level");
  }


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="search" placeholder="Product code" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table>
            <tr>
              <td><span className="page-title-img"><FaIcons.FaWarehouse /> </span></td>
              <td><span className="page-title"> VIDE </span></td>
            </tr>
          </table>

        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
          <tr>
              <td class="tg-0lax"><button onClick={list} className="button-tools">Vide</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools-red">New</button></td>
              <td class="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
            </tr>
            <tr>
            <td class="tg-0lax"></td>
            </tr>
          </table>
        </div>


        {/* end card box */}



        <div className="dataRow-with-two-column ">


          <div className="details">

            <div className="table-wrap">
              <table className="table">
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>CODE</th>
                    <th>NAME</th>
                    <th>COLOR</th>
                    <th>SIZE</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.name}</td>
                        <td>{tutorial.color}</td>
                        <td>{tutorial.size}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiFillFolderOpen />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>
            </div>


          </div>

          <div className="details">
            <form className="form form-horizontal ">
              <div className="form-body">
                <div className="row">


                  <div className="col-md-4">
                    <label> ENTRY ID</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="sotckEntryId" className="form-control" name="stockEntryId" placeholder="Stock Entry Id" value={stockEntryId} />
                  </div>

                  <div className="col-md-4">
                    <label>PRODUCT</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Item name" onChange={handleInputChange} validations={[required]} value={currentData.name} />
                  </div>

                  <div className="col-md-4">
                    <label>CODE</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChange} validations={[required]} value={currentData.code} />
                  </div>

                  <div className="col-md-4">
                    <label>SIZE</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChange} validations={[required]} value={currentData.size} />
                  </div>

                  
                  <div className="col-md-4">
                    <label>STOCK</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <select disabled='true' className="form-control" onChange={handleChangeoptionCategories} >
                      <option>{memberdetails.account}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>

                  <div className="col-md-4">
                    <label>QTY OUT</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input type="number" id="qty_out" className="form-control" name="qty_out" placeholder="QTY OUT" onChange={handleInputChange} validations={[required]} value={tutorial.qty_out} />
                  </div>

                  <div className="col-md-4">
                    <label>STATE</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <select  className="form-control" onChange={handleChangeoptionCategoriesVideState} >
                      <option>Select</option>
                      {optionCategoryArrayVideState.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>

                  <div className="col-md-4">
                    <label>DESTINATION</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input type="text" id="description" className="form-control" name="description" placeholder="Destination" onChange={handleInputChange} validations={[required]} value={tutorial.description} />
                  </div>
                  
                  <div className="col-md-4">
                    <label>COMMENT</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input type="text" id="comment" className="form-control" name="comment" placeholder="Comment" onChange={handleInputChange} validations={[required]} value={tutorial.comment} />
                  </div>


                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <label className="col-md-8 danger-message">{message}</label>

                  <div className="col-md-4">
                    <label> </label>
                  </div>




                  <div className="col-md-8 form-group">


                    {submitted ? (<button onClick={newData}
                      className="button-form"><ImPlus /> Add</button>) : (<button className="button-form" onClick={saveData}
                        type="button" className="button-form"><ImFloppyDisk /> Save</button>)}

                    <button
                      type="button" onClick={list} className="button-form"><ImCancelCircle /> Close</button>


                  </div>





                </div>
              </div>
            </form>

          </div>

        </div>
      </div>

    </div>
  );
});

export default VideNew;
