import React, { useState, useRef, useEffect } from "react";
import { render } from "react-dom";
import { useReactToPrint } from "react-to-print";


import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import OrderService from "../../services/order.service";
import StockkeeperService from "../../services/stockkeeper.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";
import { getDefaultLocale } from "react-datepicker";
import hoursToMinutes from "date-fns/esm/hoursToMinutes/index.js";

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);

    this.state = {

      currentOrderData: [],
      currentOrderDataSummary: [],
      cash: "",
      momo: "",
      visa: "",
      cheque: "",
      transfer: "",

    };

    this.getData();


  }



  getData() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    

    StockkeeperService.getRequestByNo(id)
      .then(response => {

        this.setState({
          currentOrderDataSummary: response.data.currentOrderDataSummary,
          currentOrderData:response.data.currentOrderData,
          
        
        });


      })
      .catch(e => {
        console.log(e);
      });

   

  }

  render() {


    return (
      <div className="printing-page">
        <center>
          <h2>YES PHONE</h2>
          <h6></h6>

          <hr />
        </center>


        <div className="dataRow">
          <div className="details-printing">


            <table className="table2">
              <tbody>
                <tr>
                  <td>StockKeeper Name :{this.state.currentOrderDataSummary.stockkeeper_name}</td>

                </tr>
                <tr>
                  <td>Approval :{this.state.currentOrderDataSummary.stockkeeper_approval}</td>

                </tr>
                <tr>
                  <td>StandKeeper Name :{this.state.currentOrderDataSummary.stand_supply_request_officer}</td>

                </tr>

                <tr>
                  <td>Date :{this.state.currentOrderDataSummary.stockkeeper_approval_date}</td>
                </tr>
                <tr>
                  <td>Stand :{this.state.currentOrderDataSummary.stand}</td>
                </tr>
                <tr>
                  <td>PRINTING: :{this.state.currentOrderDataSummary.printing_version}</td>
                </tr>
              </tbody>
            </table>
            <center>
              <h4>STOCK REQUEST</h4>
            </center>
            <hr />
            <div className="table-wrap">


              <table className="table table2">
                <thead className="scroll-thead">
                  <tr>
                    <th>ITEM</th>
                    <th>CODE</th>
                    <th>SIZE</th>
                    <th>COLOR</th>
                    <th>QTY</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {this.state.currentOrderData &&
                    this.state.currentOrderData.map((tutorial, index) => (

                      <tr key={tutorial.stockEntryId}>
                        <td>{tutorial.product}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.size}</td>
                        <td>{tutorial.color}</td>
                        <td>{tutorial.stockkeeper_approval_qty}</td>
                      
                      </tr>

                    ))}
                </tbody>

              </table>

            </div>
          </div>



          <div id="wrapper">




            <div className="c2">
              <table className="table2">
                <tbody>

                  {/* <tr>
                    <td>Total :{this.state.currentOrderDataSummary.total}</td>
                  </tr>
                  <tr>
                    <td>Payed :{this.state.currentOrderDataSummary.payed}</td>
                  </tr>
                  <tr>
                    <td>Balance :{this.state.currentOrderDataSummary.balance}</td>

                  </tr>
                  <tr>
                    <td>Cash :{this.state.cash}</td>
                  </tr>
                  <tr>
                    <td>MoMo :{this.state.momo}</td>
                  </tr>
                  <tr>
                    <td>Visa :{this.state.visa}</td>
                  </tr>
                  <tr>
                    <td>Cheque :{this.state.cheque}</td>
                  </tr>
                  <tr>
                    <td>Transfer :{this.state.transfer}</td>
                  </tr> */}
                </tbody>
              </table>


            </div>

          </div>


        </div>
      </div>
    );
  }
}

const currentUser = AuthService.getCurrentUser();


const StockKeeperReceipt = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_SALES")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const pageSizes = [100, 300, 100];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    
  });

  

  const updatePrintingVersion=()=>{

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

  
    StockkeeperService.updatePrintingVersion(id)
        .then(response => {
          

        })
        .catch(e => {
          console.log(e);
        });
  }

  const list = () => {
    props.history.push("/sales-list");
  };

  const home = () => {
    props.history.push("/home");
  };

  const retrieveData = () => {

    setPage(1);

  }

  useEffect(retrieveData, [page, pageSize]);

  return (

    <div className='pages'>
    <span onClick={updatePrintingVersion}>  <button onClick={handlePrint} className="button-tools">Print</button></span>
       <button onClick={list} className="button-tools">Sales</button> 
      <button onClick={home} className="button-tools">Home</button>
      <ComponentToPrint ref={componentRef} />



    </div>





  );
});

export default StockKeeperReceipt;
