import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { FcServices } from 'react-icons/fc';
import * as FaIcons from 'react-icons/fa';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CashingService from "../../services/cashing.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import { Select } from "@material-ui/core";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();


const SettingList = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    return <Redirect to="/unauthorized" />;

  }


  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);


  const initialDataState = {
    id: null,
    category: "",
    username: "",
    value: "",
    other: "",
    saller_access_code: "",


  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [memberdetails, setMemberDetails] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [currentData, setcurrentData] = useState([]);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [amountValidated, setAmountValidated] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [isHeadCashier, setIsHeadCashier] = useState(false);

  const [tutorials, setDatas] = useState([]);
  const tutorialsRef = useRef();

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [cashierName, setCashierName] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 500, 1000];

  tutorialsRef.current = tutorials;


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
        params["code"] = searchTitle;
    }

    if (page) {
        params["page"] = page - 1;
    }

    if (pageSize) {
        params["size"] = pageSize;
    }

    return params;
};


  const retrieveData = () => {
    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

        SelectOptionService.getAll(params)
            .then((response) => {
                const { tutorials, totalPages } = response.data;

                setDatas(tutorials);
                setCount(totalPages);

                // console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
            
    getMemberData();
    getSelectOption();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

    if (currentUser.roles.includes("ROLE_HEADCASHIER")) {
      setIsHeadCashier(true);

    }

  }

  const getSelectOption = () => {
    setPage(1);

   
    SelectOptionService.getAllCategory()
      .then((response) => {

        setOptionCategoryArray(response.data);
        
      })
      .catch((e) => {
        console.log(e);
      });

  };


  useEffect(retrieveData, [page, pageSize]);



  const refreshList = () => {
    setPage(1);
    retrieveData();
  };


  const openObject = (id) => {
    setPage(1);

    setMessage("");

    setcurrentData(initialDataState);
    // const id = tutorialsRef.current[rowIndex].id;


    SelectOptionService.get(id)
      .then(response => {
        setcurrentData(response.data);
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  };

  const handleSearch = () => {
    setPage(1);
    retrieveData();
};

const onChangeSearchTitle = (e) => {
  const searchTitle = e.target.value;
  setSearchTitle(searchTitle);
};


  

  const saveData = () => {
    setPage(1);

    if (tutorial.category === null || tutorial.category === "") {
      setMessage("Select Valid category");
    }
    else if (tutorial.category === "NA") {
      setMessage("Select Valid category");
    }else if (tutorial.value === null || tutorial.value === "") {
      setMessage("Value is mission");
    }
    
   
    else {

      var data = {
        id: null,
        category: tutorial.category,
        value: tutorial.value,
        other: tutorial.other,
        username: currentUser.username,
       

      };

      SelectOptionService.create(data)
        .then(response => {

          setSubmitted(true);
         // newData();
         window.location.reload();

        })
        .catch(e => {
          console.log(e);
        });

    }

  }

  const updateData = () => {
    setPage(1);

    if (currentData.headCashierAmount === null || currentData.headCashierAmount === "") {

      setMessage("Head cashier amount is empty");

    } else if (currentData.comment === null || currentData.comment === "") {

      setMessage("Comment is empty");

    }


    SelectOptionService.update(currentData.id, currentData)
      .then(response => {

        // setMessage("Updated successfully")
        retrieveData();
        
      })
      .catch(e => {
        console.log(e);
      });

      newData();
      setLgShow2(false);

  }



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const confirm = () => {

   setIsValidated(true);


  }

  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setIsValidated(false);
    setMessage("");

  };

  const remove = () => {

    SelectOptionService.remove(currentData.id)
    .then(response => {

      // setMessage("Updated successfully")
      retrieveData();
    })
    .catch(e => {
      console.log(e);
    });
    newData();
    setLgShow2(false);

  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                            <td> <input  type="text" value={searchTitle}
                                        onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                            <td><button onClick={handleSearch} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                        </tr>
              </tbody>

            </table>

          </div>
         

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><Button onClick={() => setLgShow(true)} className="button-tools-red">Add</Button></td>
                </tr>
              </tbody>

            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  SELECT OPTIONS </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>CATEGORY</th>
                      <th>USERNAME</th>
                      <th>VALUE</th>
                      <th>OTHER</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.category}</td>
                          <td>{tutorial.username}</td>
                          <td>{tutorial.value}</td>
                          <td>{tutorial.other}</td>


                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <span onClick={() => setLgShow2(true)}>  <AiFillFolderOpen /> </span>
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cashing Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>CATEGORY</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="category" name="category" onChange={handleInputChange} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.id}>{optionCategory.category}</option>
                        ))}

                      </select>
                      </div>

                      <div className="col-md-4">
                        <label>VALUE</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="value" id="value" className="form-control" name="value" placeholder="Value" onChange={handleInputChange} validations={[required]} value={tutorial.value} />
                      </div>



                      <div className="col-md-4">
                        <label>OTHER</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChange} validations={[required]} value={tutorial.other} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>




                      <div className="col-md-8 form-group">


                        {submitted ? (<button onClick={newData}
                          className="button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                            type="button" className="button-form"><ImFloppyDisk /> Save</button>)}

                      </div>


                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editing Select options

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">

                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>CATEGORY</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="category" name="category" onChange={handleInputChangeEdit} >
                        <option>{currentData.category}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.id}>{optionCategory.category}</option>
                        ))}

                      </select>
                      </div>

                      <div className="col-md-4">
                        <label>VALUE</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="value" id="value" className="form-control" name="value" placeholder="Value" onChange={handleInputChangeEdit} validations={[required]} value={currentData.value} />
                      </div>



                      <div className="col-md-4">
                        <label>OTHER</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                      </div>

                      <div className="col-md-4">
                        <label>SALLER ACCESS CODE</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="saller_access_code" className="form-control" name="saller_access_code" placeholder="Other" onChange={handleInputChangeEdit} validations={[required]} value={currentData.saller_access_code} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>




                      <div className="col-md-8 form-group">


                       <button onClick={updateData}
                            type="button" className="button-form"><ImFloppyDisk /> Save</button>

{isValidated ? (<button onClick={remove}
                            type="button" className="button-tools-red"> Delete</button>
):(<button onClick={confirm}
  type="button" className="button-form"> Remove</button>
)}



                      </div>


                    </div>
                  </div>

                </form>




              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default SettingList;
