import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { FcServices, FcCustomerSupport } from 'react-icons/fc';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import MemberService from "../../services/members.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};




const currentUser = AuthService.getCurrentUser();


const CustomerView = withRouter((props) => {

    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }


    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_SALER")) {
        return <Redirect to="/unauthorized" />;

    }

    /***************************************************************************************/

    const initialDataState = {
        id: null,
        fullname: "",
        phone: "",
        contact: "",
        phone: "",
        email: "",
        other: "",
        remark: "",
        username: "",

        published: false
    };

    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);


    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };





    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(100);

    const pageSizes = [200, 300, 500];

    tutorialsRef.current = tutorials;



    const retrieveData = () => {
        setPage(1);

        openObject();
    };


    const openObject = (rowIndex) => {
        setPage(1);

        setcurrentData(initialDataState);
        // const id = tutorialsRef.current[rowIndex].id;


        CustomersService.get(props.match.params.id)
            .then(response => {
                setcurrentData(response.data);
                //  console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });


    };

    const updateData = () => {
        setPage(1);
        CustomersService.update(currentData.id, currentData)
            .then(response => {
                console.log(response.data);
                setMessage("The Data was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteData = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;

        CustomersService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };


    useEffect(retrieveData, [page, pageSize]);


    const list = () => {
        props.history.push("/customers/");
    };

    const add = () => {
        props.history.push("/customers-new/");
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{memberdetails.firstname}</td>
                                <td>{memberdetails.lastname}</td>
                            </tr>
                            <tr>
                                <td>Branch:</td>
                                <td>{memberdetails.account}</td>
                            </tr>

                        </tbody>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FcCustomerSupport /> </span></td>
                                <td><span className="page-title"> Customer</span></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>

                                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>Fullname</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="fullname" className="form-control" name="fullname" placeholder="Full name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fullname} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Contact</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="contact" className="form-control" name="contact" placeholder="Contact" onChange={handleInputChangeEdit} validations={[required]} value={currentData.contact} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Phone</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Email</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="email" id="email" className="form-control" name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Other</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="remark" className="form-control" name="remark" placeholder="remark" onChange={handleInputChangeEdit} validations={[required]} value={currentData.remark} />
                                            </div>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <label className="col-md-8">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <div className="col-md-8 form-group">


                                                <button onClick={updateData}
                                                    type="button" className="button-form"><ImFloppyDisk /> Save</button>

                                                <button
                                                    type="button" onClick={list} className="button-form"><ImCancelCircle /> Close</button>


                                            </div>





                                        </div>
                                    </div>
                                </form>

                            </center>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
});

export default CustomerView;
