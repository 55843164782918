import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (data) => {
  return http.post("/cashinglist",data, { headers: authHeader() });
   
};

const get = (id) => {
  return http.get(`/cashing/${id}`,{ headers: authHeader() });
};

const validate = (data) => {
   
  return http.post("/cashingvalidation", data,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/cashing", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/cashing/${id}`, data,{ headers: authHeader() });
};

const confirm = (data) => {
  return http.post(`/cashingconfirmation`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/cashing/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/cashing`);
};


const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const CashingService = {
  getAll,
  get,
  validate,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  confirm,
};

export default CashingService;
