import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { FcServices } from 'react-icons/fc';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import ExpensesService from "../../services/expense.service";
import MemberService from "../../services/members.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};




const currentUser = AuthService.getCurrentUser();


const ExpenseNew = withRouter((props) => {


    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_CASHIER")) {
        return <Redirect to="/unauthorized" />;

    }

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        expenseType: "",
        amount: "",
        other: "",

        username: "",

    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [isManager, setIsManager] = useState(false);


    const handleinputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };


    const saveData = () => {
        setPage(1);

        var data = {
            expenseType: tutorial.expenseType,
            amount: tutorial.amount,
            other: tutorial.other,
            username: currentUser.username
        };



        ExpensesService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    expenseType: response.data.expenseType,
                    amount: response.data.amount,
                    other: response.data.other,
                    username: response.data.username

                });
                setSubmitted(true);
                setMessage("Data saved successfully.");
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    };

    const newData = () => {
        setData(initialDataState);
        setSubmitted(false);
        setMessage("");


    };




    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(100);

    const pageSizes = [100, 300, 500];

    tutorialsRef.current = tutorials;



    const retrieveData = () => {
        setPage(1);

        getMemberData();
    };

    const getMemberData = () => {
         setPage(1);

        MemberService.getMemberdetails(currentUser.username)
          .then((response) => {
    
            setMemberDetails(response.data);
    
    
          })
          .catch((e) => {
            console.log(e);
          });
    
        if (currentUser.roles.includes("ROLE_MANAGER")) {
          setIsManager(true);
    
        }
    
      }

    useEffect(retrieveData, [page, pageSize]);

    const list = () => {
        props.history.push("/expense-list/");
    };

    const add = () => {
        props.history.push("/expense-new/");
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <div className="table-account">
                        <tbody>
                        <tr>
                            <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div className="table-name">{memberdetails.firstname}</div></td>
                            <td><div className="table-name">{memberdetails.lastname}</div></td>
                        </tr>
                        <tr>
                            <td>Branch:</td>
                            <td><div className="table-name">{memberdetails.account}</div></td>
                        </tr>
                        <tr>
                            <td colSpan="2"></td>
                            <td></td>
                        </tr>
                        </tbody>
                        </div>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                        <tr>
                            <td><span className="page-title-img"><FaIcons.FaProductHunt /> </span></td>
                            <td><span className="page-title"> Expenses</span></td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                            <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"> </td>
                        </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>Expense type</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="expenseType" className="form-control" name="expenseType" placeholder="Expense type" onChange={handleinputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Purpose</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleinputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="other" className="form-control" name="other" placeholder="Remark" onChange={handleinputChange} validations={[required]} />
                                            </div>



                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <label className="col-md-8 danger-message">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>


                                            <div className="col-md-8 form-group">


                                                {submitted ? (<button onClick={add}
                                                    className="button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                                                        type="button" className="button-tools"><ImFloppyDisk /> Save</button>)}

                                                <button
                                                    type="button" onClick={list} className="button-tools"><ImCancelCircle /> Close</button>

                                            </div>





                                        </div>
                                    </div>
                                </form>

                            </center>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
});

export default ExpenseNew;
