import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { ImPencil } from 'react-icons/im';
import { GiTimeBomb } from 'react-icons/gi';
import { AiOutlinePrinter, AiOutlinePhone } from 'react-icons/ai';
import { BsPersonBoundingBox } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import OrderService from "../../services/order.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Saleslist = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_SALES")) {
    return <Redirect to="/unauthorized" />;

  }

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",
  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");


  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");
  const [comment, setComment] = useState("");


  const componentRef = useRef(null);

  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeComment = (e) => {
    const comment = e.target.value;
    setComment(comment);

  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }



  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (isManager == true) {
      setRole("Manager");
    }

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (role) {
      params["role"] = role;
    }

    if (memberdetails.account) {
      params["location"] = memberdetails.account;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (currentUser.username) {
      params["username"] = currentUser.username;
    }

    if (optionCategorySelectedValue) {
      params["cashier"] = optionCategorySelectedValue;
    }

    if (isReport) {
      params["report"] = isReport;
    }



    return params;
  };


  const retrieveData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);

    } else {
      setCashierName(currentUser.username);
    }


    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: "PAYED",
      text4: memberdetails.account,
      text5: cashierName,
      text6: searchTitle
    }



    OrderService.getsalesByDate(data)
      .then((response) => {

        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });




    getSelectOption();

    getMemberData();
  };

  const report = () => {

    setPage(1);

    setIsReport(true);

    const params = getRequestParams(searchTitle, page, pageSize);

    OrderService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openData = (id) => {
    setPage(1);

    OrderService.getOrderByNo(id)
      .then(response => {

        const { tutorials, totalPages, cash, momo, visa, cheque, transfer } = response.data;
        setCurrentOrderData(tutorials);
        // console.log("=====cash===="+cashAmount+" ");
        setCash(cash);
        setMomo(momo);
        setVisa(visa);
        setCheque(cheque);
        setTransfer(transfer);


      })
      .catch(e => {
        console.log(e);
      });

    OrderService.getOrderByNoSummary(id)
      .then(response => {
        setCurrentOrderDataSummary(response.data);



      })
      .catch(e => {
        console.log(e);
      });

    setLgShow(true);

  }

  const openDataComment = (id) => {
    setPage(1);

    OrderService.getOrderByNo(id)
      .then(response => {

        const { tutorials, totalPages, cash, momo, visa, cheque, transfer } = response.data;
        setCurrentOrderData(tutorials);
        // console.log("=====cash===="+cashAmount+" ");
        setCash(cash);
        setMomo(momo);
        setVisa(visa);
        setCheque(cheque);
        setTransfer(transfer);


      })
      .catch(e => {
        console.log(e);
      });

    OrderService.getOrderByNoSummary(id)
      .then(response => {
        setCurrentOrderDataSummary(response.data);
        
      })
      .catch(e => {
        console.log(e);
      });

    setLgShow2(true);

  }


  const openObjectWithProduct = (id) => {
    setcurrentData(initialDataState);

    //const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/stock-in/" + id);
  };




  const logOut = () => {
    AuthService.logout();
  }


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const openObject = (id) => {
    setPage(1);

    setcurrentData(initialDataState);

    OrderService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const pendingOrder = () => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = () => {
    props.history.push("/sales-pending-order-deposit");
  }

  const newOrder = () => {
    props.history.push("/customers-search");
  }

  const receipt = (id) => {
    props.history.push(`/sales-receipt/?id=${currentOrderDataSummary.orderNo}`);
    setLgShow(false);
  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const searchOrder = () => {
    setPage(1);

    OrderService.searchSales(searchText)
      .then(response => {

        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

      })
      .catch(e => {
        console.log(e);
      });
  }

  const searchOrderPhone = () => {
    setPage(1);
    OrderService.searchSalesPhone(searchText)
      .then(response => {

        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);


      })
      .catch(e => {
        console.log(e);
      });
  }

  const saveComment = (order) => {

    setPage(1);

    setMessage("");

    var data = {
      text1: order,
      text2: comment
    }

    OrderService.updateSalesCommentOther(data)
      .then(response => {

        if (response.data == 1) {
          setMessage("Successfully saved.");
        } else {
          setMessage("Your comment was not saved.");
        }


      })
      .catch(e => {
        console.log(e);
      });


  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <div className="table-account">
              <tbody>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                  <td colSpan="2"> <input id="customer" type="text" name="customer" placeholder="Customer" onChange={handleChangeSearchText} value={currentData.name} className="search-input" />  </td>
                  <td><button onClick={searchOrder} className="button-tools-sm"> <span className="table-tools-img-search"><BsPersonBoundingBox /></span></button> <button onClick={searchOrderPhone} className="button-tools-sm"> <span className="table-tools-img-search"><AiOutlinePhone /></span></button></td>
                </tr>
              </tbody>
              </div>
            </table>


          </div>

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                {isManager ? (
                  <tr>
                    <td>Cashier</td>
                    <td>
                      <div className="col-md-8 form-group">
                        <select className="form-control" onChange={handleChangeoptionCategories} >
                          <option>Select</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                    </td>
                  </tr>) : (<tr>
                    <td>Cashier</td>
                    <td>
                      <div className="col-md-8 form-group">
                        <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                          <option key={currentUser.username}>{currentUser.username}</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                    </td>
                  </tr>)}
              </tbody>

            </table>




          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={newOrder} className="button-tools">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={pendingOrder} className="button-tools">Pending</button></td>
                  <td className="tg-0lax"><button onClick={depositOrder} className="button-tools">Credit</button></td>
                </tr>
              </tbody>

            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  SALES SUMMARY * </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>Order No</th>
                      <th>Customer</th>
                      <th>Phone</th>
                      <th>Saller</th>
                      <th>Total</th>
                      <th>Payed</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Username</th>
                      <th>Branch</th>
                      <th>#</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (
                        <tr>
                          <td>{tutorial.orderid}</td>
                          <td>{tutorial.customer}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.saller}</td>
                          <td>{tutorial.amount}</td>
                          <td>{tutorial.amount_payed}</td>
                          <td>{tutorial.orderTime}</td>
                          <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>
                          <td>{tutorial.username}</td>
                          <td><span className={"status " + tutorial.location}>{tutorial.location}</span></td>

                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openData(tutorial.orderid)}
                            > <AiOutlinePrinter />
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openDataComment(tutorial.orderid)}
                            > <FaIcons.FaCommentDots />
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div className="dataRow">
            <div className="details">


              <table>
                <tbody>
                  <tr>
                    <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>

                  </tr>
                  <tr>
                    <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Date :{currentOrderDataSummary.date}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Total :{currentOrderDataSummary.total}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Payed :{currentOrderDataSummary.payed}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Balance :{currentOrderDataSummary.balance}</h5></td>
                    <td><button onClick={receipt} className="button-form">Print</button></td>
                    <td><button onClick={() => setLgShow(false)} className="button-form">Close</button></td>
                  </tr>
                </tbody>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>PRICE</th>
                      <th>QTY</th>
                      <th>TOTAL</th>
                      <th>DISCOUNT</th>
                      <th>AUTHORIZATION</th>
                      <th>AUTHORIZED BY</th>
                     


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr key={tutorial.product}>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.price}</td>
                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total}</td>
                          <td>{tutorial.product_price*tutorial.qty - tutorial.total}</td>
                          <td><span className={"status " + tutorial.authorization_status}>{tutorial.authorization_status}</span></td>
                          <td>{tutorial.authorization_by}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

                Cash:{cash} <br></br>
                MoMo:{momo} <br></br>
                Visa:{visa} <br></br>
                Cheque:{cheque} <br></br>
                Transfer:{transfer} <br></br>

              </div>
            </div>


          </div>


        </Modal.Body>
      </Modal>

      {/* comments  */}

      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">
              Add commments
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div className="dataRow">
            <div className="details">


              <table>
                <tbody>
                  <tr>
                    <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>

                  </tr>
                  <tr>
                    <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Date :{currentOrderDataSummary.date}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Total :{currentOrderDataSummary.total}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Payed :{currentOrderDataSummary.payed}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Balance :{currentOrderDataSummary.balance}</h5></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>PRICE</th>
                      <th>QTY</th>
                      <th>TOTAL</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.price}</td>
                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

                <div className="row">

                  <div className="col-md-3">
                    Cash:{cash} <br></br>
                    MoMo:{momo} <br></br>
                    Visa:{visa} <br></br>
                    Cheque:{cheque} <br></br>
                    Transfer:{transfer} <br></br>
                  </div>
                 
                  <div className="col-md-8">
                    <div className="row">
                      <input
                        type="text"
                        placeholder="Place your comments here."
                        className="form-control form-input-text comment-text-field"
                        id="comment"
                        name="comment"
                        onChange={onChangeComment}
                        value={currentOrderDataSummary.other}

                      />
                      {message}
                    </div>
                    <div className="row">
                      <button onClick={() => saveComment(currentOrderDataSummary.orderNo)} className="button-tools col-md-2">Save</button> <button onClick={() => setLgShow2(false)} className="button-tools col-md-2">Close</button>
                    </div>


                  </div>

                </div>

              </div>
            </div>


          </div>


        </Modal.Body>
      </Modal>


    </>
  );
});

export default Saleslist;
