import React, { useState, useEffect, useMemo, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import DeliveryService from "../../services/delivery.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const currentUser = AuthService.getCurrentUser();


const DeliveryReportPending = withRouter((props) => {


    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    const initialDataState = {
        id: null,
        code: "",
        name: "",
        description: "",
        min_price: "",
        max_price: "",
        color: "",
        size: "",
        remark: "",
        username: "",
        stand: "",

        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);



    const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);

    const handleChangeoptionCategories = (event) => {
        setOptionCategorySelectedValue(event.target.value);
        setCashierName(event.target.value);

    }

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const saveData = () => {

        setPage(1);

        var data = {
            code: tutorial.code,
            name: tutorial.name,
            description: tutorial.description,
            min_price: tutorial.min_price,
            max_price: tutorial.max_price,
            color: tutorial.color,
            size: tutorial.size,
            remark: tutorial.remark,
            username: currentUser.username
        };



        DeliveryService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    code: response.data.code,
                    name: response.data.name,
                    description: response.data.description,
                    min_price: response.data.min_price,
                    max_price: response.data.max_price,
                    color: response.data.color,
                    size: response.data.size,
                    remark: response.data.remark,
                    code: response.data.code,
                    username: response.data.username

                });
                setSubmitted(true);
                //console.log(response.data);

            })
            .catch(e => {
                console.log(e);
            });
    };

    const newData = () => {
        setData(initialDataState);
        setSubmitted(false);
    };

    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [isCompleted, setIsCompleted] = useState(true);
    const [isLocked, setIsLocked] = useState(true);
    const [isManager, setIsManager] = useState(false);
    const [cashierName, setCashierName] = useState("");

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    }

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    }

    const pageSizes = [200, 500, 1000];

    tutorialsRef.current = tutorials;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };



    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };

    const retrieveData = () => {

        getMemberData();
        getSelectOption();
        refreshList();


    };

    const getSelectOption = () => {
        setPage(1);
        var category = "BRANCH";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {

                setOptionCategoryArray(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setIsManager(true);

        }

    }

    useEffect(retrieveData, [page, pageSize]);


    const refreshList = () => {
        setPage(1);

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setCashierName(optionCategorySelectedValue);

        } else {
            setCashierName(memberdetails.account);
        }

        var data = {
            text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
            text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
            text3: cashierName,
        }

        DeliveryService.getReportByDatePending(data)
            .then((response) => {

                setDatas(response.data);

            })
            .catch((e) => {
                console.log(e);
            });

    };



    const handleSearchByOrderNo = () => {


        setPage(1);

        refreshList();

        var data = {
            text1: "orderno",
            text2: searchTitle,

        }

        DeliveryService.getDeliverySearch(data)
            .then((response) => {


                setDatas(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    };

    const handleSearchByCustomer = () => {

        setPage(1);
        refreshList();

        var data = {
            text1: "customer",
            text2: searchTitle,

        }

        DeliveryService.getDeliverySearch(data)
            .then((response) => {

                setDatas(response.data);

            })
            .catch((e) => {
                console.log(e);
            });

    };



    const updateData = () => {
        setPage(1);
        if (currentData.paymentStatus === "PAYED") {

            if(currentData.lastQtyDelivered > currentData.qtyToDeliver){

                setMessage("You are delivering more qty!");

            }else{

                DeliveryService.update(currentData.id, currentData)
                .then(response => {
                    // console.log(response.data);
                    setMessage("The Data was updated successfully!");
                })
                .catch(e => {
                    console.log(e);
                });

            }

            

        } else {
            setMessage("This order is not payed!");
        }

    };

    const deleteData = (rowIndex) => {
        setPage(1);
        const id = tutorialsRef.current[rowIndex].id;

        DeliveryService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const deliveries = () => {
        props.history.push("/deliveries");
    }

    const report = () => {
        props.history.push("/delivery-report");
    }

    const reste = () => {
        props.history.push("/delivery-reste");
    }

    const pending = () => {
        props.history.push("/delivery-pending");
    }

    const delivered = () => {
        props.history.push("/delivery-completed");
    }

    const close = () => {
       setSmShow(false);
        props.history.push("/delivery-completed");
    }

    const openObject = (id) => {
        setPage(1);
        setMessage("");

        setcurrentData(initialDataState);

        DeliveryService.get(id)
            .then(response => {
                setcurrentData(response.data);
                if (response.data.locked === "Authorized") {
                    setIsLocked(false);
                } else {
                    setIsLocked(true);
                }

                if (response.data.qtyRemaining < 1) {
                    setIsCompleted(false);

                } else {
                    setIsCompleted(true);
                }
            })
            .catch(e => {
                console.log(e);
            });

        setcurrentData({ ...currentData, deliveredBy: currentUser.username });

        setSmShow(true);


    };

    const lock = (id) => {
        setPage(1);
        DeliveryService.lockDelivery(currentData.id).then((response) => {
            if (response.data == 1) {
                window.location.reload();
                setMessage("Locked successfully.");
            }

        })
            .catch((e) => {
                console.log(e);
            });
    };

    const authorize = (id) => {
        setPage(1);
        DeliveryService.authorizeDelivery(currentData.id).then((response) => {
            if (response.data == 1) {
                //  window.location.reload();
                
                setMessage("Authorized successfully.");

            }

        })
            .catch((e) => {
                console.log(e);
            });

            refreshList();
    };


    return (<>
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>

                            <tr>
                                <td></td>
                                <td><button onClick={() => setLgShow(true)} className="button-tools">search</button></td>
                            </tr>

                            {isManager ? (<tr>
                                <td>Branch</td>
                                <td>
                                    <div className="col-md-8 form-group">
                                        <select className="form-control" onChange={handleChangeoptionCategories} >
                                            <option>Select</option>
                                            {optionCategoryArray.map((optionCategory) => (
                                                <option key={optionCategory.value}>{optionCategory.value}</option>
                                            ))}

                                        </select>
                                    </div></td>
                            </tr>) : (<tr>
                                <td>Branch</td>
                                <td>
                                    <div className="col-md-8 form-group">
                                        <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                                            <option key={memberdetails.account}>{memberdetails.account}</option>
                                            {optionCategoryArray.map((optionCategory) => (
                                                <option key={optionCategory.value}>{optionCategory.value}</option>
                                            ))}

                                        </select>
                                    </div></td>
                            </tr>)}
                        </tbody>
                    </table>


                </div>
                <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td rowSpan="3"><h2><span className="page-title-img"><FaIcons.FaTruckMoving /></span></h2></td>
                                <td>From</td>
                                <td><DatePicker className="form-control datePicker-style"
                                    selected={startDate}
                                    onChange={handleChangeStartDate}
                                    name="startDate"
                                    maxDate={new Date()}
                                    dateFormat="yyyy-MM-dd"
                                /></td>
                            </tr>
                            <tr>
                                <td>To</td>
                                <td ><DatePicker className="form-control datePicker-style"
                                    selected={endDate}
                                    onChange={handleChangeEndDate}
                                    name="endDate"
                                    maxDate={new Date()}
                                    dateFormat="yyyy-MM-dd"
                                /></td>
                            </tr>
                        </tbody>

                    </table>




                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                {/* <td className="tg-0lax"><button onClick={deliveries} className="button-tools">All</button></td> */}
                                <td className="tg-0lax"><button onClick={report} className="button-tools">Report</button></td>
                                <td className="tg-0lax"><button onClick={reste} className="button-tools">Resté</button> </td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"> <button onClick={pending} className="button-tools">Pending</button> </td>
                                <td className="tg-0lax"><button onClick={delivered} className="button-tools">Delivered</button> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">
                    <div className="row pagination-box">

                        <div className="col-md-3 pagination-box">{"Items per Page : "}
                            <select onChange={handlePageSizeChange} value={pageSize}>
                                {pageSizes.map((size) => (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                ))}
                            </select></div>

                        <div className="col-md-3 table-title">  EXIT REPORT - PENDING  </div>

                        <div className="col-md-4"><Pagination

                            count={count}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                        />

                        </div>



                    </div>
                    <div className="details">

                        <div className="table-wrap">

                            <table className="table">
                                <thead className="scroll-thead">
                                    <tr>
                                        <th>DATE</th>
                                        <th>CUSTOMER</th>
                                        <th>ORDER NO</th>
                                        <th>PAYMENT</th>
                                        <th>SALLER</th>
                                        <th>STOCK</th>
                                        <th>ITEM</th>
                                        <th>CODE</th>
                                        <th>SIZE</th>
                                        <th>COLOR</th>
                                        <th>ORDER</th>
                                        <th>TO DELIVER</th>
                                        <th>DELIVERED</th>
                                        <th>REMAINING</th>
                                        <th>LAST DELIVERY</th>
                                        <th>STATUS</th>
                                        <th>LOCKED</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tutorials &&
                                        tutorials.map((tutorial, index) => (

                                            <tr key={tutorial.id}>
                                                <td>{tutorial.orderDate}</td>
                                                <td>{tutorial.customer}</td>
                                                <td>{tutorial.orderNo}</td>
                                                <td><span className={"status " + tutorial.paymentStatus}>{tutorial.paymentStatus}</span></td>
                                                <td>{tutorial.createdBy}</td>
                                                <td>{tutorial.stock}</td>
                                                <td>{tutorial.item}</td>
                                                <td>{tutorial.code}</td>
                                                <td>{tutorial.size}</td>
                                                <td>{tutorial.color}</td>
                                                <td>{tutorial.qtyOrdered}</td>
                                                <td>{tutorial.qtyToDeliver}</td>
                                                <td>{tutorial.qtyDelivered}</td>
                                                <td>{tutorial.qtyRemaining}</td>
                                                <td>{tutorial.lastQtyDelivered}</td>
                                                <td><span className={"status " + tutorial.deliveryStatus}>{tutorial.deliveryStatus}</span></td>
                                                <td>{tutorial.locked}</td>

                                                <td><div className="table-action-button">

                                                    <button
                                                        type="button" className="btn"
                                                        onClick={() => openObject(tutorial.id)}
                                                    > <FaIcons.FaEdit />
                                                    </button>


                                                </div></td>
                                            </tr>

                                        ))}
                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        {/*edit */}

        <Modal
            size="md"
            backdrop="static"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">

                    Search <span className="table-tools-img-search"><FaIcons.FaSearch /></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-bg">

                <div className="dataRow">

                    <div className="details">

                        <form className="form form-horizontal ">
                            <div className="form-body">
                                <div className="row">

                                    <div className="col-md-4">
                                        <label>Authorization</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="locked" className="form-control" name="id" placeholder="Authorization" value={currentData.locked} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Date</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="orderDate" className="form-control" name="orderDate" placeholder="model number" value={currentData.orderDate} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Customer</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="customer" id="name" className="form-control" name="customer" placeholder="Product name" value={currentData.customer} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Order No</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="orderNo" className="form-control" name="orderNo" placeholder="Description" value={currentData.orderNo} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Payment</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="payment" className="form-control" name="payment" placeholder="Payment" value={currentData.paymentStatus} />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Ordered</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="number" id="qtyOrdered" className="form-control" name="qtyOrdered" placeholder="Minimum price" value={currentData.qtyOrdered} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Item</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="item" className="form-control" name="item" placeholder="item" value={currentData.item} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Code</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="code" className="form-control" name="code" placeholder="Code" value={currentData.code} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Size</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="size" className="form-control" name="item" placeholder="Size" value={currentData.size} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Color</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="color" className="form-control" name="color" placeholder="Color" value={currentData.color} />
                                    </div>


                                    <div className="col-md-4">
                                        <label>Delivered</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="number" id="qtyDelivered" className="form-control" name="qtyDelivered" placeholder="Maximum price" value={currentData.qtyDelivered} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Remaining</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="qtyRemaining" className="form-control" name="qtyRemaining" placeholder="Color" value={currentData.qtyRemaining} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>To deliver</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input type="text" id="qtyToDeliver" className="form-control" name="qtyToDeliver" placeholder="To Deliver" onChange={handleInputChangeEdit} value={currentData.qtyToDeliver} />
                                    </div>

                                    {isCompleted ? (
                                        <>
                                            <div className="col-md-4">
                                                <label>Last delivery</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="lastQtyDelivered" className="form-control" name="lastQtyDelivered" placeholder="Current delivered" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastQtyDelivered} />
                                            </div>
                                        </>
                                    ) : (<>
                                        <div className="col-md-4">
                                            <label>Last delivery</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly type="text" id="lastQtyDelivered" className="form-control" name="lastQtyDelivered" placeholder="Current delivered" onChange={handleInputChangeEdit} validations={[required]} />
                                        </div>
                                    </>)}



                                    <div className="col-md-4">
                                        <label>Delivery Date</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="deliveryData" className="form-control" name="deliveryDate" placeholder="Deliery Date" value={currentData.deliveryDate} />
                                    </div>

                                    <div className="col-md-4">
                                        <label>Saller</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="createdBy" className="form-control" name="createdBy" placeholder="Saller" value={currentData.createdBy} />
                                    </div>

                                    <div className="col-md-4">
                                        <label> Delivered By</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="deliveredBy" className="form-control" name="deliveredBy" placeholder="Delivered By" value={currentData.deliveredBy} />
                                    </div>

                                    <div className="col-md-4">
                                        <label> Status</label>
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <input readOnly type="text" id="deliveredBy" className="form-control" name="deliveredBy" placeholder="Status" value={currentData.deliveryStatus} />
                                    </div>


                                    <div className="col-md-4">
                                        <label> </label>
                                    </div>
                                    <label className="col-md-8 danger-message">{message}</label>

                                    <div className="col-md-4">
                                        <label> </label>
                                    </div>

                                    <div className="col-md-8 form-group">
                                        {isLocked ? ("") : (<button onClick={updateData}
                                            type="button" className="button-form"> Save</button>)}


                                        {isLocked ? (<button onClick={authorize}
                                            type="button" className="button-form">Authorize</button>) : (<button onClick={lock}
                                                type="button" className="button-form">Lock</button>)}


                                        <button onClick={close}
                                            type="button" className="button-form">Close</button>

                                    </div>



                                </div>
                            </div>
                        </form>


                    </div>


                </div>


            </Modal.Body>
        </Modal>

        <Modal
            size="md"
            backdrop="static"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">

                    Search <span className="table-tools-img-search"><FaIcons.FaSearch /></span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-bg">

                <div className="dataRow">

                    <div className="details-dialog-sm">

                        <div className="row"><input type="text" value={searchTitle}
                            onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input></div>
                        <hr />
                        <div className="row"> <button onClick={handleSearchByOrderNo} className="button-tools-search">Search by Order No</button> </div>
                        <div className="row"> <button onClick={handleSearchByCustomer} className="button-tools-search">Search by Customer</button> </div>
                        <div className="row"> <button onClick={() => setLgShow(false)} className="button-tools-search-red ">Close</button> </div>


                    </div>


                </div>


            </Modal.Body>
        </Modal>
    </>
    );
});

export default DeliveryReportPending;
