import React, { useState, useEffect,  useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb, GiPayMoney } from 'react-icons/gi';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import OrderService from "../../services/order.service";
import MemberService from "../../services/members.service";
import { format } from 'date-fns';
import SalesOrderSuccess from "./sales-order-new-success";





const currentUser = AuthService.getCurrentUser();


const SalesPendingOrderSaller = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    return <Redirect to="/unauthorized" />;

}

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",


  };

   
  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [memberdetails, setMemberDetails] = useState([]);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };




  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");

  const [searchText, setSearchText] = useState("");

  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }


  const retrieveOrderedData = () => {
    setPage(1);

    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: "Pending",
    }
    OrderService.getPendingOrdersByDate(data)
      .then((response) => {


        setOrderedData(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  }

  const retrieveData = () => {
    setPage(1);
    retrieveOrderedData();
    getMemberData();

  }

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  
    const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };


  const openObject = (id) => {

    setcurrentData(initialDataState);

    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const searchOrder = () => {
    setPage(1);
    OrderService.searchOrder(searchText)
      .then(response => {
        setOrderedData(response.data);

        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(retrieveData,[page, pageSize]);
  


  const payment = (data) => {
    props.history.push("/payment-form?data=" + data);
  }

  const pendingOrder = (data) => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const sales = (data) => {
    props.history.push("/sales-list");
  }

  const [name, setName] = useState(

    {
      title:"order no",
    }
  );

  

  return (
    
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>Branch:</td>
              <td>{memberdetails.account}</td>
            </tr>
            <tr>
              <td colspan="2"> <input id="customer" type="text" name="customer" placeholder="Order no" onChange={handleChangeSearchText} value={currentData.name} className="search-input" />  </td>
              <td><button onClick={searchOrder} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table className="table table-tools">

            <tr>
              <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
              <td>From</td>
              <td><DatePicker className="form-control datePicker-style"
                selected={startDate}
                onChange={handleChangeStartDate}
                name="startDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
            </tr>
            <tr>
              <td>To</td>
              <td ><DatePicker className="form-control datePicker-style"
                selected={endDate}
                onChange={handleChangeEndDate}
                name="endDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
            </tr>

          </table>




        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td class="tg-0lax" rowspan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
              <td class="tg-0lax"></td>
              <td class="tg-0lax"></td>
            </tr>
            <tr>
              <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td class="tg-0lax"><button onClick={sales} className="button-tools">Sales</button></td>
            </tr>
            <tr>
              <td class="tg-0lax"><button onClick={pendingOrder} className="button-tools">Pending</button></td>
              <td class="tg-0lax"><button onClick={depositOrder} className="button-tools">Credit</button></td>
            </tr>

          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">
          <div className="row pagination-box">

            <div className="col-md-3 pagination-box">{"Items per Page : "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select></div>

            <div className="col-md-3 table-title">  PENDING ORDERS </div>

            <div className="col-md-4"><Pagination

              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />

            </div>



          </div>
          <div className="details">

            <div className="table-wrap">

              <table className="table ">
                <thead className="scroll-thead">
                  <tr>
                    <th>Order No</th>
                    <th>Customer</th>
                    <th>Saller</th>
                    <th>Total</th>
                    <th>Payed</th>
                    <th>Date</th>
                    <th>Status</th>
                    

                  </tr>
                </thead>
                <tbody>
                  {orderedData &&
                    orderedData.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.orderid}</td>
                        <td>{tutorial.customer}</td>
                        <td>{tutorial.saller}</td>
                        <td>{tutorial.amount}</td>
                        <td>{tutorial.amount_payed}</td>
                        <td>{tutorial.orderTime}</td>
                        <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>
                        <td>{tutorial.username}</td>

                        
                      </tr>

                    ))}
                </tbody>

              </table>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
});

export default SalesPendingOrderSaller;
