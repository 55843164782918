import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (data) => {
  return http.post("/payments",data, { headers: authHeader() });
};

const getAllTotal = (data) => {
  return http.post("/paymentstotal",data, { headers: authHeader() });
};

const getAllByCashier = (data) => {
  return http.post("/paymentsbycashier",data, { headers: authHeader() });
};

const getAllTotalByCashier = (data) => {
  return http.post("/paymentstotalbycashier",data, { headers: authHeader() });
};

const get = (id) => {
  return http.get(`/product/${id}`,{ headers: authHeader() });
};

const getByOrderid = (orderid) => {
  return http.get(`/paymentsbyorder/${orderid}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/newpayment", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/product/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/tutorials`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const search = (data) => {
  return http.post(`/paymentsearch`, data,{ headers: authHeader() });
};

const journal = (data) => {
   
  return http.post("/journal", data,{ headers: authHeader() });
};

const PaymentsService = {
  getAll,
  getAllTotal,
  get,
  create,
  update,
  remove,
  removeAll,
  search,
  getByOrderid,
  getAllByCashier,
  getAllTotalByCashier,
  journal

};

export default PaymentsService;
