import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { ImPencil } from "react-icons/im";
import { IoIosRemoveCircleOutline } from "react-icons/io";

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();


const UsersLIst = withRouter((props) => {



  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    account: "",
    phone: "",
    joiningdate: "",
    roleName: "",
    user_group: "",
    password_expiration_date: "",
    report_duration: "",
    disabled: "",
    otp_enabled: "",

  };

  const [tutorial, setData] = useState(initialDataState);

  const [currentData, setcurrentData] = useState(initialDataState);
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayDisabled, setOptionCategoryArrayDisabled] = useState([]);
  const [optionCategoryArrayOtp_enabled, setOptionCategoryArrayOtp_enabled] = useState([]);
  const [optionCategoryArrayUser_group, setOptionCategoryArrayUser_group] = useState([]);

  const [message, setMessage] = useState("");
  const [messageNewPassword, setMessageNewPassword] = useState("");

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [300, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  
};

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {
    setPage(1);

    getMemberData();

    const params = getRequestParams(searchTitle, page, pageSize);

    MemberService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);
       
      })
      .catch((e) => {
        console.log(e);
      });

      getSelectOption();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);
   
    MemberService.getUserRoles()
        .then((response) => {

          setOptionCategoryArray(response.data);
         

        })
        .catch((e) => {
            console.log(e);
        });

        var category1 = "OTP-OPTION";
        SelectOptionService.getAllSelectedOptions(category1)
            .then((response) => {

                setOptionCategoryArrayOtp_enabled(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

            var category2 = "DISABLED";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {

                setOptionCategoryArrayDisabled(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

            var category2 = "USER_GROUP";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {

                setOptionCategoryArrayUser_group(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });


        
};


  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");

    setLgShow(true);

    setPage(1);

    setcurrentData(initialDataState);


    MemberService.get(id)
      .then(response => {
        
        setcurrentData(response.data);

      })
      .catch(e => {
        console.log(e);
      });

  };

  const removeReole = (id) => {

   setMessage("");
    setPage(1);

    setcurrentData(initialDataState);

    var data ={
      text1:currentData.id,
      text2:id,
    }

    MemberService.removeRole(data)
      .then(response => {
        
        setMessage(response.data);

      })
      .catch(e => {
        console.log(e);
      });

      refreshList();

  };


  const openObject2 = (id) => {
    setMessage("");
    setPage(1);
    setLgShow2(true);

    MemberService.get(id)
      .then(response => {
        setcurrentData(response.data);

      
        getUserroles(response.data.email);

      })
      .catch(e => {
        console.log(e);
      });


   
  };

const getUserroles=(username)=>{

  MemberService.getMemberdetailsRoles(username)
  .then(response => {
    
    setUserRoles(response.data.roles);

  })
  .catch(e => {
    console.log(e);
  });


}

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);

  const registration = () => {
    props.history.push("/registration/");
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const updateData = () => {

    
    setPage(1);

    setMessage("");

    MemberService.update(currentData.id, currentData)
      .then(response => {

        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });

      refreshList();
  };

  const save =()=>{

    setMessage("");

    if(tutorial.roleName === null || tutorial.roleName ===""){
      setMessage("Select valid role.");
    }else{

      var data ={
        text1:currentData.id,
        text2:tutorial.roleName,
      }
  
      MemberService.createRole(data)
      .then(response => {
  
        setMessage(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    }

   

  }

  const changePassword =(username)=>{

    setMessage("");
  
    var data={
      
      text4:username,
    }
      
  
      MemberService.changePasswordAdmin(data)
    .then((response) => {
  
        setMessageNewPassword(response.data);
  
      })
      .catch(e => {
        console.log(e);
      });
   
    }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
            <div className="table-account">
              <tbody>
              <tr>
                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><div className="table-name">{memberdetails.firstname}</div></td>
                <td><div className="table-name">{memberdetails.lastname}</div></td>
              </tr>
              <tr>
                <td>Branch:</td>
                <td><div className="table-name">{memberdetails.account}</div></td>
              </tr>
              </tbody>
              </div>
            </table>


          </div>

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <tbody>
              <tr>
                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                <td className="tg-0lax"></td>
                <td className="tg-0lax"></td>
              </tr>
              <tr>
                <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                <td className="tg-0lax"><button onClick={registration} className="button-tools">New</button></td>
              </tr>
              <tr>
                <td className="tg-0lax"></td>
                <td className="tg-0lax"></td>
              </tr>
                </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  USERS </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>FIRSTNAME</th>
                      <th>LASTNAME</th>
                      <th>PHONE</th>
                      <th>LOCATION</th>
                      <th>EAMIL</th>
                      <th>GROUP</th>
                      <th>EXPIRATION</th>
                      <th>REPORT DURATION</th>
                      <th>DISABLED</th>
                      <th>OTP ENABLED</th>
                      <th>TOOLS</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.firstname}</td>
                          <td>{tutorial.lastname}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.account}</td>
                          <td>{tutorial.email}</td>
                          <td>{tutorial.user_group}</td>
                          <td>{tutorial.password_expiration_date}</td>
                          <td>{tutorial.report_duration}</td>
                          <td>{tutorial.disabled}</td>
                          <td>{tutorial.otp_enabled}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <ImPencil />
                            </button>

                            
                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject2(tutorial.id)}
                            >  <FaIcons.FaEnvelopeOpenText />  
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editing user

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>FIrst Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname} />
                      </div>
                      <div className="col-md-4">
                        <label>Lastname</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="lastname" className="form-control" name="lastname" placeholder="Lastname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastname} />
                      </div>
                      <div className="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                      </div>
                      <div className="col-md-4">
                        <label>Account</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account} />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="email" id="email" className="form-control" readOnly name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>

                      <div className="col-md-4">
                        <label>Group</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="user_group" name="user_group" onChange={handleInputChangeEdit} >
                                                    <option>{currentData.user_group}</option>
                                                    {optionCategoryArrayUser_group.map((optionCategory) => (
                                                        <option key={optionCategoryArrayUser_group.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                      </div>

                      <div className="col-md-4">
                        <label>Expiration</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="password_expiration_date" className="form-control" name="password_expiration_date" placeholder="Expiration" onChange={handleInputChangeEdit} validations={[required]} value={currentData.password_expiration_date} />
                      </div>

                      <div className="col-md-4">
                        <label>Report Duration</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="report_duration" className="form-control" name="report_duration" placeholder="Report Duration" onChange={handleInputChangeEdit} validations={[required]} value={currentData.report_duration} />
                      </div>

                      <div className="col-md-4">
                        <label>Disabled</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="disabled" name="disabled" onChange={handleInputChangeEdit} >
                                                    <option>{currentData.disabled}</option>
                                                    {optionCategoryArrayDisabled.map((optionCategory) => (
                                                        <option key={optionCategoryArrayDisabled.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                      </div>

                      <div className="col-md-4">
                        <label>OTP Enabled</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="otp_enabled" name="otp_enabled" onChange={handleInputChangeEdit} >
                                                    <option>{currentData.otp_enabled}</option>
                                                    {optionCategoryArrayOtp_enabled.map((optionCategory) => (
                                                        <option key={optionCategoryArrayOtp_enabled.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        <button onClick={updateData}
                          type="button" className="button-form"> Edit</button>

                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            User Details

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>FIrst Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname+" "+currentData.lastname} />
                      </div>
                      
                     
                      <div className="col-md-4">
                        <label>Account</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account} />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="email" id="email" className="form-control" readOnly name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>

                      
                      <div className="col-md-4">
                        <label>Role</label>
                      </div>
                      <div className="col-md-8 form-group">
                      <select className="form-control" id="roleName" name="roleName" onChange={handleInputChange} >
                                                    <option>Select</option>
                                                    {optionCategoryArray.map((optionCategory) => (
                                                        <option key={optionCategoryArray}>{optionCategory}</option>
                                                    ))}

                                                </select>
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        <button onClick={save}
                          type="button" className="button-form"> Add</button>

                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>

                <hr />
                ROLES 
                <hr />
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ROLE ID</th>
                      <th>ROLES</th>
                      <th>REMOVE</th>

                    </tr>
                  </thead>
                 
                    {userRoles &&
                      userRoles.map((tutorial, index) => (
                        <tbody>
                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.name}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => removeReole(tutorial.id)}
                            > <IoIosRemoveCircleOutline />
                            </button>



                          </div></td>
                        </tr>
                         </tbody>

                      ))}
                 

                </table>

              </div>

              <hr/>

              <button className="newpassword-btn" onClick={()=>changePassword(currentData.email)}> reset password </button> <span className="newpassword"> {messageNewPassword} </span>

            </center>





          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default UsersLIst;
