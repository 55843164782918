import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/stockvide", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/stockvide/${id}`,{ headers: authHeader() });
};

const create = (data) => {
  return http.post("/stockvide", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/stockvide/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/stockvide/${id}`,{ headers: authHeader()});

  
};

const report = (data) => {
  return http.post("/stockvidereport", data,{ headers: authHeader() });
};

const reportSum = (data) => {
  return http.post("/stockvidereportsumm", data,{ headers: authHeader() });
};

///////////////////stock in/////////////////////////////////


const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

/////////////////////stock level //////////////////////////////
const getAllStockLevel = (params) => {
  return http.get("/stocklevelvide", { params,headers: authHeader() });
};

const getAllStockLevelBystock= (params) => {
  return http.get("/stocklevelbystockvide", { params,headers: authHeader() });
};

const updateStockLevel = (id, data) => {
  return http.put(`/stocklevelvide/${id}`, data,{ headers: authHeader() });
};

const generateDefaultStockLevel = () => {
  return http.get(`/initialemptystockvide`,{ headers: authHeader() });
};

const getStockLevelById = (id) => {
  return http.get(`/stocklevelvide/${id}`,{ headers: authHeader() });
};



const VideService = {
  getAll,
  get,
  create,
  update,
  remove,
  findByTitle,
  report,
  reportSum,
  //stock level
  getAllStockLevel,
  getAllStockLevelBystock,
  updateStockLevel,
  generateDefaultStockLevel,
  getStockLevelById
  
};

export default VideService;
