import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { GrValidate } from 'react-icons/gr';
import { GiTimeBomb } from 'react-icons/gi';
import { AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import {  Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const StockListOutInspect = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_INSPECT")) {
    return <Redirect to="/unauthorized" />;

}

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",

    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    username: "",
    confirmation: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [selectByname] = useState("Name");
  const [selectByCode] = useState("Code");

  const [totalItems, setTotalItems] = useState(0);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [actualStock, setActualStock] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [optionCategoryArrayConfirmation, setOptionCategoryArrayConfirmation] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }

  const [avairableQty,setAvairableQty] = useState(0);
  const [isAvairableQtyChecked,setIsAvairableQtyChecked] = useState(false);



  const handleExportToExcel = event => {
    console.log("=======data export=====");
  };




  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchedProductDetails, setSearchedProductDetails] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeSearchProduct = (e) => {
    const searchProduct = e.target.value;
    setSearchProduct(searchProduct);

  };





  const retrieveData = () => {

    setPage(1);

    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "OUT",
      text4: searchTitle,
      text5:""
    }

    StockService.getStockInInspection(data)
      .then((response) => {
         const { tutorials, totalItems } = response.data;

        setDatas(tutorials);
        setTotalItems(totalItems);
        
      })
      .catch((e) => {
        console.log(e);
      });

    var category = "STOCK-CONFIRMATION";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayConfirmation(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getSelectOptionTransfer();
    getMemberData();
  };

  

  const getMemberData = () => {

    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    setPage(1);
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  
  const findByTitle = () => {
    setPage(1);
    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "OUT",
      text4: searchTitle
    }

    StockService.getStockInSearch(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };



  const openObjectWithProduct = (id) => {
    setcurrentData(initialDataState);

    //const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/stock-out/" + id);
  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };






  const logOut = () => {
    AuthService.logout();
  }




  const handleCallback = (data) => {

    setSearchedProductDetails(data);

  }

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }



  const openObject = (rowIndex) => {
    setPage(1);

    // const id = tutorialsRef.current[rowIndex].id;

    // props.history.push("/product/" + id);

    setcurrentData(initialDataState);

    const id = tutorialsRef.current[rowIndex].id;


    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const list = () => {
    props.history.push("/stock-out");
  }

  const add = () => {
    props.history.push("/stock-in-new");
  }

  const returned = () => {
    props.history.push("/stock-returned");
  }

  const stockLevel = () => {
    props.history.push("/stock-level");
  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const loadData = (id) => {
    setPage(1);

    setMessage("");

    StockService.get(id)
      .then(response => {
        setcurrentData(response.data);
        getProductStockLevel(response.data.pid, response.data.stand);

      })
      .catch(e => {
        console.log(e);
      });


  };

  const getProductStockLevel = (pid, stock) => {
    setPage(1);

    var data = {
      text1: pid,
      text2: stock
    }

    StockService.getProductActualStock(data)
      .then(response => {
        // setcurrentData(response.data);

        setActualStock(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  }

  const confirmation = () => {
    setPage(1);

    
    if (currentData.confirmation === "Confirmed") {
      setMessage("This was confirmed before");

    }else if(isAvairableQtyChecked===false){
      setMessage("Check if product is avairable!");

    }else if(avairableQty< data.qty){
      setMessage("We don't have this product!");
    } else {

      var data = {
        text1: currentData.pid,
        text2: currentData.stand,
        text3: currentData.qty_in,
        text4: currentData.product,
        text5: currentData.size,
        text6: currentData.color,
        text7: currentData.code,
        text8: currentData.id,
      }

      StockService.confirmationStockOut(data)
      .then(response => {
        // setcurrentData(response.data);

        setMessage("Successfully confirmed.");
        refreshList();
        setIsAvairableQtyChecked(false);
    setAvairableQty(0);

      })
      .catch(e => {
        console.log(e);
        setMessage("There is some error.");
      });

    }

    

  }

  const checkQty=()=>{
    setPage(1);

    var data={
      text1:currentData.id,
      text2:optionCategorySelectedValue
    }

       
    ProductsService.getAvairableProductQty(data)
    .then(response => {

      setIsAvairableQtyChecked(true);

      setAvairableQty(response.data);
     setMessage("Qty in stock"+response.data);

    })
    .catch(e => {
      console.log(e);
      setMessage("We don't have this product");
    });


  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table table-tools">
              <tr>
                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>{memberdetails.firstname}</td>
                <td>{memberdetails.lastname}</td>
              </tr>
              <tr>
                <td>{memberdetails.account}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="customer" placeholder="Product name" className="search-input" />  </td>
                <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
              </tr>
            </table>


          </div>
          <div className="col-md-3 tool-box">
            <table className="table table-tools">
              <tbody>

              <tr>
                <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                <td>From</td>
                <td><DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>
              <tr>
                <td>To</td>
                <td ><DatePicker className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>
              </tbody>

            </table>




          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>

              <tr>
                
                <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Reflesh</button></td>
                
                <td class="tg-0lax"><button onClick={list} className="button-tools">Stock</button></td>
              </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  STOCK EXIT INSPECTION #{totalItems}</div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>

                      <th>ID</th>
                      <th>STAND</th>
                      <th>DATE</th>
                      <th>PID</th>
                      <th>PRODUCT</th>
                      <th>CODE</th>
                      <th>SIZE</th>
                      <th>COLOR</th>
                      <th>QTY</th>
                      <th>STOCK KEEPER</th>
                      <th>DESCRIPTION</th>
                      <th>COMMENT</th>
                      <th>CONFIRMATION</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.stand}</td>
                          <td>{tutorial.date1}</td>
                          <td>{tutorial.pid}</td>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.size}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.qty_out}</td>
                          <td>{tutorial.stockkeeper}</td>
                          <td>{tutorial.description}</td>
                          <td>{tutorial.comment}</td>
                          <td><span className={"status " + tutorial.confirmation}>{tutorial.confirmation}</span></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <div className="details">

              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">


                    <div class="col-md-4">
                      <label>Product</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product" class="form-control" name="product" placeholder="Product" onChange={handleInputChangeEdit} validations={[required]} value={currentData.product} />
                    </div>

                    <div class="col-md-4">
                      <label>Code</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="code" class="form-control" name="code" placeholder="Code" onChange={handleInputChangeEdit} validations={[required]} value={currentData.code} />
                    </div>

                    <div class="col-md-4">
                      <label>Size</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="size" class="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={currentData.size} />
                    </div>
                    <div class="col-md-4">
                      <label>Color</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="color" class="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={currentData.color} />
                    </div>
                    <div class="col-md-4">
                      <label>Stock</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <select disabled='true' id="stand" name="stand" className="form-control" onChange={handleInputChangeEdit} >
                        <option disabled='true'>{currentData.stand}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option value={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>

                    <div class="col-md-4">
                      <label>Actual Stock</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly type="number" id="actualStock" class="form-control" name="actualStock" placeholder="Actual" onChange={handleInputChangeEdit} validations={[required]} value={actualStock} />
                    </div>

                    <div class="col-md-4">
                      <label>QTY</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly type="number" id="qty_in" class="form-control" name="qty_in" placeholder="QTY IN" onChange={handleInputChangeEdit} validations={[required]} value={currentData.qty_in} />
                    </div>



                    <div class="col-md-4">
                      <label>Description</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly type="text" id="description" class="form-control" name="description" placeholder="Description" onChange={handleInputChangeEdit} validations={[required]} value={currentData.description} />
                    </div>

                    <div class="col-md-4">
                      <label>Comment</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <input readOnly type="text" id="comment" class="form-control" name="comment" placeholder="Comment" onChange={handleInputChangeEdit} validations={[required]} value={currentData.comment} />
                    </div>

                    <div class="col-md-4">
                      <label>Confirmation</label>
                    </div>
                    <div class="col-md-8 form-group">
                      <select readOnly id="confirmation" name="confirmation" className="form-control" onChange={handleInputChangeEdit} >
                        <option >{currentData.confirmation}</option>
                        {optionCategoryArrayConfirmation.map((optionCategory) => (
                          <option value={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>

                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <div className="col-md-8 form-group">



                      <button
                        type="button" onClick={() => confirmation()} className="button-form"> Confirm1xxxx</button>

                      <button
                        type="button" onClick={() => setLgShow(false)} className="button-form"> Close1</button>

                    </div>
                    

                  </div>
                </div>
              </form>

            </div>




          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default StockListOutInspect;
