import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/orderdetails", { params,headers: authHeader() });
};

const getOrderByNo = (order) => {
  
  return http.get(`/currentorder/${order}`, { headers: authHeader() });
};

const getOrderBySaller = (data) => {

  return http.post("/currentordersaller", data,{ headers: authHeader() });
};

const getOrderBySallerSummary = (data) => {

  return http.post("/currentordersalleritemsummary", data,{ headers: authHeader() });
};

const getOrderBySallerPerformanceReport = (data) => {

  return http.post("/currentordersallerreport", data,{ headers: authHeader() });
};

const getOrderByNoTemp = (order) => {
  
  return http.get(`/currentordertemp/${order}`, { headers: authHeader() });
};

const getOrderByNoSummary = (order) => {
  
  return http.get(`/currentordersummary/${order}`, { headers: authHeader() });
};

const getOrderByNoTotal = (orderNo) => {
  
  return http.get(`/currentordertotal/${orderNo}`, { headers: authHeader() });
};

const getOrderNo = (username) => {
  return http.get(`/orderno/${username}`, { headers: authHeader() });
};

const saveNewSale = (orderno) => {
  return http.get(`/newsale/${orderno}`, { headers: authHeader() });
};

const saveNewSaleAnalysis = (orderno) => {
  return http.get(`/newsaleAnalysis/${orderno}`, { headers: authHeader() });
};

const checkIfRowpriceExist = (orderno) => {
  return http.get(`/checkifunauthorizedrowprice/${orderno}`, { headers: authHeader() });
};

const checkeOfOrderWasSaved = (orderno) => {
  return http.get(`/newsalecheckifsaved/${orderno}`, { headers: authHeader() });
};


const getPendingOrders = () => {
  return http.get(`/ordersummary`, { headers: authHeader() });
};

const getPendingOrdersByDate = (data) => {
 
  return http.post(`/pendingordersummary`, data, { headers: authHeader() });
};

const getsalesByDate = (data) => {
  return http.post(`/salessummary`, data, { headers: authHeader() });
};

const getSavedOrder = (orderno) => {
  return http.get(`/savedorder/${orderno}`, { headers: authHeader() });
};

const get = (id) => {
  return http.get(`/product/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/orderdetails", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/currentorder/${id}`,{ headers: authHeader()});
};

const deleteOrder = (id) => {
 
  return http.get(`/removeorder/${id}`,{ headers: authHeader()});
};

const archive = () => {
  return http.get(`/archiv`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const searchOrder = (orderid) => {
  return http.get(`/searchorder/${orderid}`,{ headers: authHeader()});
};

const searchSales = (orderid) => {
  
  return http.get(`/searchsales/${orderid}`,{ headers: authHeader()});
};

const searchSalesPhone = (orderid) => {
  return http.get(`/searchsaleswithphone/${orderid}`,{ headers: authHeader()});
};

const updateSalesComment = (data) => {
  return http.post("/salessummarycomment", data,{ headers: authHeader() });

}

const updateSalesCommentOther = (data) => {
  return http.post("/salessummarycommentOther", data,{ headers: authHeader() });

}

const authorizeLowPrice = (data) => {
 
  return http.post(`/authorizelowprice`, data, { headers: authHeader() });
};

const OrderService = {
  getAll,
  get,
  create,
  update,
  remove,
  deleteOrder,
  getOrderByNo,
  getOrderByNoTemp,
  getOrderNo,
  getOrderBySaller,
  getOrderBySallerSummary,
  getOrderBySallerPerformanceReport,
  getOrderByNoTotal,
  getOrderByNoSummary,
  saveNewSaleAnalysis,
  checkeOfOrderWasSaved,
  saveNewSale,
  getPendingOrders,
  getSavedOrder,
  getPendingOrdersByDate,
  searchOrder,
  getsalesByDate,
  searchSales,
  searchSalesPhone,
  updateSalesComment,
  updateSalesCommentOther,
  archive,
  checkIfRowpriceExist,
  authorizeLowPrice
};

export default OrderService;
