import React, { useState, useEffect, useRef, useMemo } from "react";
import * as FaIcons from 'react-icons/fa';
import { SiPrivateinternetaccess } from 'react-icons/si';
import { GiTimeBomb } from 'react-icons/gi';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";


const currentUser = AuthService.getCurrentUser();


const UnauthorizedAccess = withRouter((props) => {

  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

}else{
  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
  
      return <Redirect to="/login" />;
  
  }
  
}



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [memberdetails, setMemberDetails] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const retrieveData = () => {
    setPage(1);

    getMemberData();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);
       
      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData,[page, pageSize]);


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

        <table className="table-tools">
          <tbody>
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
           </tbody>
          </table>


        </div>
        <div className="col-md-3 tool-box">
         

        </div>

       


        {/* end card box */}



        <div className="dataRow">
          <div className="details">

            <div className="table-wrap">

              <center>
            <h2>  Unauthorized Access </h2>
            </center>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
});

export default UnauthorizedAccess;
