import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GrValidate } from 'react-icons/gr';
import { GiTimeBomb, GiPayMoney } from 'react-icons/gi';
import { AiFillFolderOpen, AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import OrderService from "../../services/order.service";
import MemberService from "../../services/members.service";
import { format } from 'date-fns';



const currentUser = AuthService.getCurrentUser();


const SalesPendingOrders = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_SALER")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",


  };



  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [isCashier, setIsCashier] = useState(false);
  const [memberdetails, setMemberDetails] = useState([]);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };




  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");

  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const retrieveOrderedData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {

      var data = {
        text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
        text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
        text3: "Pending",
        text4: "Manager",
        text5: memberdetails.account,

      }

    } else {

      var data = {
        text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
        text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
        text3: "Pending",
        text4: "Saler",
        text5: memberdetails.account,

      }
    }



    OrderService.getPendingOrdersByDate(data)
      .then((response) => {


        setOrderedData(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  }

  const retrieveData = () => {
    setPage(1);

    if (currentUser.roles.includes("ROLE_SALES")) {
      setIsCashier(true);
    }

    getMemberData();
    retrieveOrderedData();


  }




  //useEffect(retrieveOrderedData);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };





  const openObject = (rowIndex) => {

    // const id = tutorialsRef.current[rowIndex].id;

    // props.history.push("/product/" + id);

    setcurrentData(initialDataState);

    const id = tutorialsRef.current[rowIndex].id;


    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };





  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const payment = (data) => {
    props.history.push("/payment-form?data=" + data);
  }

  const newOrder = () => {
    props.history.push("/customers-search");
  }

  const paymentHistoric = (data) => {
    props.history.push("/payment-historic?data=" + data);
  }

  useEffect(retrieveData, [page, pageSize]);


  const searchOrder = () => {
    setPage(1);
    OrderService.searchOrder(searchText)
      .then(response => {
        setOrderedData(response.data);

        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const openData = (id) => {
    setPage(1);

    OrderService.getOrderByNoTemp(id)
      .then(response => {
        const { tutorials, totalPages } = response.data;
        setCurrentOrderData(tutorials);


      })
      .catch(e => {
        console.log(e);
      });

    OrderService.getOrderByNoSummary(id)
      .then(response => {
        setCurrentOrderDataSummary(response.data);



      })
      .catch(e => {
        console.log(e);
      });


  }

  const pendingOrder = (data) => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const sales = (data) => {
    props.history.push("/sales-list");

  }


  const [lgShow, setLgShow] = useState(false);
  const componentRef = useRef(null);

  const deleteData = (id) => {

    setPage(1);

    OrderService.deleteOrder(id)
      .then((response) => {
        /// props.history.push("/product");

        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };


  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <div className="table-account">
                <tbody>

                  <tr>
                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>

                    <td>{memberdetails.firstname}</td>
                    <td>{memberdetails.lastname}</td>

                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td>{memberdetails.account}</td>
                  </tr>
                  <tr>
                    <td colSpan="2"> <input id="customer" type="text" name="customer" placeholder="Order no" onChange={handleChangeSearchText} value={currentData.name} className="search-input" />  </td>
                    <td><button onClick={searchOrder} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                  </tr>

                </tbody>
              </div>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className="table-tools">
            <div className="table-account">
              <tbody>
                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td>From</td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                <tr>
                  <td>To</td>
                  <td ><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
              </tbody>
              </div>
            </table>




          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={sales} className="button-tools">Sales</button></td>
                  <td className="tg-0lax"><button onClick={newOrder} className="button-tools-red">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={pendingOrder} className="button-tools">Pending</button></td>
                  <td className="tg-0lax"><button onClick={depositOrder} className="button-tools">Credit</button></td>
                </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  PENDING ORDERS </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>Order No</th>
                      <th>Customer</th>
                      <th>Saller</th>
                      <th>Total</th>
                      <th>Payed</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Username</th>
                      <th>Branch</th>
                      <th>Tools</th>


                    </tr>
                  </thead>
                  <tbody>
                    {orderedData &&
                      orderedData.map((tutorial, index) => (

                        <tr key={tutorial.orderid}>
                          <td>{tutorial.orderid}</td>
                          <td>{tutorial.customer}</td>
                          <td>{tutorial.saller}</td>
                          <td>{tutorial.amount}</td>
                          <td>{tutorial.amount_payed}</td>
                          <td>{tutorial.orderTime}</td>
                          <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>
                          <td>{tutorial.username}</td>
                          <td><span className={"status " + tutorial.location}>{tutorial.location}</span></td>

                          <td><div className="row">

                            <button onClick={() => setLgShow(true)} type="button" className="col-md-1 table-buttons" >
                              <span onClick={() => openData(tutorial.orderid)}>  <AiFillFolderOpen /> </span>
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => payment(tutorial.orderid)}
                            >
                              <span><GiPayMoney /></span>


                            </button>


                          </div></td>

                        </tr>

                      ))}
                  </tbody>

                </table>
              </div>

            </div>

          </div>
        </div>

      </div>
      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">



              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div className="dataRow">
            <div className="details">


              <table>
                <tbody>
                  <tr>
                    <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>

                  </tr>
                  <tr>
                    <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Date :{currentOrderDataSummary.date}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Total :{currentOrderDataSummary.total}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Payed :{currentOrderDataSummary.payed}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Balance :{currentOrderDataSummary.balance}</h5></td>

                    <td><button onClick={() => setLgShow(false)} className="button-form">Close</button></td>
                    <td><button onClick={() => deleteData(currentOrderDataSummary.orderNo)} className="button-tools-red">Concel</button></td>
                  </tr>
                </tbody>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>PRICE</th>
                      <th>QTY</th>
                      <th>TOTAL</th>
                      <th>DISCOUNT</th>
                      <th>AUTHORIZATION</th>
                      <th>AUTHORIZED BY</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.price}</td>
                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total}</td>
                          <td>{tutorial.product_price*tutorial.qty - tutorial.total}</td>
                          <td><span className={"status " + tutorial.authorization_status}>{tutorial.authorization_status}</span></td>
                          <td>{tutorial.authorization_by}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>
            </div>


          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default SalesPendingOrders;
