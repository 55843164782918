import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";


import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";

import { format } from 'date-fns';
import { List } from "@material-ui/core";



const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const NavbarLeft = withRouter((props) => {



  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",
    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    stock_type: "",
    username: "",
    stockEntryId: "",
    location: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [stockEntryId, setStockEntryId] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategorySelectedValueTransferDestination, setOptionCategorySelectedValueTransferDestination] = useState("");
  const [optionCategorySelectedValueTransferSource, setOptionCategorySelectedValueTransferSource] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [memberdetails, setMemberDetails] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }



  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };


  const newData = () => {
    setPage(1);
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };





  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };





  // useEffect(retrieveData, [page, pageSize]);



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const userList = () => {
    props.history.push("/users/");
  }

  const reports = () => {
    props.history.push("/reports/");
  }
  const customers = () => {
    props.history.push("/customers/");
  }
  const products = () => {
    props.history.push("/products/");
  }
  
  const orders = () => {
    props.history.push("/sales-pending-order/");
  }
  const salesList = () => {
    props.history.push("/sales-list/");
  }
  const deliveryPending = () => {
    props.history.push("/delivery-pending/");
  }
  const expenses = () => {
    props.history.push("/expense-list/");
  }

  const stock = () => {
    props.history.push("/stock-in/");
  }
  const payments = () => {
    props.history.push("/payments/");
  }
  const cashing = () => {
    props.history.push("/cashing/");
  }
  const credit = () => {
    props.history.push("/sales-pending-order-deposit/");
  }
  const users = () => {
    props.history.push("/users/");
  }
  const settings = () => {
    props.history.push("/settings/");
  }
  const stockkeeperPendingRequest = () => {
    props.history.push("/stockkeeper-pending-request/");
  }
const access = () => {
  props.history.push("/access/");
}
const otpAccessOtp = () => {
  props.history.push("/access-otp/");
}


  return (
    <div className='navbar-left'>
     
        <div className="sidebar-header1">
          
        </div>

          
     <div onClick={reports}className="row-menu">Reports</div>
     <div onClick={customers} className="row-menu">Customers</div>
     <div onClick={products} className="row-menu">Products</div>
     <div onClick={orders} className="row-menu">Orders</div>
     <div onClick={salesList} className="row-menu">Sales</div>
     {/* <div onClick={deliveryPending} className="row-menu">Exit</div> */}
     <div onClick={expenses} className="row-menu"> Expenses</div>
     <div onClick={stock} className="row-menu"> Stock </div>
     <div onClick={payments} className="row-menu"> Payments</div>
     <div onClick={cashing} className="row-menu"> Cashing</div>
     <div onClick={credit} className="row-menu"> Credit</div>
     <div onClick={users} className="row-menu"> users</div>
     <div onClick={settings} className="row-menu"> Settings</div>
     {/* <div onClick={access} className="row-menu">MAC access</div> */}
     <div onClick={otpAccessOtp} className="row-menu">Otp</div>
     <div onClick={stockkeeperPendingRequest} className="row-menu">StockKeeper</div>
    

     

    </div>
  );
});

export default NavbarLeft;
