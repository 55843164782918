import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { GrValidate } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';
import { GiTimeBomb } from 'react-icons/gi';
import { AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import StockService from "../../services/stock.service";
import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const StockListOut = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_STOCK")) {
    return <Redirect to="/unauthorized" />;

  }

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",

    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    username: "",
    confirmation: "",
    location: "",
    price_value: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [stock_type, setStock_type] = useState("OUT");
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [actualStock, setActualStock] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [stockName, setStockName] = useState("");
  const [isManager, setIsManager] = useState(false);
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

 
  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [optionCategoryArrayConfirmation, setOptionCategoryArrayConfirmation] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setStockName(event.target.value);

  }

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }



  const handleExportToExcel = event => {
    console.log("=======data export=====");
  };




  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchedProductDetails, setSearchedProductDetails] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeSearchProduct = (e) => {
    const searchProduct = e.target.value;
    setSearchProduct(searchProduct);

  };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (stock_type) {
      params["stock_type"] = "OUT";
    }

    return params;
  };


  const retrieveStockData = () => {
    setPage(1);

    setSearchTitle("");

    const params = getRequestParams(searchTitle, page, pageSize);

    StockService.getAllIn(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });


  }

  const retrieveData = () => {
    setSearchTitle("");
    setPage(1);

    retrieveStockDataByStockAndDate();

    getSelectOption();
    getSelectOptionTransfer();
    getMemberData();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

      if (currentUser.roles.includes("ROLE_MANAGER")) {
        setIsManager(true);
  
      }

  }

  const retrieveStockDataByStockAndDate = () => {

    setMessage("");
    
    setPage(1);


    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setStockName(optionCategorySelectedValue);

    } else {
      setStockName(memberdetails.account);
    }


    var data ={
      text1:format(startDate, 'yyyy-MM-dd'),
      text2:format(endDate, 'yyyy-MM-dd'),
      text3:stockName,
      text4:"OUT"
    }

    if(!currentUser.roles.includes("ROLE_MAIN_STOCK") && data.text3==="MAIN STOCK"){

      setMessage("Stock not allowed!");

    }else{

      StockService.getByStockAndDate(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
       

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    }
    
  }


  const getSelectOption = () => {
    setPage(1);
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    setPage(1);
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "OUT",
      text4: searchTitle
    }

    StockService.getStockInSearch(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByTitleWithName = () => {
    setPage(1);
    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "OUT",
      text4: searchTitle
    }

    StockService.getStockInSearchWithName(data)
      .then((response) => {
        // const { tutorials, totalPages } = response.data;

        setDatas(response.data);
        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
      setSearchTitle("");
  };



  const openObjectWithProduct = (rowIndex) => {
    setcurrentData(initialDataState);

    const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/stock-out/" + id);
  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };






  const logOut = () => {
    AuthService.logout();
  }




  const handleCallback = (data) => {

    setSearchedProductDetails(data);

  }

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {
    setPage(1);
    setStartDate(date);

  }



  const openObject = (id) => {

    props.history.push("/stock-out/" + id);
  };

  const add = () => {
    props.history.push("/stock-out-new");
  }

  const list = () => {
    props.history.push("/stock-in");
  }

  const stockLevel = () => {
    props.history.push("/stock-level");
  }

  const stockOut = () => {
    props.history.push("/stock-out");
  }

  const returned = () => {
    props.history.push("/stock-returned");
  }

  const stockInInspect = () => {
    props.history.push("/stock-out-inspect");
  }

  const [lgShow, setLgShow] = useState(false);
  const [smShow, setSmShow] = useState(false);

  const loadData = (id) => {
    setPage(1);

    setMessage("");

    StockService.get(id)
      .then(response => {
        setcurrentData(response.data);
        //console.log(response.data);
        getProductStockLevel(response.data.pid, response.data.stand);

      })
      .catch(e => {
        console.log(e);
      });


  };

  const getProductStockLevel = (pid, stock) => {
    setPage(1);

    var data = {
      text1: pid,
      text2: stock
    }

    StockService.getProductActualStock(data)
      .then(response => {
        // setcurrentData(response.data);

        setActualStock(response.data);

      })
      .catch(e => {
        console.log(e);
      });
  }

  const confirmation = () => {
    setPage(1);

    if (currentData.confirmation === "Confirmed") {
      setMessage("This was confirmed before");

    } else if (actualStock === 0 || actualStock < 0) {
      setMessage("We don't have this product in stock!");

    }else if(actualStock < currentData.qty_out){
      setMessage("We don't have this Qty in stock!");
    }
     else if (actualStock === null || actualStock === "") {
      setMessage("We don't have this product in stock!");
    } else {

      var data = {
        text1: currentData.pid,
        text2: currentData.stand,
        text3: currentData.qty_out,
        text4: currentData.product,
        text5: currentData.size,
        text6: currentData.color,
        text7: currentData.code,
        text8: currentData.id,
      }

      StockService.confirmationStockOut(data)
        .then(response => {
          // setcurrentData(response.data);

          setSubmitted(true);
          setMessage(response.data);

          refreshList();

        })
        .catch(e => {
          console.log(e);
          setMessage("There is some error.");
        });

    }

  }

  const resetFrom = () => {
    //console.log("=======reset=====");
    setSubmitted(false);
  }


  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}
         
          <div className="col-md-3 tool-box">
            <table className="table-tools">
            <tbody>
              <tr>
                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
               
                <td><DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
                <td><DatePicker className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                /></td>
              </tr>
              <tr>

                {isManager ? ( <td><div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>):( <td><div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option>{memberdetails.account}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>)}
               
                 
                <td ><button onClick={retrieveStockDataByStockAndDate} className="button-tools">Refresh</button></td>
              </tr>
</tbody>

            </table>

          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
              <tr>
                <td className="tg-0lax"><button onClick={()=>setSmShow(true)} className="button-tools">search</button></td>
                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                <td className="tg-0lax"><button onClick={list} className="button-tools">Supply</button></td>
              </tr>
              <tr>
                <td className="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
                <td className="tg-0lax"><button onClick={stockOut} className="button-tools">Out</button></td>
                <td className="tg-0lax"><button onClick={stockInInspect} className="button-tools">Inspect</button></td>
              </tr>
              </tbody>
            </table>
            
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title"> <span className="danger-message">{message}</span>  STOCK OUT </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>

                      <th>ID</th>
                      <th>BRANCH</th>
                      <th>STAND</th>
                      <th>DATE</th>
                      <th>PID</th>
                      <th>PRODUCT</th>
                      <th>CODE</th>
                      <th>SIZE</th>
                      <th>QTY</th>
                      <th>VALUE</th>
                      <th>TOTAL</th>
                      <th>STOCK KEEPER</th>
                      <th>DESCRIPTION</th>
                      <th>COMMENT</th>
                      <th>CONFIRMATION</th>
                      <th>TOOLS</th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.location}</td>
                          <td>{tutorial.stand}</td>
                          <td>{tutorial.date1}</td>
                          <td>{tutorial.pid}</td>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.size}</td>
                          <td>{tutorial.qty_out}</td>
                          <td>{tutorial.price_value}</td>
                          <td>{tutorial.price_value*tutorial.qty_out}</td>
                          <td>{tutorial.stockkeeper}</td>
                          <td><span className={"status " + tutorial.description}>{tutorial.description}</span></td>
                          <td>{tutorial.comment}</td>
                          <td><span className={"status " + tutorial.confirmation}>{tutorial.confirmation}</span></td>
                          <td><div className="table-action-button">

                            <button
                              type="button" className="btn"
                              onClick={() => openObject(tutorial.id)}
                            > <AiTwotoneEdit />
                            </button>

                            <button
                              type="button" className="btn"
                              onClick={() => setLgShow(true)}
                            >
                              <span onClick={() => loadData(tutorial.id)}><GrValidate /></span>


                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>
      <Modal
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <div className="details">

              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">


                    <div className="col-md-4">
                      <label>Product</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Product" onChange={handleInputChangeEdit} validations={[required]} value={currentData.product} />
                    </div>

                    <div className="col-md-4">
                      <label>Code</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChangeEdit} validations={[required]} value={currentData.code} />
                    </div>

                    <div className="col-md-4">
                      <label>Size</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={currentData.size} />
                    </div>
                    <div className="col-md-4">
                      <label>Color</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={currentData.color} />
                    </div>
                    <div className="col-md-4">
                      <label>Stock</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <select disabled id="stand" name="stand" className="form-control" onChange={handleInputChangeEdit} >
                        <option key={currentData.stand}>{currentData.stand}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{currentData.stand}</option>
                        ))}

                      </select>
                    </div>

                    <div className="col-md-4">
                      <label>Actual Stock</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly type="number" id="actualStock" className="form-control" name="actualStock" placeholder="Actual" onChange={handleInputChangeEdit} validations={[required]} value={actualStock} />
                    </div>

                    <div className="col-md-4">
                      <label>QTY</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly type="number" id="qty_out" className="form-control" name="qty_out" placeholder="QTY OUT" onChange={handleInputChangeEdit} validations={[required]} value={currentData.qty_out} />
                    </div>



                    <div className="col-md-4">
                      <label>Description</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly type="text" id="description" className="form-control" name="description" placeholder="Description" onChange={handleInputChangeEdit} validations={[required]} value={currentData.description} />
                    </div>

                    <div className="col-md-4">
                      <label>Comment</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly type="text" id="comment" className="form-control" name="comment" placeholder="Comment" onChange={handleInputChangeEdit} validations={[required]} value={currentData.comment} />
                    </div>

                    <div className="col-md-4">
                      <label>Confirmation</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <select readOnly id="confirmation" name="confirmation" className="form-control" onChange={handleInputChangeEdit} >
                        <option key={currentData.confirmation} >{currentData.confirmation}</option>
                        {optionCategoryArrayConfirmation.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>

                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <div className="col-md-8 form-group">

                      {submitted ? ("") : (<button
                        type="button" onClick={() => confirmation()} className="button-form"> Confirm</button>)}


                      <span onClick={resetFrom}>
                        <button
                          type="button" onClick={() => setLgShow(false)} className="button-form"> Close</button>
                      </span>

                    </div>


                    <div className="col-md-4">

                    </div>



                  </div>
                </div>
              </form>

            </div>




          </div>


        </Modal.Body>
      </Modal>

      {/*search */}

      <Modal
        size="md"
        backdrop="static"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

            Search <span className="table-tools-img-search"><FaIcons.FaSearch /></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <div className="details-search">

            <div className="row"> <input onChange={onChangeSearchTitle} id="search" type="text" name="customer" placeholder="Product name" className="search-input" /> </div>
            <hr/>
            <div className="row">  <button onClick={findByTitleWithName} className="button-tools-search">search with name </button>  </div>
            
            <div className="row">  <button onClick={findByTitle} className="button-tools-search">search with code</button> </div>
            <hr/>
            </div>




          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default StockListOut;
