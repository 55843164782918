import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GrValidate } from 'react-icons/gr';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen, AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import OrderService from "../../services/order.service";
import MemberService from "../../services/members.service";
import { format } from 'date-fns';
import StockkeeperService from "../../services/stockkeeper.service";
import SelectOptionService from "../../services/select-options.service";



const currentUser = AuthService.getCurrentUser();


const StockkeeperReceivedRequest = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_STOCK")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",
    size: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    color: "",
    stock_type: "",
    stockEntryId: "",
    returned: "",
    confirmation: "",
    location: "",
    qty_requested: "",
    stand_supply_request_date: "",
    stand_supply_request_officer: "",
    business_operation_manager_name: "",
    business_operation_manager_approval: "",
    business_operation_manager_approval_date: "",
    branch_manager_name: "",
    branch_manager_approval: "",
    branch_manager_approval_date: "",
    stockkeeper_name: "",
    stockkeeper_approval: "",
    stockkeeper_approval_date: "",
    stockkeeper_approval_qty: "",
    stand_receiption_confirmation: "",
    stand_receiption_confirmation_date: "",
    request_Status: "",


  };



  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [isCashier, setIsCashier] = useState(false);
  const [isBusinessManagerApproved, setIsBusinessManagerApproved] = useState(false);
  const [isBranchManagerApproved, setIsBranchManagerApproved] = useState(false);
  const [isStockKeeperApproved, setIsStockKeeperApproved] = useState(false);
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValueStockkeeper, setOptionCategorySelectedValueStockkeeper] = useState("");
  const [optionCategorySelectedValueBussinessOperation, setOptionCategorySelectedValueBussinessOperation] = useState("");
  const [optionCategorySelectedValueBranchManager, setOptionCategorySelectedValueBranchManager] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [currentOrderDataSummaryByProductId, setCurrentOrderDataSummaryByProductId] = useState([]);
  const [approvalDecision, setApprovalDecision] = useState("");
  const [message, setMessage] = useState("");
  const [isManager, setIsManager] = useState(false);
  const [stockName, setStockName] = useState("");

  const [stockKeeperAvairableQty, setStockKeeperAvairableQty] = useState("");
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setStockName(event.target.value);

  }

  const handleChangeoptionCategoriesStockKeeper = (event) => {
    setOptionCategorySelectedValueStockkeeper(event.target.value);

  }

  const handleChangeoptionCategoriesBusinessOperation = (event) => {
    setOptionCategorySelectedValueBussinessOperation(event.target.value);

  }

  const handleChangeoptionCategoriesBranchManager = (event) => {
    setOptionCategorySelectedValueBranchManager(event.target.value);

  }


  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };




  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");

  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const retrieveOrderedData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setStockName(optionCategorySelectedValue);

    } else {
      setStockName(memberdetails.account);
    }



    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: stockName,


    }

    
    StockkeeperService.getPendingRequest(data)
      .then((response) => {

        const { tutorials } = response.data;

        setOrderedData(tutorials);



      })
      .catch((e) => {
        console.log(e);
      });

  }

  const retrieveData = () => {
    setPage(1);

    if (currentUser.roles.includes("ROLE_SALES")) {
      setIsCashier(true);
    }

    getMemberData();
    retrieveOrderedData();
    getSelectOption();


  }


  const getSelectOption = () => {
    setPage(1);
    var category = "STOCK-NAME";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);


      })
      .catch((e) => {
        console.log(e);
      });
  };

  //useEffect(retrieveOrderedData);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const request = () => {
    props.history.push("/stockkeeper-request-form");
  }

  const payment = (data) => {
    props.history.push("/payment-form?data=" + data);
  }



  useEffect(retrieveData, [page, pageSize]);


  const searchOrder = () => {
    setPage(1);
    OrderService.searchOrder(searchText)
      .then(response => {
        setOrderedData(response.data);

        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

      if (currentUser.roles.includes("ROLE_MANAGER")) {
        setIsManager(true);
  
      }

  }

  const openData = (id) => {
    setPage(1);
    setMessage("");

    StockkeeperService.getRequestByNo(id)
      .then(response => {
        const { currentOrderDataSummary, currentOrderData } = response.data;

        setCurrentOrderDataSummary(currentOrderDataSummary);
        setCurrentOrderData(currentOrderData);

        if (currentOrderDataSummary.business_operation_manager_approval === "APPROVED") {
          setIsBusinessManagerApproved(true);
        } else {
          setIsBusinessManagerApproved(false);
        }

        if (currentOrderDataSummary.branch_manager_approval === "APPROVED") {
          setIsBranchManagerApproved(true);
        } else {
          setIsBranchManagerApproved(false);
        }

        if (currentOrderDataSummary.stockkeeper_approval === "APPROVED") {
          setIsStockKeeperApproved(true);
        } else {
          setIsStockKeeperApproved(false);
        }


      })
      .catch(e => {
        console.log(e);
      });

  }

  const openObject = (id) => {

    setMessage("");

    setPage(1);

    StockkeeperService.getRequestById(id)
      .then(response => {
        const { currentOrderDataSummaryByProductId } = response.data;

        setCurrentOrderDataSummaryByProductId(currentOrderDataSummaryByProductId);


        setLgShow2(true);

      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };


  const approveRequestApproved = (approvalLevel) => {

    setMessage("");
    setPage(1);


    if (approvalLevel === "BUSINESS-OPERATION") {
      setApprovalDecision(optionCategorySelectedValueBussinessOperation);
    } else if (approvalLevel === "BRANCH-MANAGER") {
      setApprovalDecision(optionCategorySelectedValueBranchManager);
    } else if (approvalLevel === "STOCKKEEPER") {

      

        setApprovalDecision(optionCategorySelectedValueStockkeeper);

      
      
    }

    var data = {
      text1: approvalLevel,
      text2: currentUser.username,
      text3: "APPROVED",
      text4: currentOrderDataSummary.stockEntryId


    }

    

    setMessage("");

    if(!currentUser.roles.includes("ROLE_STOCK_KEEPER") && data.text1==="STOCKKEEPER"){
      setMessage("Your're not a stock keeper.");
   }else if(!currentUser.roles.includes("ROLE_STAND_KEEPER") && data.text1==="STANDKEEPER"){
    setMessage("Your're not a stand keeper.");
   }
   else{

    StockkeeperService.approveRequest(data)
      .then(response => {

        openData(currentOrderDataSummary.stockEntryId);



        setMessage(response.data);

        if (response.data === "") {

          setLgShow3(false);

        } else {

          setLgShow3(true);

        }


      })
      .catch(e => {
        console.log(e);
      });


   }

    
  }

  const approveRequestReview = (approvalLevel) => {
    setPage(1);


    if (approvalLevel === "BUSINESS-OPERATION") {
      setApprovalDecision(optionCategorySelectedValueBussinessOperation);
    } else if (approvalLevel === "BRANCH-MANAGER") {
      setApprovalDecision(optionCategorySelectedValueBranchManager);
    } else if (approvalLevel === "STOCKKEEPER") {
      setApprovalDecision(optionCategorySelectedValueStockkeeper);
    }

    var data = {
      text1: approvalLevel,
      text2: currentUser.username,
      text3: "REVIEW",
      text4: currentOrderDataSummary.stockEntryId


    }

    if (currentOrderDataSummary.business_operation_manager_approval === "" || currentOrderDataSummary.business_operation_manager_approval === "PENDING") {
      setMessage();
    }

    StockkeeperService.approveRequest(data)
      .then(response => {
        openData(currentOrderDataSummary.stockEntryId);

        if (response.data === "") {

          setLgShow3(false);

        } else {

          setLgShow3(true);

        }


      })
      .catch(e => {
        console.log(e);
      });

  }


  const approveRequestRejected = (approvalLevel) => {
    setPage(1);


    if (approvalLevel === "BUSINESS-OPERATION") {
      setApprovalDecision(optionCategorySelectedValueBussinessOperation);
    } else if (approvalLevel === "BRANCH-MANAGER") {
      setApprovalDecision(optionCategorySelectedValueBranchManager);
    } else if (approvalLevel === "STOCKKEEPER") {
      setApprovalDecision(optionCategorySelectedValueStockkeeper);
    }

    var data = {
      text1: approvalLevel,
      text2: currentUser.username,
      text3: "REJECTED",
      text4: currentOrderDataSummary.stockEntryId


    }

    StockkeeperService.approveRequest(data)
      .then(response => {

        openData(currentOrderDataSummary.stockEntryId);

        if (response.data === "") {

          setLgShow3(false);

        } else {

          setLgShow3(true);

        }

      })
      .catch(e => {
        console.log(e);
      });

  }

  const pendingOrder = (data) => {
    props.history.push("/sales-pending-order");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const sales = (data) => {
    props.history.push("/sales-list");

  }

  const requestList = () => {
    props.history.push("/stockkeeper-list");
  }


  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const componentRef = useRef(null);
  const componentRef2 = useRef(null);


  const deleteData = (id) => {

   

    setPage(1);

    OrderService.deleteOrder(id)
      .then((response) => {
        /// props.history.push("/product");

        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleInputChange_stockkeeper_approval_qty = (event) => {

    setStockKeeperAvairableQty(event.target.value);

  }

  const stockKeeperconfirmAvairableQty = () => {

    if (stockKeeperAvairableQty === "" || stockKeeperAvairableQty === 0) {

      setMessage("You can't provide empty qty!");

    } else {

      var data = {
        text1: currentOrderDataSummaryByProductId.id,
        text2: stockKeeperAvairableQty
      }

      StockkeeperService.approveRequestStockKeeperAvairableQty(data)
        .then(response => {
          openData(currentOrderDataSummary.stockEntryId);
          setLgShow2(false);

        })
        .catch(e => {
          console.log(e);
        });

    }




  }

  

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                {isManager ? ( <td><div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>):( <td><div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option>{memberdetails.account}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>)}
                  {/* <td><button onClick={searchOrder} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td> */}
                </tr>
              </tbody>
            </table>


          </div>
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td>From</td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                <tr>
                  <td>To</td>
                  <td ><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
              </tbody>
            </table>




          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={requestList} className="button-tools">Requests</button></td>
                  <td className="tg-0lax"><button onClick={request} className="button-tools-red">New</button></td>
                </tr>

              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">
              </div>

              <div className="col-md-3 table-title">  PENDING REQUEST </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>REF</th>
                      <th>BRANCH</th>
                      <th>STAND</th>
                      <th>TIME</th>
                      <th>STOCKKEEPER</th>
                      <th>STAND KEEPER</th>
                      <th>STATUS</th>
                      <th>Tools</th>


                    </tr>
                  </thead>
                  <tbody>
                    {orderedData &&
                      orderedData.map((tutorial, index) => (

                        <tr key={tutorial.stockEntryId}>
                          <td>{tutorial.stockEntryId}</td>
                          <td>{tutorial.location}</td>
                          <td>{tutorial.stand}</td>
                          <td>{tutorial.stand_supply_request_date}</td>
                          <td>{tutorial.stockkeeper_name}</td>
                          <td>{tutorial.stand_supply_request_officer}</td>
                          <td><span className={"status " + tutorial.request_Status}>{tutorial.request_Status}</span></td>

                          <td><div className="row">

                            <button onClick={() => setLgShow(true)} type="button" className="col-md-1 table-buttons" >
                              <span onClick={() => openData(tutorial.stockEntryId)}>  <AiFillFolderOpen /> </span>
                            </button>




                          </div></td>

                        </tr>

                      ))}
                  </tbody>

                </table>
              </div>

            </div>

          </div>
        </div>

      </div>
      <Modal ref={componentRef}
        size="xl"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

              Details

              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="row">

            <div className="col-md-4 custom-marging">

           
              REQUEST# {currentOrderDataSummary.stockEntryId}
              <hr />
              <div className="row">Name: {currentOrderDataSummary.stand_supply_request_officer}</div>
              <div className="row">Stand: {currentOrderDataSummary.stand}</div>
              <div className="row">Date: {currentOrderDataSummary.stand_supply_request_date}</div>
              <div className="row">Reception: {currentOrderDataSummary.stand_receiption_confirmation}</div>
              <div className="row">Reception date: {currentOrderDataSummary.stand_receiption_confirmation_date}</div>
              <hr />
              <div className="row">
                {isStockKeeperApproved ? (<button onClick={() => approveRequestApproved("STANDKEEPER")} className="button-tools-stock-keeper">RECEIVED</button>) : ("")}
              
            </div> 
              
            </div> 
            <div className="col-md-4 custom-marging">
            
              STOCK KEEPER
              <hr />
              <div className="row"></div>
              <div className="row">Stockkeeper:{currentOrderDataSummary.stockkeeper_name}</div>
              <div className="row">Date:{currentOrderDataSummary.stockkeeper_approval_date}</div>
              
              <div className="row"><br/><br/><br/></div>
              <hr />

              <div className="row">
                {isBranchManagerApproved ? (<button onClick={() => approveRequestApproved("STOCKKEEPER")} className="button-tools-stock-keeper">APPROVED</button>) : ("")}
              </div>
              {/* <div className="row">{isBranchManagerApproved ? (<button onClick={() => approveRequestReview("STOCKKEEPER")} className="button-confirmation-stockkeeper-yellow">REVIEW</button>) : ("")} </div>
              <div className="row">{isBranchManagerApproved ? (<button onClick={() => approveRequestRejected("STOCKKEEPER")} className="button-confirmation-stockkeeper-red">DENIED</button>) : ("")}  </div> */}
            
            </div>

 
   

            

            {/* <div className="details">
              OPERATION MANAGER
              <hr />
              <div className="row"></div>
              <div className="row">Name:{currentOrderDataSummary.business_operation_manager_name}</div>
              <div className="row">Date:{currentOrderDataSummary.business_operation_manager_approval_date}</div>
              <div className="row">Approval:{currentOrderDataSummary.business_operation_manager_approval}</div>
              <hr />
               
              <div className="row"><button onClick={()=>approveRequestApproved("BUSINESS-OPERATION")} className="button-confirmation-stockkeeper-green">APPROVED</button></div>
              <div className="row"><button onClick={()=>approveRequestReview("BUSINESS-OPERATION")} className="button-confirmation-stockkeeper-yellow">REVIEW</button></div>
              <div className="row"><button onClick={()=>approveRequestRejected("BUSINESS-OPERATION")} className="button-confirmation-stockkeeper-red">DENIED</button></div>
            </div> */}

            {/* <div className="details">
              BRANCH MANAGER
              <hr />
              <div className="row"></div>
              <div className="row">Name:{currentOrderDataSummary.branch_manager_name}</div>
              <div className="row">Date:{currentOrderDataSummary.branch_manager_approval_date}</div>
              <div className="row">Approval:{currentOrderDataSummary.branch_manager_approval}</div>
              <hr />
              <div className="row">

               {isBusinessManagerApproved ? (<button onClick={()=>approveRequestApproved("BRANCH-MANAGER")} className="button-confirmation-stockkeeper-green">APPROVED</button>):(" ")}
                
                </div>
              <div className="row">
                {isBusinessManagerApproved ? (<button onClick={()=>approveRequestReview("BRANCH-MANAGER")} className="button-confirmation-stockkeeper-yellow">REVIEW</button>):(" ")}
              </div>
              <div className="row">{isBusinessManagerApproved ? (<button onClick={()=>approveRequestRejected("BRANCH-MANAGER")} className="button-confirmation-stockkeeper-red">DENIED</button>):(" ")} </div>
            </div> */}
            
            
          </div>

          <div className="dataRow">
            <div className="details">

              <div className="row"><button onClick={() => setLgShow(false)} className="button-tools col-md-1">Close</button> </div> 
              {/* <button onClick={() => deleteData(currentOrderDataSummary.orderNo)} className="button-tools col-md-1">Delete</button> */}

              <table>
                <tbody>
                  <tr>


                    <td><span className="danger-message">{message}</span> </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>SIZE</th>
                      <th>COLOR</th>
                      <th>QTY-REQUEST</th>
                      <th>QTY-STOCKKEEPER</th>
                      <th>TOOL</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.size}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.qty_requested}</td>
                          <td>{tutorial.stockkeeper_approval_qty}</td>
                          <td>
                            <div className="row">
                              <button onClick={() => setLgShow(true)} type="button" className="col-md-1 table-buttons" >
                                <span onClick={() => openObject(tutorial.id)}>  <AiTwotoneEdit /> </span>
                              </button>


                            </div>
                          </td>

                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>
            </div>


          </div>


        </Modal.Body>
      </Modal>

      <Modal ref={componentRef2}
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

              Details

              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow-modal-header">




          </div>

          <div className="dataRow">
            <div className="details">

              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">

                    <div className="col-md-4">
                      <label>Product</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Item name" onChange={handleInputChange} value={currentOrderDataSummaryByProductId.product} />
                    </div>

                    <div className="col-md-4">
                      <label>CODE</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChange} value={currentOrderDataSummaryByProductId.code} />
                    </div>

                    <div className="col-md-4">
                      <label>size</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChange} value={currentOrderDataSummaryByProductId.size} />
                    </div>

                    <div className="col-md-4">
                      <label>Color</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChange} value={currentOrderDataSummaryByProductId.color} />
                    </div>

                    <div className="col-md-4">
                      <label>Stand</label>
                    </div>

                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="stand" className="form-control" name="stand" placeholder="Stand" onChange={handleInputChange} value={currentOrderDataSummaryByProductId.stand} />
                    </div>

                    <div className="col-md-4">
                      <label>QTY Requested</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="number" id="qty" className="form-control" name="qty" placeholder="QTY" value={currentOrderDataSummaryByProductId.qty_requested} />
                    </div>


                    <div className="col-md-4">
                      <label>QTY</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="number" id="stockkeeper_approval_qty" className="form-control" name="stockkeeper_approval_qty" placeholder="QTY" onChange={handleInputChange_stockkeeper_approval_qty} />
                    </div>



                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">

                    </div>

                    <div className="row col-md-8 form-group">
                      <button
                        type="button" onClick={() => stockKeeperconfirmAvairableQty()} className="col-md-4 button-tools"> OK</button>

                      <button
                        type="button" onClick={() => setLgShow2(false)} className="col-md-4 button-tools">  Close</button>

                    </div>



                  </div>
                </div>
              </form>


            </div>


          </div>


        </Modal.Body>
      </Modal>

      <Modal ref={componentRef2}
        size="md"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

              Warning

              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow-modal-header">

          </div>

          <center> <span className="danger-message">{message}</span>  </center>









        </Modal.Body>
      </Modal>
    </>
  );
});

export default StockkeeperReceivedRequest;
