import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/delivery", { params,headers: authHeader() });
};

const getDeliverySearch = (data) => {
  return http.post("/deliverysearch/",data, { headers: authHeader() });
};

const getReportByDate = (data) => {
  return http.post("/deliveryreport/",data, { headers: authHeader() });
};

const getReportByDateReste = (data) => {
  return http.post("/deliveryreportreste/",data, { headers: authHeader() });
};

const getReportByDatePending = (data) => {
  return http.post("/deliveryreportpending/",data, { headers: authHeader() });
};

const getReportByDateCompleted = (data) => {
  return http.post("/deliveryreportcompleted/",data, { headers: authHeader() });
};


const get = (id) => {
  return http.get(`/delivery/${id}`,{ headers: authHeader() });
};

const getByOrderNo = (orderno) => {
  return http.get(`/deliverybyorder/${orderno}`,{ headers: authHeader() });
};

const getResteByOrderNo = (orderno) => {
  return http.get(`/deliveryrestebyorder/${orderno}`,{ headers: authHeader() });
};


const update = (id, data) => {
  return http.put(`/delivery/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/delivery/${id}`,{ headers: authHeader()});
};



const findByTitle = (title) => {
  return http.get(`/delivery?code=${title}`);
};

const lockDelivery = (id) => {
 
  return http.get(`/lockdelivery/${id}`,{ headers: authHeader() });
};

const authorizeDelivery = (id) => {
 
  return http.get(`/authorizedelivery/${id}`,{ headers: authHeader() });
};



const DeliveryService = {
  getAll,
  getReportByDate,
  getReportByDateReste,
  getReportByDatePending,
  getReportByDateCompleted,
  getResteByOrderNo,
  get,
  getByOrderNo,
  update,
  remove,
  findByTitle,
  lockDelivery,
  authorizeDelivery,
  getDeliverySearch
  
};

export default DeliveryService;