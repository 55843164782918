import React, { useState, useRef,useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import SelectOptionService from "../../services/select-options.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import logo from '../images/logo.png'



var pass1;

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 200) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 100 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const vuser_group = (value) => {
  if (value.length < 1) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();


const UserRegistration = withRouter((props) => {



  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    return <Redirect to="/unauthorized" />;

}

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [account, setAccount] = useState("");

  const [user_group, setUser_group] = useState("GUEST");
  const [password_expiration_date, setPassword_expiration_date] = useState("");
  const [report_duration, setReport_duration] = useState("0");
  const [disabled, setDisabled] = useState("YES");
  const [otp_enabled, setOtp_enabled] = useState("YES");

  const [successful, setSuccessful] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");

 

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    pass1 = e.target.value;
    setPassword(password);
  };

  const onChangePassword2 = (e) => {
    const password2 = e.target.value;
    setPassword2(password2);
  };


  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeAccount = (e) => {
    const account = e.target.value;
    setAccount(account);
  };

  const onChangeUser_group = (e) => {
    const user_group = e.target.value;
    setUser_group(user_group);
  };

  const onChangePassword_expiration_date = (e) => {
    const password_expiration_date = e.target.value;
    setPassword_expiration_date(password_expiration_date);
  };

  const onChangeReport_duration = (e) => {
    const report_duration = e.target.value;
    setReport_duration(report_duration);
  };

  const onChangeDisabled = (e) => {
    const disabled = e.target.value;
    setDisabled(disabled);
  };

  const onChangeOtp_enabled = (e) => {
    const otp_enabled = e.target.value;
    setOtp_enabled(otp_enabled);
  };


  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {


      AuthService.register(username, email, password, firstname, lastname, phone, account,user_group,password_expiration_date,report_duration,disabled,otp_enabled).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
          setSubmitted(true);

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const vpassword2 = (value) => {
    if (pass1 != value) {
      return (
        <div className="alert alert-danger" role="alert">
          Passwords not matching!!!
        </div>
      );
    }
  };

  const list = () => {
    props.history.push("/users/");
  };

  const add = () => {
    props.history.push("/registration/");
  };

  

  
  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          


        </div>

        <div className="col-md-3 tool-box">

          <table>
            <tbody>
            <tr>
              <td><div className="page-title-img"><FaIcons.FaUsers /> </div></td>
              <td><div className="page-title"> User registration</div></td>
            </tr>
            </tbody>
          </table>


        </div>

        <div className="col-md-3 tool-box">

          <table className="table-tools">
            <tbody>
            <tr>
              <td class="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
              <td class="tg-0lax"></td>
              <td class="tg-0lax"></td>
            </tr>
            <tr>
              <td class="tg-0lax"><button onClick={list} className="button-tools">List</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
            </tr>
            <tr>
            <td class="tg-0lax"><button onClick={add} className="button-tools">Roles</button></td>
              <td class="tg-0lax"></td>
            </tr>
            </tbody>
          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">

          <center>
            <div className="details-small">

              <div className="card-body form-horizontal">

                <Form className="form" onSubmit={handleRegister} ref={form}>
                  {!successful && (
                    <div className="form-body">
                      <div className="row">

                        <div className="col-md-3 form-label-text">
                          <label>First name</label>

                        </div>
                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="First name"
                            className="form-control form-input-text"
                            name="firstname"
                            value={firstname}
                            onChange={onChangeFirstname}
                            validations={[required]}
                          />

                        </div>
                        <div className="col-md-3 form-label-text">
                          <label>Last name</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Last names"
                            className="form-control form-input-text"
                            name="lastname"
                            value={lastname}
                            onChange={onChangeLastname}
                            validations={[required]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>Username</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Username (Must be email)"
                            className="form-control form-input-text"
                            name="username"
                            value={username}
                            onChange={onChangeUsername}
                            validations={[required, validEmail]}
                          />
                        </div>
                        <div className="col-md-3 form-label-text">
                          <label>Phone</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Phone"
                            className="form-control form-input-text"
                            name="phone"
                            value={phone}
                            onChange={onChangePhone}
                            validations={[required]}
                          />
                        </div>
                        <div className="col-md-3 form-label-text">
                          <label>Location</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Account"
                            className="form-control form-input-text"
                            name="account"
                            value={account}
                            onChange={onChangeAccount}
                            validations={[required]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>Password</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="password"
                            placeholder="Password"
                            className="form-control form-input-text"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[required, vpassword]}
                          />
                        </div>


                        <div className="col-md-3 form-label-text">
                          <label>Confirm passord</label>
                        </div>
                        <div className="col-md-8">
                          <Input
                            type="password"
                            placeholder="Confirm password"
                            className="form-control form-input-text"
                            name="password2"
                            value={password2}
                            onChange={onChangePassword2}
                            validations={[required, vpassword2]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>Expiration</label>
                        </div>

                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Expiration"
                            className="form-control form-input-text"
                            name="password_expiration_date"
                            value={password_expiration_date}
                            onChange={onChangePassword_expiration_date}
                            validations={[required]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>Group</label>
                        </div>

                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Group"
                            className="form-control form-input-text"
                            name="user_group"
                            disabled
                            value={user_group}
                            onChange={onChangeUser_group}
                            validations={[required]}
                          />
                        </div>

                       

                        <div className="col-md-3 form-label-text">
                          <label>Report duration</label>
                        </div>

                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Report duration"
                            className="form-control form-input-text"
                            name="report_duration"
                            disabled
                            value={report_duration}
                            onChange={onChangeReport_duration}
                            validations={[required]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>Disabled</label>
                        </div>

                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="Disabled"
                            className="form-control form-input-text"
                            name="disabled"
                            disabled="true"
                            value={disabled}
                            onChange={onChangeDisabled}
                            validations={[required]}
                          />
                        </div>

                        <div className="col-md-3 form-label-text">
                          <label>OTP Enabled</label>
                        </div>

                        <div className="col-md-8">
                          <Input
                            type="text"
                            placeholder="OTP Enabled"
                            className="form-control form-input-text"
                            name="otp_enabled"
                            disabled
                            value={otp_enabled}
                            onChange={onChangeOtp_enabled}
                            validations={[required]}
                          />
                        </div>



                        <div className="col-md-3 form-label-text">
                          <label> </label>
                        </div>
                        <div className="col-md-8">
                          <button type="submit" className="button-form">Submit</button>

                          {message}

                        </div>


                      </div>

                    </div>
                  )}

                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful ? "alert-success" : "alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>

            </div>
          </center>
        </div>
      </div>

    </div>
  );
});

export default UserRegistration;
