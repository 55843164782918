import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiTwotoneEdit } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import VideService from "../../services/vide.service";
//import ProductsService from "../../services/products.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";




const currentUser = AuthService.getCurrentUser();


const VideList = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_out: "",
    exitState: "",
    qty_returned: "",
    returnedState: "",
    stockkeeper: "",
    code: "",
    size: "",
    description: "",
    comment: "",
    stockEntryId: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [isReport, setIsReport] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [videSum, setVideSum] = useState([]);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeoptionCategoriesTransfer = (event) => {
    setOptionCategorySelectedValueTransfer(event.target.value);

  }



  const handleExportToExcel = event => {
    console.log("=======data export=====");
  };




  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchedProductDetails, setSearchedProductDetails] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeSearchProduct = (e) => {
    const searchProduct = e.target.value;
    setSearchProduct(searchProduct);

  };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };


  const retrieveData = () => {

setIsReport(false);

    var data = {
      text1: format(startDate, 'yyyy-MM-dd'),
      text2: format(endDate, 'yyyy-MM-dd'),
      text3: "IN"
    }

    const params = getRequestParams(searchTitle, page, pageSize);
    VideService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    getSelectOption();
    getSelectOptionTransfer();
    getMemberData();
  };

  const getMemberData = () => {

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectOptionTransfer = () => {
    var category = "Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
      .then((response) => {


        setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };




  const openObjectWithProduct = (id) => {
    setcurrentData(initialDataState);

    //const id = tutorialsRef.current[rowIndex].id;

    props.history.push("/vide-view/" + id);
  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const handleSearch = () => {
    setPage(1);
    retrieveData();
  };


  const handleCallback = (data) => {

    setSearchedProductDetails(data);

  }

  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }



  const openObject = (rowIndex) => {


  };

  const stockOut = () => {
    props.history.push("/stock-out");
  }



  const generateDefaultStockLevel = () => {
    VideService.generateDefaultStockLevel();
  }

  const report = () => {

    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: optionCategorySelectedValue,
    }

    VideService.report(data)
      .then((response) => {


        setDatas(response.data);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

      
    VideService.reportSum(data)
      .then((response) => {


        setVideSum(response.data);

       
      })
      .catch((e) => {
        //console.log(e);
        setIsReport(false);
      });
      setIsReport(true);

  }

  const stockLevel = () => {
    props.history.push("/vide-level");
  }

  const add = () => {
    props.history.push("/vide-new");
  }




  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="customer" placeholder="Product name" className="search-input" />  </td>
              <td><button onClick={handleSearch} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table className="table table-tools">

            <tr>
              <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
              <td><DatePicker className="form-control datePicker-style"
                selected={startDate}
                onChange={handleChangeStartDate}
                name="startDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
              <td><DatePicker className="form-control datePicker-style"
                selected={endDate}
                onChange={handleChangeEndDate}
                name="endDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              /></td>
            </tr>
            <tr>
              <td><div className="col-md-8 form-group">
                <select className="form-control select-option" onChange={handleChangeoptionCategories} >
                  <option>Select</option>
                  {optionCategoryArray.map((optionCategory) => (
                    <option value={optionCategory.value}>{optionCategory.value}</option>
                  ))}

                </select>
              </div></td>
              <td ><button onClick={report} className="button-tools">Report</button></td>
            </tr>

          </table>




        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">

            <tr>
              <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools-red">New</button></td>
              <td class="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
            </tr>
            <tr>
              <td class="tg-0lax"></td>
            </tr>

          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">
          <div className="row pagination-box">

            <div className="col-md-3 pagination-box">{"Items per Page : "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select></div>

            <div className="col-md-3 table-title">  VIDE </div>

            <div className="col-md-4"><Pagination

              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />

            </div>
            {isReport?(<center> 
              <h3>
              OUT:{videSum.qty_out} | RETURNED:{videSum.qty_returned} | BALANCE:{videSum.qty_balance}
            </h3>
            </center>):("")}
            
          </div>
          <div className="details">

            <div className="table-wrap">

              <table className="table ">
                <thead className="scroll-thead">
                  <tr>


                    <th>STAND</th>
                    <th>DATE</th>
                    <th>ORDER NO</th>
                    <th>PRODUCT</th>
                    <th>OUT</th>
                    <th>STATE</th>
                    <th>RETURNED</th>
                    <th>STATE</th>
                    <th>BALANCE</th>
                    <th>COMPLETED</th>
                    <th>DESTINATION</th>
                    <th>COMMENT</th>
                    <th>Action</th>


                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.stand}</td>
                        <td>{tutorial.date1}</td>
                        <td>{tutorial.orderNo}</td>
                        <td>{tutorial.product}</td>
                        <td>{tutorial.qty_out}</td>
                        <td>{tutorial.exitState}</td>
                        <td>{tutorial.qty_returned}</td>
                        <td>{tutorial.returnedState}</td>
                        <td>{tutorial.balance}</td>
                        <td><span className={"status " + tutorial.completed}>{tutorial.completed}</span></td>
                        <td>{tutorial.description}</td>
                        <td>{tutorial.comment}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObjectWithProduct(tutorial.id)}
                          > <AiTwotoneEdit />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>

            </div>

          </div>

        </div>
      </div>

    </div>
  );
});

export default VideList;
