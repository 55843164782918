import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices, FcBusinesswoman } from 'react-icons/fc';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import OrderService from "../../services/order.service";
import MemberService from "../../services/members.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};


const currentUser = AuthService.getCurrentUser();


const SalesOrderSuccess = withRouter((props) => {
    
    
    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_SALER")) {
        return <Redirect to="/unauthorized" />;

    }

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/


    const [orderNo, setOrderNo] = useState("");
    const [orderTime, setorderTime] = useState("");
    const [orderCustomer, setorderCustomer] = useState("");
    const [orderSaller, setorderSaller] = useState("");
    const [orderAmount, setorderAmount] = useState("");
    const [orderPhone, setorderPhone] = useState("");
    const [orderStatus, setorderStatus] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const tutorialsRef = useRef();

    const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const pageSizes = [3, 5, 10];

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }

    
    const retrieveData = () => {
        setPage(1);

        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');


        OrderService.getSavedOrder(id)
            .then((response) => {


                setOrderNo(response.data.orderid);
                setorderCustomer(response.data.customer);
                setorderPhone(response.data.phone);
                setorderTime(response.data.orderTime);
                setorderAmount(response.data.amount);
                setorderSaller(response.data.saller)
                setorderStatus(response.data.status)


                //  console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        getMemberData();

    };

    useEffect(retrieveData, [page, pageSize]);


    const list = () => {
        props.history.push("/sales-pending-order");
    };

    const home = () => {
        props.history.push("/home");
    };


    const receipt = () => {
        props.history.push(`/sales-receipt/?id=${orderNo}`);

    }


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{memberdetails.firstname}</td>
                            <td>{memberdetails.lastname}</td>
                        </tr>
                        <tr>
                            <td>Branch:</td>
                            <td>{memberdetails.account}</td>
                        </tr>
                        <tr>
                            <td colSpan="2"></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                        <tr>
                            <td><span className="page-title-img"><FaIcons.FaShoppingCart /> </span></td>
                            <td><span className="page-title"> Orders</span></td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                            <td className="tg-0lax"><button onClick={home} className="button-tools">Home</button></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"> </td>
                        </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <span className="form-title danger-message">Your order was saved successfully </span>

                            <form className="form form-horizontal ">
                                <div className="form-body">
                                    <div className="row">

                                        <div className="col-md-4">
                                            <label>Customer</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="description" className="form-control" name="customer" placeholder="Customer name" value={orderCustomer} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Phone</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="comment" className="form-control" name="phone" placeholder="Phone" value={orderPhone} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Tracking no</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Tracking" value={orderNo} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Amount</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="code" className="form-control" name="amount" placeholder="amount" value={orderAmount} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Saller</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="saller" className="form-control" name="saller" placeholder="saller" value={orderSaller} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Order Time</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="ordertime" className="form-control" name="time" placeholder="saller" value={orderTime} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Status</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input readOnly="readonly" type="text" id="status" className="form-control" name="status" placeholder="saller" value={orderStatus} />
                                        </div>
                                        <div className="col-md-4">
                                            <label></label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <button onClick={list} className="button-tools">Orders</button>
                                           {/* <button onClick={receipt} className="button-tools">Print</button> */}
                                        </div>



                                    </div>
                                </div>
                            </form>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
});

export default SalesOrderSuccess;
