import http from "./http-common";
import authHeader from "./auth-header";

const getPendingRequest = (data) => {
  return http.post("/pendingrequest", data, { headers: authHeader() });
};

const getRequestReport = (data) => {
  return http.post("/requestreport", data, { headers: authHeader() });
};

const getAllIn = (params) => {
  return http.get("/stockinlist", { params,headers: authHeader() });
};



const getRequestById = (id) => {
  return http.get(`/currentrequestbyid/${id}`,{ headers: authHeader() });
};

const create = (data) => {
  return http.post("/stockkeeper", data,{ headers: authHeader() });
};

//get tempolary saved stand request
const getRequestByNoTemp = (request) => {
  
  return http.get(`/currentrequesttemp/${request}`, { headers: authHeader() });
};

const getRequestByNo = (request) => {
  
  return http.get(`/currentrequest/${request}`, { headers: authHeader() });
};


const remove = (id) => {
  return http.delete(`/currentrequest/${id}`,{ headers: authHeader()});
  
};
 
const saveNewRequest = (request) => {
  return http.get(`/newrequest/${request}`, { headers: authHeader() });
};

const approveRequest = (data) => {
  
  return http.post("/approverequest",data, { headers: authHeader() });
};

const approveRequestStockKeeperAvairableQty = (data) => {
  
  return http.post("/approverequestAvairableqty",data, { headers: authHeader() });
};

const updatePrintingVersion = (id) => {
  return http.get(`/printingversion/${id}`,{ headers: authHeader() });
};

const StockkeeperService = {
 
  create,
  remove,
  getRequestByNoTemp,
  getRequestByNo,
  saveNewRequest,
  getPendingRequest,
  approveRequest,
  getRequestById,
  approveRequestStockKeeperAvairableQty,
  getRequestReport,
  updatePrintingVersion
  
  
};

export default StockkeeperService;
