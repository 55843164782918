import React, { useState, useEffect, useMemo, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { ImPencil } from "react-icons/im";
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const currentUser = AuthService.getCurrentUser();


const ProductList = withRouter((props) => {


    if (JSON.parse(localStorage.getItem("user")) == null) {

        return <Redirect to="/login" />;

    }

    let decodedToken = jwt_decode(currentUser.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

        return <Redirect to="/login" />;

    }

    if (!currentUser.roles.includes("ROLE_PRODUCTS")) {
        return <Redirect to="/unauthorized" />;

    }

    const initialDataState = {
        id: null,
        code: "",
        name: "",
        description: "",
        min_price: "",
        max_price: "",
        suply_price: "",
        color: "",
        size: "",
        remark: "",
        username: "",

        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [currentData, setcurrentData] = useState(initialDataState);
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [optionCategoryArrayConfirmation, setOptionCategoryArrayConfirmation] = useState([]);
    const [optionCategoryArrayProduct, setOptionCategoryArrayProduct] = useState([]);
    const [optionCategoryArrayDescription, setOptionCategoryArrayDescription] = useState([]);
    const [isManager, setIsManager] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const saveData = () => {
        setPage(1);


        var data = {
            code: tutorial.code,
            name: tutorial.name,
            description: tutorial.description,
            min_price: tutorial.min_price,
            max_price: tutorial.max_price,
            suply_price: tutorial.suply_price,
            color: tutorial.color,
            size: tutorial.size,
            remark: tutorial.remark,
            username: currentUser.username
        };



        ProductsService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    code: response.data.code,
                    name: response.data.name,
                    description: response.data.description,
                    min_price: response.data.min_price,
                    max_price: response.data.max_price,
                    suply_price: response.data.suply_price,
                    color: response.data.color,
                    size: response.data.size,
                    remark: response.data.remark,
                    code: response.data.code,
                    username: response.data.username

                });
                setSubmitted(true);
                //console.log(response.data);

            })
            .catch(e => {
                console.log(e);
            });
    };

    const newData = () => {
        setData(initialDataState);
        setSubmitted(false);
    };

    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [lgShow, setLgShow] = useState(false);

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    }

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    }

    const pageSizes = [200, 500, 1000];

    tutorialsRef.current = tutorials;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };



    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };

    const retrieveData = () => {
        setPage(1);

       getMemberData();

        const params = getRequestParams(searchTitle, page, pageSize);

        ProductsService.getAll(params)
            .then((response) => {
                const { tutorials, totalPages } = response.data;

                setDatas(tutorials);
                setCount(totalPages);

                 
            })
            .catch((e) => {
                console.log(e);
            });

            getSelectOption();
            
    };

    const retrieveDataSearchWithName = () => {
        setPage(1);

       getMemberData();

        const params = getRequestParams(searchTitle, page, pageSize);

        ProductsService.getAllSearchWithName(params)
            .then((response) => {
                const { tutorials, totalPages } = response.data;

                setDatas(tutorials);
                setCount(totalPages);

                // console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

            getSelectOption();
            
    };

    const getSelectOption = () => {
        setPage(1);
        var category = "PRODUCT-STAND-PRESENCE";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {

                setOptionCategoryArray(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

        var category1 = "STOCK-CONFIRMATION";
        SelectOptionService.getAllSelectedOptions(category1)
            .then((response) => {

                setOptionCategoryArrayConfirmation(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

        var category2 = "PRODUCT-NAME";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {

                setOptionCategoryArrayProduct(response.data);
                // setOptionCategoryArrayTransfer(response.data);



            })
            .catch((e) => {
                console.log(e);
            });

            var category2= "DESCRIPTION-TYPE";
            SelectOptionService.getAllSelectedOptions(category2)
                .then((response) => {
    
                    setOptionCategoryArrayDescription(response.data);
                    // setOptionCategoryArrayTransfer(response.data);
    
    
    
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    const getMemberData=()=>{
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
        .then((response) => {
        
            setMemberDetails(response.data);
             
            
        })
        .catch((e) => {
            console.log(e);
        });

    }

    useEffect(retrieveData, [page, pageSize]);

    const refreshList = () => {
        setPage(1);
        retrieveData();
    };



    const handleSearch = () => {
        setPage(1);
        retrieveData();
    };

    const handleSearchWithName = () => {
        setPage(1);
        retrieveDataSearchWithName();
    };

    const openObject = (id) => {

        setMessage("");

        setcurrentData(initialDataState);
        
        ProductsService.get(id)
            .then(response => {
                setcurrentData(response.data);
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

            setLgShow(true);
    };

    const updateData = () => {
        setPage(1);

        if (currentData.code === null || currentData.code === "") {

            setMessage("Product code can't be empty");

        } else if (currentData.name === null || currentData.name === "") {

            setMessage("Product name can't be empty");

        }
        else if (currentData.min_price === null || currentData.min_price === "") {

            setMessage("Minimum price can't be empty");

        } else if (currentData.max_price === null || currentData.max_price === "") {

            setMessage("Maximum can't be empty");

        } else if (currentData.color === null || currentData.color === "") {

            setMessage("Color can't be empty");

        } else if (currentData.size === null || currentData.size === "") {

            setMessage("Size can't be empty");

        }



        else {

            ProductsService.update(currentData.id, currentData)
                .then(response => {

                    setMessage(response.data);
                    refreshList();
                })
                .catch(e => {
                    console.log(e);
                });

        }
    };

    const deleteData = (rowIndex) => {
        setPage(1);
        const id = tutorialsRef.current[rowIndex].id;

        ProductsService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPage(1);
        setPageSize(event.target.value);
       
    };

    const add =()=>{
        props.history.push("/products-new");
    }

    const synchSupplyPrice =()=>{
        ProductsService.synchSupplyPrice()
            .then((response) => {
                
            })
            .catch((e) => {
                console.log(e);
            });
    }


    return (<>
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        
                        <tr>
                            <td><input  type="text" value={searchTitle}
                                        onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> <br/> </td>
                            <td></td>
                        </tr>
                       
                        <tr>
                            <td> <div className="row button-tools-product-div"><button onClick={handleSearch} className="button-tools-product"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search with code</button>   </div> </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td> <div className="row button-tools-product-div"><button onClick={handleSearchWithName} className="button-tools-product"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search with name</button>   </div> </td>
                            <td></td>
                        </tr>
                        
                        </tbody>
                       
                    </table>


                </div>
               

                <div className="col-md-3 tool-box">
                    
                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                            <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                        </tr>
                        <tr>
                            {/* <td className="tg-0lax"><button onClick={synchSupplyPrice} className="button-tools">Synch</button></td> */}
                            <td className="tg-0lax"> </td>
                            <td className="tg-0lax"> </td>
                        </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">
                <div className="row pagination-box">

<div className="col-md-3 pagination-box">{"Items per Page : "}
    <select onChange={handlePageSizeChange} value={pageSize}>
        {pageSizes.map((size) => (
            <option key={size} value={size}>
                {size}
            </option>
        ))}
    </select></div>

    <div className="col-md-3 table-title">  PRODUCTS LIST  </div>

<div className="col-md-4"><Pagination

    count={count}
    page={page}
    siblingCount={1}
    boundaryCount={1}
    variant="outlined"
    shape="rounded"
    onChange={handlePageChange}
/>

</div>



</div>
                    <div className="details">

                        <div className="table-wrap">

                            <table className="table ">
                                <thead className="scroll-thead">
                                    <tr>
                                        <th>ID</th>
                                        <th>CODE</th>
                                        <th>NAME</th>
                                        <th>DESCRIPTION</th>
                                        <th>MIN-PRICE</th>
                                        <th>MAX-PRICE</th>
                                        <th>SUPLY-PRICE</th>
                                        <th>COLOR</th>
                                        <th>SIZE</th>
                                        <th>REMARK</th>
                                        <th>USERNAME</th>
                                        {/* <th>CONFIRMATION</th> */}
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tutorials &&
                                        tutorials.map((tutorial, index) => (

                                            <tr key={tutorial.id}>
                                                <td>{tutorial.id}</td>
                                                <td>{tutorial.code}</td>
                                                <td>{tutorial.name}</td>
                                                <td>{tutorial.description}</td>
                                                <td>{tutorial.min_price}</td>
                                                <td>{tutorial.max_price}</td>
                                                <td>{tutorial.suply_price}</td>
                                                <td>{tutorial.color}</td>
                                                <td>{tutorial.size}</td>
                                                <td><span className={"status " + tutorial.remark}>{tutorial.remark}</span></td>
                                                <td>{tutorial.username}</td>
                                                {/* <td><span className={"status " + tutorial.confirmation}>{tutorial.confirmation}</span></td> */}

                                                <td><div className="row">

                                                    <button
                                                        type="button" className="col-md-1 table-buttons"
                                                        onClick={() => openObject(tutorial.id)}
                                                    > <AiFillFolderOpen/>
                                                    </button>

                                                </div></td>
                                            </tr>

                                        ))}
                                </tbody>

                            </table>

                        </div>

                    </div>

                </div>
            </div>

        </div>
        <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editing 

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">
              <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>CODE</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="code" className="form-control" name="code" placeholder="model number" onChange={handleInputChangeEdit} validations={[required]} value={currentData.code} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name:</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <select className="form-control" id="name" name="name" onChange={handleInputChangeEdit} >
                                                    <option>{currentData.name}</option>
                                                    {optionCategoryArrayProduct.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Description</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                            <select className="form-control" id="description" name="description" onChange={handleInputChangeEdit} >
                                                    <option key={currentData.description}>{currentData.description}</option>
                                                    {optionCategoryArrayDescription.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Min price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="min-price" className="form-control" name="min_price" placeholder="Minimum price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.min_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Max price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="max_price" className="form-control" name="max_price" placeholder="Maximum price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.max_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Suply price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="suply_price" className="form-control" name="suply_price" placeholder="Maximum price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.suply_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>color</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={currentData.color} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>size</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input disabled type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={currentData.size} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="remark" className="form-control" name="remark" placeholder="Remark" onChange={handleInputChangeEdit} value={currentData.remark} />
                                            </div>

                                          
                                            {/* {isManager ? (<><div className="col-md-4">
                                                <label>Confirmation</label>
                                            </div>
                                                <div className="col-md-8 form-group">
                                                    <select className="form-control" id="confirmation" name="confirmation" onChange={handleInputChangeEdit} >
                                                        <option key={currentData.confirmation}>{currentData.confirmation}</option>
                                                        {optionCategoryArrayConfirmation.map((optionCategory) => (
                                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                                        ))}

                                                    </select>
                                                </div></>) : (<> <div className="col-md-4">
                                                    <label>Confirmation</label>
                                                </div>
                                                    <div className="col-md-8 form-group">
                                                        <select disabled className="form-control" id="confirmation" name="confirmation" onChange={handleInputChangeEdit} >
                                                            <option key={currentData.confirmation}>{currentData.confirmation}</option>
                                                            {optionCategoryArrayConfirmation.map((optionCategory) => (
                                                                <option key={optionCategory.value}>{optionCategory.value}</option>
                                                            ))}

                                                        </select>
                                                    </div> </>)} */}




                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>
                                            <label className="col-md-8 danger-message">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <div className="col-md-8 form-group">
                                                <button onClick={updateData}
                                                    type="button" className="button-form"> Save</button>


                                                <button onClick={()=>setLgShow(false)}
                                                    type="button" className="button-form">Close</button>

                                            </div>



                                        </div>
                                    </div>
                                </form>

              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>
        </>
    );
});

export default ProductList;
