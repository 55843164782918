import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {

     return http.get("/members", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/members/${id}`,{ headers: authHeader() });
};

const getByAccount = (account) => {
  return http.get(`/membersbyrole/${account}`,{ headers: authHeader() });
};

const getMemberdetails = (username) => {
    return http.get(`/memberdetails/${username}`,{ headers: authHeader() });
};

const getUserRoles = () => {
  return http.get(`/userroles`,{ headers: authHeader() });
};

const getMemberdetailsRoles = (username) => {
  return http.get(`/memberoles/${username}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/members", data,{ headers: authHeader() });
};

const createRole = (data) => {
   
  return http.post("/userroles", data,{ headers: authHeader() });
};

const removeRole = (data) => {
   
  return http.post("/userrolesremove", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/members/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/members/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/customers`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const getMemberContributionList = (params) => {

  return http.get("/contributionlist", { params,headers: authHeader()  });

  
};

const getMemberContributionListTotal = (data) => {

  return http.post(`/contributionlistTotal`,data, { headers: authHeader()  });

  
};

const getMemberContribution = (id) => {
  return http.get(`/contributionlist/${id}`,{ headers: authHeader() });
};

const updateMemberContribution = (id, data) => {
  return http.put(`/contributionlist/${id}`, data,{ headers: authHeader() });
};

const removeFromList = (id) => {
  return http.delete(`/contributionlist/${id}`,{ headers: authHeader()});
};

const getMemberBulkContribution = (data) => {
  return http.post(`/bulkcontribution`,data,{ headers: authHeader() });
};

const changePassword = (data) => {

  return http.post("/changepassword", data,{ headers: authHeader() });

  
};

const changePasswordAdmin = (data) => {

  return http.post("/changepasswordadmin", data,{ headers: authHeader() });

  
};

const MemberService = {
  getAll,
  getByAccount,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  getMemberContributionList,
  getMemberContribution,
  updateMemberContribution,
  removeFromList,
  getMemberContributionListTotal,
  getMemberBulkContribution,
  getMemberdetails,
  getMemberdetailsRoles,
  getUserRoles,
  createRole,
  removeRole,
  changePassword,
  changePasswordAdmin
};

export default MemberService;
