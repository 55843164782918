import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (data) => {
  return http.post("/expenselist",data, { headers: authHeader() });
};

const get = (id) => {
  return http.get(`/expense/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/newexpense", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/product/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/tutorials`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const ExpensesService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
};

export default ExpensesService;
