import axios from "axios";

export default axios.create({

  baseURL: "https://peace.anandaapp.com:9114/api",
//  baseURL: "http://localhost:9114/api",

  headers: {
    "Content-type": "application/json"
  }
  
});
