import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { GiPayMoney, GiCash } from 'react-icons/gi';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import OrderService from "../../services/order.service";
import PaymentsService from "../../services/payments.service";
import MemberService from "../../services/members.service";
import NumberFormat from 'react-number-format';

var accessibleTxt = "";



const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const PaymentForm = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_CASHIER")) {
    return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    orderId: "",
    customer: "",
    phone: "",
    orderTime: "",
    saller: "",
    amount: "",
    total_payed: "",
    balance: "",
    cash: "",
    cheque: "",
    transfer: "",
    visa: "",
    total_received: "",
    payment_time: "",
    cashier: "",

    cash: "",
    momo: "",
    momo_charges:"",
    visa: "",
    cheque: "",
    transfer: "",


  };



  const [tutorial, setData] = useState(initialDataState);
  const [isOrderDataEmpty, setIsOrderDataEmpty] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [isSaleSummaryDuplicated, setIsSaleSummaryDuplicated] = useState(false);
  const [isSaleSummaryExist, setIsSaleSummaryExist] = useState(true);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [orderDetails, setOrderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [messageOnSave, setMessageOnSave] = useState("");
  const [orderTotal, setOrderTotal] = useState(0);
  const [balance, setbalance] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isSuccessfull, setIsSuccessfull] = useState(false);
  const [isPaymentSavedAnalysis, setIsPaymentSavedAnalysis] = useState(false);

  const [cash, setCash] = useState('');
  const [momo, setMomo] = useState('');
  const [momo_charges, setMomoCharges] = useState('');
  const [visa, setVisa] = useState('');
  const [cheque, setCheque] = useState('');
  const [transfer, setTransfer] = useState('');
  const [totalReceived, setTotalReceived] = useState(0);
  const [memberdetails, setMemberDetails] = useState([]);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleChangeCash = (event) => {
    setCash(event.target.value);

  }

  const handleChangeMomo = (event) => {
    setMomo(event.target.value);

  }

  const handleChangeMomoCharges = (event) => {
    setMomoCharges(event.target.value);

  }

  const handleChangeVisa = (event) => {
    setVisa(event.target.value);

  }

  const handleChangeCheque = (event) => {
    setCheque(event.target.value);

  }

  const handleChangeTransfer = (event) => {
    setTransfer(event.target.value);

  }


  const calculateTotal = () => {
    setPage(1);
    var sum = cash + momo + momo_charges + visa + cheque + transfer;
    
    setTotalReceived(sum);
    accessibleTxt = "readOnly";
  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };


  const saveData = () => {
    setPage(1);
    var data = {

      id: null,
      orderno: orderDetails.orderid,
      customer: orderDetails.customer,
      phone: orderDetails.phone,
      orderTime: orderDetails.orderTime,
      saller: orderDetails.saller,
      amount: orderDetails.amount,
      amount_payed: orderDetails.amount_payed,
      balance: balance,
      cash: cash,
      momo: momo,
      momo_charges: momo_charges,
      cheque: cheque,
      transfer: transfer,
      visa: visa,
      totalReceived: totalReceived,
      cashier: currentUser.username,
      location:memberdetails.account



    };

    if (data.customer == null || data.customer == "") {
      setMessage("Customer name missing!");
    } else if (data.phone == null || data.phone == "") {
      setMessage("Customer phone number missing!");
    }
    else if (cash == null || cash == "") {
      setMessage("Received cash is missing!");
    }
    else if (momo == null || momo == "") {
      setMessage("Received MoMo is missing!");
    }
    // else if (momo_charges == null || momo_charges == "") {
    //   setMessage("Received MoMo_charges is missing!");
    // }
    else if (visa == null || visa == "") {
      setMessage("Received visa is missing!");
    }
    else if (cheque == null || cheque == "") {
      setMessage("Received cash is missing!");
    }
    else if (transfer == null || transfer == "") {
      setMessage("Received cash is missing!");
    }
    // else if (totalReceived == null || totalReceived == "") {
    //   setMessage("Received amount is missing!");
    // } else if (totalReceived == 0 || totalReceived < 1) {
    //   setMessage("Invalid receiced amount!");
    // } 
    // else if (totalReceived > balance) {
    //   console.log("====216===received:"+totalReceived+" balance:"+balance);
    //   setMessage("Received amount is more than balance!");
    // }
    else if(isSaleSummaryDuplicated === true){
      setMessage("This order is duplicated!");
    }
    else if(isSaleSummaryExist===false){
      setMessage("This pending order was not found!");
    }
    else {

      PaymentsService.create(data)
        .then(response => {
          setData({
            id: null,
            orderno: response.data.orderId,
            customer: response.data.customer,
            phone: response.data.phone,
            orderTime: response.data.orderTime,
            saller: response.data.saller,
            amount: response.data.amount,
            totalPayed: response.data.totalPayed,
            balance: response.data,
            cash: response.data,
            momo: response.data,
            momoCharges:response.data.totalCharges,
            cheque: response.data,
            transfer: response.data,
            visa: response.data,
            totalReceived: response.data,
            cashier: response.data.username,
            

          });
          setSubmitted(true);
          setMessage(response.data);

          if(response.data === "Payment saved successfully."){
            setIsSuccessfull(true);
          }else{
            setIsSuccessfull(false);
          }
          //console.log(response.data);
          refreshList();
          retrieveOrderedData();
          setIsClicked(true);
        })
        .catch(e => {
          console.log(e);
        });

    }


  };

  const newData = () => {
    setData(initialDataState);

    props.history.push("/sales-pending-orders");
  };

  const orders = () => {

    props.history.push("/sales-pending-order");

  };

  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };







  const retrieveOrderedData = () => {
    setPage(1);

    const queryParams = new URLSearchParams(window.location.search);
    const parm = queryParams.get('data');

    OrderService.getOrderByNoTemp(parm)
      .then((response) => {

        const { tutorials, totalPages,cash,momo,momoCharges,visa,orderSummaryCount} = response.data;

        setOrderedData(tutorials);
        setIsOrderDataEmpty(false);

        if(orderSummaryCount > 1){
          setIsSaleSummaryDuplicated(true);
        }else if(orderSummaryCount < 1){
          setIsSaleSummaryExist(false);
        }


      })
      .catch((e) => {
        console.log(e);
      });

    //get current order total
    OrderService.getOrderByNoTotal(parm)
      .then((response) => {


        setOrderTotal(response.data);


        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    //get order details
    OrderService.getSavedOrder(parm)
      .then((response) => {


        setOrderDetails(response.data);

        var total = response.data.amount - response.data.amount_payed;
        setbalance(total);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

  };

  const retrieveData = () => {

    setPage(1);

    retrieveOrderedData();
    getMemberData();

    

  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }


  useEffect(retrieveData, [page, pageSize]);
  //useEffect(retrieveData, calculateTotal, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };






  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const confirmnewSale = () => {

    if (orderTotal == 0 || orderTotal == null) {

      setMessageOnSave("You can't save empty order!");

    } else {

      OrderService.saveNewSale().then(response => {

        if (response.data == "Saved") {
          props.history.push(`/sales-order-success/?id=${orderNo}`);
        }

      })
        .catch(e => {
          console.log(e);
        });

    }

  }

  const payment = () => {
    props.history.push(`/payments`);

  }

  const receipt = () => {
    props.history.push(`/sales-receipt/?id=${orderDetails.orderid}`);
     
  }

  const reset=()=>{
    window.location.reload();
  }

  const [smShow, setSmShow] = useState(false);

  return (<>
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box-payment">
          <form>

            <div className="row">

              <div className="col-md-4">
                <label>Tracking No</label>
              </div>
              <div className="col-md-8 form-group">
                <label readOnly="readonly" type="text" id="orderid" className="form-control" placeholder="orderid" > {orderDetails.orderid}</label>
              </div>

              <div className="col-md-4">
                <label>Customer</label>
              </div>
              <div className="col-md-8 form-group">
                <label readOnly="readonly" type="text" id="customer" className="form-control" placeholder="Customer"> {orderDetails.customer} </label>
              </div>

              <div className="col-md-4">
                <label>Phone</label>
              </div>
              <div className="col-md-8 form-group">
                <label readOnly="readonly" type="text" id="customer" className="form-control" placeholder="Phone" >{orderDetails.phone}</label>
              </div>



              <div className="col-md-4">
                <label>Order Time</label>
              </div>
              <div className="col-md-8 form-group">
                <label readOnly="readonly" type="text" id="orderTime" className="form-control" placeholder="OrderTime" >{orderDetails.orderTime}</label>
              </div>

              <div className="col-md-4">
                <label>Saller</label>
              </div>
              <div className="col-md-8 form-group">
                <label readOnly="readonly" type="text" id="price" className="form-control" placeholder="Saller" >{orderDetails.saller}</label>
              </div>

              <div className="col-md-4">
                <label>Amount</label>
              </div>
              <div className="col-md-8 form-group">
                <NumberFormat thousandSeparator={true} prefix={'Frw '} readOnly="readonly" type="text" id="totalshare" className="form-control" value={orderDetails.amount} />
              </div>

              <br></br>
              <br></br>

              <div className="col-md-4">
                <label> </label>
              </div>

              <label className="col-md-8 danger-message">{message}</label>
              


            </div>

          </form>
          <center>
            <span className="title-img"><FaIcons.FaCoins />  </span>
          </center>

        </div>
        <div className="col-md-3 tool-box-payment">
          <form>


            <div className="form-body">
              <div className="row">


                <div className="col-md-4">
                  <label>Payed</label>
                </div>
                <div className="col-md-8 form-group">
                  <NumberFormat thousandSeparator={true} prefix={'Frw '} readOnly="readonly" type="text" id="totalshare" className="form-control" value={orderDetails.amount_payed} />

                </div>

                <div className="col-md-4">
                  <label>Balance</label>
                </div>
                <div className="col-md-8 form-group">
                  <NumberFormat thousandSeparator={true} prefix={'Frw '} readOnly="readonly" type="text" id="totalshare" className="form-control" value={balance} />

                </div>



                <div className="col-md-4">
                  <label>Cash</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="orderTime" className="form-control" name="number" placeholder="Cash" onChange={handleChangeCash}  />
                </div>

                <div className="col-md-4">
                  <label>MoMo</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="momo" className="form-control" name="momo" placeholder="MoMo" onChange={handleChangeMomo} />
                </div>

                {/* <div className="col-md-4">
                  <label>MoMoCharges</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="momoCharges" className="form-control" name="momoCharges" placeholder="Charges" onChange={handleChangeMomoCharges} />
                </div> */}

                <div className="col-md-4">
                  <label>Visa</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="visa" className="form-control" name="visa" placeholder="Visa" onChange={handleChangeVisa}  />
                </div>

                <div className="col-md-4">
                  <label>Cheque</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="cheque" className="form-control" name="cheque" placeholder="Cheque" onChange={handleChangeCheque}  />
                </div>

                <div className="col-md-4">
                  <label>Transfer</label>
                </div>
                <div className="col-md-8 form-group">
                  <input type="number" id="transfer" className="form-control" name="transfer" placeholder="Transfer" onChange={handleChangeTransfer}  />
                </div>

               
                <div className="col-md-4">
                  <label> </label>
                </div>


              </div>
            </div>
          </form>


        </div>

        <div className="col-md-3 tool-box-payment-sm">

          <center>
            <div className="title"> PAYMENT </div>
          </center>
          <hr />


          <table className="table-tools">
            <tbody>
            
            <tr>
              <td className="tg-0lax">{isClicked ? ("") : (<button className="button-tools-red" onClick={saveData}
                type="button"><ImFloppyDisk /> Save</button>)} </td>
                <td className="tg-0lax"><button onClick={reset} className="button-tools">Reset</button></td>
                              
              <td className="tg-0lax"><button onClick={payment} className="button-tools">Payments</button></td>
            </tr>
            <tr>
            <td className="tg-0lax">{isSuccessfull ? (<button className="button-tools-green" onClick={receipt}
                type="button"> Print</button>):("")} </td>
              <td className="tg-0lax"><button onClick={orders} className="button-tools">Orders</button></td>
            </tr>
            </tbody>

          </table>

          <hr />

          <center>
            <span className="title-img"><GiPayMoney /></span>
          </center>



        </div>


        {/* end card box */}



        <div className="dataRow">

          <div className="details">

            <table className="table">
              <thead>


                <tr>
                  <th>Product</th>
                  <th>Code</th>
                  <th>pid</th>
                  <th>size</th>
                  <th>color</th>
                  <th>price</th>
                  <th>Qty</th>
                  <th>Total</th>
                  <th>Authorization</th>
                  <th>Authorized by</th>

                </tr>

              </thead>


              <tbody>
                {orderedData &&
                  orderedData.map((tutorial, index) => (

                    <tr key={tutorial.id}>
                      <td>{tutorial.product}</td>
                      <td>{tutorial.code}</td>
                      <td>{tutorial.pid}</td>
                      <td>{tutorial.color}</td>
                      <td>{tutorial.size}</td>
                      <td>{tutorial.price}</td>
                      <td>{tutorial.qty}</td>
                      <td>{tutorial.total}</td>
                      <td><span className={"status " + tutorial.authorization_status}>{tutorial.authorization_status}</span></td>
                      <td>{tutorial.authorization_by}</td>
                    </tr>

                  ))}
              </tbody>

            </table>


          </div>

        </div>
      </div>

    </div>
    <Modal
    size="sm"
    backdrop="static"
    show={smShow}
    onHide={() => setSmShow(false)}
    aria-labelledby="example-modal-sizes-title-lg"

  >
    <Modal.Header>
      <Modal.Title id="example-modal-sizes-title-lg">
      <div className="row">


<div className="col-md-2"> 
<FaIcons.FaShoppingCart/> 
</div>


</div> 
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-bg">

      <div className="dataRow">
        <div className="details-dialog-sm">

        <table className="table table2">
            <tbody>
              <tr>
                <td>
                Order: 
                </td>
              </tr>
              <tr>
                <td>
                Items: 
                </td>
              </tr>
              <tr>
                <td>
                Total: 
                </td>
              </tr>
              <tr>
                <td>
                Saller: 
                </td>
              </tr>
              <tr>
                <td>
                Customer: 
                </td>
              </tr>
            </tbody>
          </table>
          
          <center>
                       
            <div className="row"> <button onClick={orders} className="button-form">Close</button> </div>
             {isPaymentSavedAnalysis ?(""):(<div className="row"> <button onClick={saveData} className="button-tools-red">Save</button> </div>)} 

          </center>

        </div>
      </div>


    </Modal.Body>
  </Modal>
</>
  );
});

export default PaymentForm;
