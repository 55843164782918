import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { ImCancelCircle } from "react-icons/im";
import { FcServices, FcBusinesswoman } from 'react-icons/fc';
import { GrFormSearch } from 'react-icons/gr';

import { AiFillFolderOpen } from 'react-icons/ai';
import DatePicker from 'react-datepicker';

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import OrderService from "../../services/order.service";
import StockkeeperService from "../../services/stockkeeper.service";
import SelectOptionService from "../../services/select-options.service";
import ProductsService from "../../services/products.service";
import MemberService from "../../services/members.service";
import AccessControlOtpService from "../../services/access-control-otp.service";
import { format } from 'date-fns';



const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};



const StockkeeperStockRequestForm = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_STOCK")) {
    return <Redirect to="/unauthorized" />;

  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);


  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",
    size: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    color: "",
    stock_type: "",
    stockEntryId: "",
    returned: "",
    confirmation: "",
    location: "",
    qty_requested: "",
    stand_supply_request_date: "",
    stand_supply_request_officer: "",
    business_operation_manager_name: "",
    business_operation_manager_approval: "",
    business_operation_manager_approval_date: "",
    branch_manager_name: "",
    branch_manager_approval: "",
    branch_manager_approval_date: "",
    stockkeeper_name: "",
    stockkeeper_approval: "",
    stockkeeper_approval_date: "",
    stockkeeper_approval_qty: "",
    stand_receiption_confirmation: "",
    stand_receiption_confirmation_date: "",
    request_Status: "",

  };



  const [tutorial, setData] = useState(initialDataState);
  const [isOrderDataEmpty, setIsOrderDataEmpty] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [sallerName, setSallerName] = useState("");
  const [messageOnSave, setMessageOnSave] = useState("");
  //ANALYSIS
  const [orderNoAnalysis, setOrderNoAnalysis] = useState("");
  const [noOfItemAnalysis, setNoOfItemAnalysis] = useState("");
  const [totalAnalysis, setTotalAnalysis] = useState("");

  const [orderNoAnalysis2, setOrderNoAnalysis2] = useState("");
  const [noOfItemAnalysis2, setNoOfItemAnalysis2] = useState("");
  const [totalAnalysis2, setTotalAnalysis2] = useState("");
  const [sallerAnalysis2, setSallerAnalysis2] = useState("");
  const [customerAnalysis2, setCustomerAnalysis2] = useState("");
  const [isOrderSavedAnalysis2, setIsOrderSavedAnalysis2] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [priceAuthorizationCode, setPriceAuthorizationCode] = useState("");


  const [orderTotal, setOrderTotal] = useState(0);
  const [isIdGenerated, setIsIdGenerated] = useState(false);
  const [isOrderVerfied, setIsOrderVerfied] = useState(false);
  const [avairableQty, setAvairableQty] = useState(0);
  const [isAvairableQtyChecked, setIsAvairableQtyChecked] = useState(false);

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [memberdetails, setMemberDetails] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeAuthorization = (e) => {

    const code = e.target.value;
    setPriceAuthorizationCode(code);
    console.log("======147====" + code);

  };

  const authorizePrice = () => {

    AccessControlOtpService.checkIfAuthorizationCodeExist(priceAuthorizationCode)
      .then((response) => {

        if (response.data === 1) {
          setIsAuthorized(true);
        }


      })
      .catch((e) => {
        console.log(e);
      });

  }


  const saveData = () => {

    setPage(1);

    setMessage("");

   

    
      setMessage("Qty is missing.");

 

      var data = {
       
        id: null,
        stockEntryId: orderNo,
        date1: tutorial.orderTime,
        product: currentData.name,
        code: currentData.code,
        pid: currentData.id,
        qty: 0,
        size: currentData.size,
        color: currentData.color,
        saller: sallerName,
  
        //stand: optionCategorySelectedValue,
        stand: memberdetails.account,
        username: currentUser.username,
        location: memberdetails.account,
  
        qty_requested: tutorial.qty,
        stand_supply_request_date: "",
        stand_supply_request_officer: currentUser.username,
        business_operation_manager_name: "",
        business_operation_manager_approval: "",
        business_operation_manager_approval_date: "",
        branch_manager_name: "",
        branch_manager_approval: "",
        branch_manager_approval_date: "",
        stockkeeper_name: "",
        stockkeeper_approval: "",
        stockkeeper_approval_date: "",
        stockkeeper_approval_qty: "",
        stand_receiption_confirmation: "",
        stand_receiption_confirmation_date: "",
        request_Status: "TEMPORARY",
  
  
      };
  
      if(data.qty_requested < 1){

        setMessage("Requested qty missing.");

      }else{

        StockkeeperService.create(data)
        .then(response => {


          //setMessage(response.data);
          //console.log(response.data);
          //refreshList();
          newData();
          retrieveOrderedRequestTemp();
        })
        .catch(e => {
          console.log(e);
        });


      setMessage("");
      setIsAvairableQtyChecked(false);
      setAvairableQty(0);
      setIsAuthorized(false);


      }
  
        
    

    


  

  };

  const newData = () => {
    setPage(1);
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const reset = () => {

    props.history.push("/sales-order-customer");

  };

  const [tutorials, setDatas] = useState([]);
  const [orderedData, setOrderedData] = useState([]);
  const [orderNo, setOrderNo] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };


  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveOrderedRequestTemp = () => {

    setPage(1);
    StockkeeperService.getRequestByNoTemp(orderNo)
      .then((response) => {

        const { tutorials } = response.data;

        setOrderedData(tutorials);
        setIsOrderDataEmpty(false);


      })
      .catch((e) => {
        console.log(e);
      });



  };

  const generateOrderNo = () => {
    setPage(1);
    //get order no
    OrderService.getOrderNo(currentUser.username)
      .then((response) => {


        setOrderNo(response.data);
        setIsIdGenerated(true);



      })
      .catch((e) => {
        console.log(e);
      });

    //end get order no

  }

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

    ProductsService.getAll(params)
      .then((response) => {
        const { tutorials } = response.data;

        setDatas(tutorials);


        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getMemberData();




  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);
    var category = "stock-name";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveOrderedRequestTemp();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setPage(1);
    setIsAvairableQtyChecked(false);
    setAvairableQty(0);
    setMessage("");

    setcurrentData(initialDataState);

    ProductsService.get(id)
      .then(response => {
        setcurrentData(response.data);

        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    // props.history.push("/stock/" + id);
  };

  const updateData = () => {
    OrderService.update(currentData.id, currentData)
      .then(response => {
        //console.log(response.data);
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });

  };

  const deleteData = (id) => {
    setPage(1);

    StockkeeperService.remove(id)
      .then((response) => {
        /// props.history.push("/product");

        retrieveOrderedRequestTemp();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };





  const payment = (data) => {
    props.history.push("/payment-historic?data=" + data);
  }

  const pendingRequest = () => {
    props.history.push("/stockkeeper-pending-request");
  }

  const depositOrder = (data) => {
    props.history.push("/sales-pending-order-deposit");
  }

  const confirmNewRequest = () => {
    setPage(1);



    StockkeeperService.saveNewRequest(orderNo).then(response => {

      if (response.data > 0) {

        setSmShow(true);

      }

      if (response.data < 1) {
        setMessage("This orde was not saved! Click on save.");

      }

    })
      .catch(e => {
        console.log(e);
      });



  }


  const checkQty = () => {
    setPage(1);

    var data = {
      text1: currentData.id,
      text2: "GAKINJIRO"
    }


    ProductsService.getAvairableProductQty(data)
      .then(response => {

        setIsAvairableQtyChecked(true);

        setAvairableQty(response.data);
        setMessage("Qty in MAIN STOCK:" + response.data);

      })
      .catch(e => {
        console.log(e);
        setMessage("We don't have this product");
      });


  }

  const analyse = () => {

    setMessage("");

    setIsOrderVerfied(true);

  }

  const closeDialog = () => {

    window.location.reload();

  }


  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">
            <hr />
            <div className="page-tile">REQUESTING ITEMS IN STOCK</div>
            <hr />
            <div className="page-tile">REF:{orderNo}</div>




          </div>


          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Request</button></td>
                  {isOrderVerfied ? (<td></td>) : (<td className="tg-0lax">{isIdGenerated ? (<Button onClick={() => setLgShow(true)} className="button-tools-red">Add</Button>) : (<button className="button-tools-red" onClick={generateOrderNo}> Items </button>)}  </td>)}

                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={pendingRequest} className="button-tools">Concel</button></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>

          STOCKKEEPER REQUEST
          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div id="wrapper">

                {isOrderDataEmpty ? ("") : (<div id="c1">



                  {isOrderVerfied ? (<button className="button-tools-red" onClick={confirmNewRequest}>Save</button>) : (<button className="button-form" onClick={analyse}>Check</button>)}

                </div>)}

                <div id="c2">  </div>
                <div className="danger-message">{message}</div>
              </div>


            </div>
            <div className="details">


              <div className="table-wrap">

                <table className="table">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Product</th>
                      <th>Stand</th>
                      <th>Code</th>
                      <th>pid</th>
                      <th>size</th>
                      <th>color</th>
                      <th>Qty</th>
                      <th>Tools</th>
                    </tr>

                  </thead>


                  <tbody>
                    {orderedData &&
                      orderedData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.stand}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.pid}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.size}</td>
                          <td>{tutorial.qty_requested}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => deleteData(tutorial.id)}
                            >
                              <ImCancelCircle />
                            </button>

                          </div></td>
                        </tr>
                      ))}
                  </tbody>




                </table>


              </div>

            </div>

          </div>
        </div>


      </div>


      <Modal
        size="xl"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">

              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Product code"
                  className="form-control form-input-text"
                  value={searchTitle}
                  onChange={onChangeSearchTitle}

                />


              </div>
              <div className="col-md-4">
                <button onClick={findByTitle} className="button-form-sm"><span><GrFormSearch />Search</span></button>


              </div>
              <div className="col-md-2">
                <FaIcons.FaShoppingCart />
              </div>
              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow-with-two-column-modal">
            <div className="details">
              <div className="table-wrap">

                <table className="table">
                  <thead>
                    <tr>

                      <th>PRODUCT</th>
                      <th>CODE</th>
                      <th>PID</th>
                      <th>COLOR</th>
                      <th>SIZE</th>
                      <th>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.name}</td>
                          <td>{tutorial.code}</td>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.color}</td>
                          <td>{tutorial.size}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <AiFillFolderOpen />
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>
              </div>
            </div>
            <div className="details">

              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">



                    <div className="col-md-4">
                      <label>Product</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="product" className="form-control" name="product" placeholder="Item name" onChange={handleInputChange} value={currentData.name} />
                    </div>

                    <div className="col-md-4">
                      <label>CODE</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="code" className="form-control" name="code" placeholder="Code" onChange={handleInputChange} value={currentData.code} />
                    </div>

                    <div className="col-md-4">
                      <label>size</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChange} value={currentData.size} />
                    </div>

                    <div className="col-md-4">
                      <label>Color</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input readOnly="readonly" type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChange} value={currentData.color} />
                    </div>


                    <div className="col-md-4">
                      <label>Stock</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option>{memberdetails.account}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>


                    <div className="col-md-4">
                      <label>QTY</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="number" id="qty" className="form-control" name="qty" placeholder="QTY" onChange={handleInputChange} value={tutorial.qty} />
                    </div>



                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">

                    </div>

                    <div className="col-md-8 form-group">
                      <button onClick={saveData}
                        type="button" className="button-tools-red">  REQUEST</button>

                    </div>


                  </div>
                </div>
              </form>

            </div>

          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">


              <div className="col-md-2">
                <FaIcons.FaShoppingCart />
              </div>


            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">
            <div className="details-dialog-sm">

              <table className="table table2">
                <tbody>
                  <tr>
                    <td>
                      REF: {orderNo}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Successfully saved.
                    </td>
                  </tr>

                </tbody>
              </table>

              <center>

                <div className="row"> <button onClick={pendingRequest} className="button-form">Close</button> </div>
                <div className="row"> <button onClick={confirmNewRequest} className="button-tools-red">Save</button> </div>
                {/* {isOrderSavedAnalysis2 ?(""):(<div className="row"> <button onClick={confirmnewSale} className="button-tools-red">Save</button> </div>)}  */}

              </center>

            </div>
          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Warning

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">



                <div className="row">
                  <label className="danger-message"> This product can't be sold on this price! </label>
                </div>



                <div className="row">
                  <button onClick={() => setLgShow2(false)}
                    type="button" className="button-tools-red"> Close</button>

                </div>

              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>


      <Modal
        size="sm"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Authorization code

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <div className="row">
                  <input type="text" id="priceAuthorizationCode" className="form-control" name="priceAuthorizationCode" placeholder="Authorization code" onChange={handleInputChangeAuthorization} validations={[required]} />
                </div>

                <div className="row">
                  <label className="danger-message"> {message} </label>
                </div>

                <div className="row">
                  <button
                    type="button" onClick={authorizePrice} className="button-tools-red"> Authorize</button>
                </div>

                <div className="row">
                  <button onClick={() => setLgShow3(false)}
                    type="button" className="button-tools-red"> Close</button>

                </div>

              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default StockkeeperStockRequestForm;
