import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { AiFillFolderOpen,AiOutlinePhone } from 'react-icons/ai';
import { FcServices } from 'react-icons/fc';
import { ImFloppyDisk, ImCancelCircle, ImPlus, ImProfile } from "react-icons/im";

import { withRouter } from 'react-router';
import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import SelectOptionService from "../../services/select-options.service";
import MemberService from "../../services/members.service";





const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const CustomerSearch = withRouter((props) => {


  if (JSON.parse(localStorage.getItem("user")) == null) {

    return <Redirect to="/login" />;

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    return <Redirect to="/login" />;

  }

  if (!currentUser.roles.includes("ROLE_USER")) {
    return <Redirect to="/unauthorized" />;

}

  const initialDataState = {
    id: null,
    fullname: "",
    phone: "",
    contact: "",
    phone: "",
    email: "",
    other: "",
    remark: "",
    username: "",

    published: false
  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [memberdetails, setMemberDetails] = useState([]);
  const [searchTitleType, setSearchTitleType] = useState("Name");

  const [isValidSaller, setIsValidSaller] = useState("false");
  const [sallerAccessCode, setSallerAccessCode] = useState("");
  const [savedAccessCode, setSavedAccessCode] = useState("");

  const handleChangeoptionCategories = (event) => {

    setMessage("");

    if (customerName === null || customerName === "") {
      
      setMessage("Customer name is missing!");

    } else if (customerPhone === null || customerPhone === "") {
      
      setMessage("Customer phone is missing!");

    }else{
      setOptionCategorySelectedValue(event.target.value);

      console.log(event.target.value);

      getSelectedSallerValidCode(event.target.value);
  
      setLgShow(true);

    }
    

  }

  const getSelectedSallerValidCode=(saller)=>{

    SelectOptionService.getSallerDetails(saller)
    .then((response) => {
      

      setSavedAccessCode(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  }

  const handleInputChangePhone = event => {
    const phone = event.target.value;
    setCustomerPhone(phone);

  };

  const handleInputChangeName = event => {
    const name = event.target.value;

    setCustomerName(name);
  };

  const handleInputChangeSallerAccessCode=(e)=>{
    setSallerAccessCode(e.target.value);
}



  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };



  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");


  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 300, 500];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };



  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

    CustomersService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getMemberData();

      


  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);

        getSelectOption(response.data.account);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const findByTitlePhone = () => {
    setSearchTitleType("Phone");
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setPage(1);

    setcurrentData(initialDataState);

    CustomersService.get(id)
      .then(response => {
        setcurrentData(response.data);
        //  console.log(response.data);
        setCustomerName(response.data.fullname);
        setCustomerPhone(response.data.phone);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getSelectOption = (location) => {
    setPage(1);
    var category = "SALLER";
    // SelectOptionService.getAllSelectedOptions(category)
 
    SelectOptionService.getSelectionSallersByBranch(location)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });
  };

  const nextPage = () => {
    setPage(1);

    //console.log(sallerAccessCode+" "+savedAccessCode.saller_access_code);
    
    if (sallerAccessCode === null || sallerAccessCode === "") {
      setMessage("Access code missing.");

    }else if(sallerAccessCode === savedAccessCode.saller_access_code){
      props.history.push(`/sales-order-new/?name=${customerName}&phone=${customerPhone}&saller=${optionCategorySelectedValue}`);
    }
    
    else {
      setMessage("Incorrect code.");
    }


  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };



  const stockOut = () => {
    setPage(1);
    props.history.push("/stock-out");
  }

  const add = () => {
    setPage(1);
    props.history.push("/customers-new");
  }

  const list = () => {
    props.history.push("/customers");
  }

  const [lgShow, setLgShow] = useState(false);


  return (
    <>
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table-tools">
            <tbody>
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>Branch:</td>
              <td>{memberdetails.account}</td>
            </tr>
            <tr>
              <td colSpan="2"> <input onChange={onChangeSearchTitle} id="search" type="text" name="search" placeholder="Customer" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools-sm"> <span className="table-tools-img-search"><BsPersonBoundingBox /></span></button><button onClick={findByTitlePhone} className="button-tools-sm"> <span className="table-tools-img-search"><AiOutlinePhone/></span></button></td>
            </tr>
            </tbody>
          </table>


        </div>
       

        <div className="col-md-3 tool-box">

          
        </div>


        {/* end card box */}



        <div className="dataRow-with-two-column ">


          <div className="details">

            <div className="table-wrap">
              <table>
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>FULL NAME</th>
                    <th>PHONE</th>
                    <th>CONTACT</th>
                    <th>EMAIL</th>
                    <th>OTHER</th>

                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr key={tutorial.id}>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.fullname}</td>
                        <td>{tutorial.phone}</td>
                        <td>{tutorial.contact}</td>
                        <td>{tutorial.email}</td>
                        <td>{tutorial.other}</td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiFillFolderOpen />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>

              </table>
            </div>


          </div>

          <div className="details">
            <form className="form form-horizontal ">
              <div className="form-body">
                <div className="row">


                  <div className="col-md-4">
                    <label>Customer</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input type="text" id="fullname" className="form-control" name="fullname" placeholder="Customer name" onChange={handleInputChangeName} value={customerName} />
                  </div>

                  <div className="col-md-4">
                    <label>Phone</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <input type="text" id="comment" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangePhone} value={customerPhone} />
                  </div>

                  <div className="col-md-4">
                    <label>Saller</label>
                  </div>
                  <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>

                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <label className="col-md-8 danger-message">{message}</label>

                  <div className="col-md-4">
                    <label> </label>
                  </div>

                  <div className="col-md-8 form-group">

                  {isValidSaller ?(""):(<><button onClick={()=>setLgShow(true)}
                      type="button" className="button-form">Next</button></>)}
                   


                  </div>




                </div>
              </div>
            </form>

          </div>

        </div>
      </div>

    </div>

    <Modal
        size="sm"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
           Saller Access Code

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>
            <label className="col-md-8 danger-message">{message}</label>
              <hr />
              <div className="col-md-8 form-group">
                      <input type="password" autoComplete="off" id="sallerAccessCode" className="form-control" name="sallerAccessCode" placeholder="Access Code" onChange={handleInputChangeSallerAccessCode} />
                    </div>
              <hr />
              <div className="row"> <button onClick={nextPage} className="button-form-modal-sm">Next</button> </div>
              <div className="row"> <button onClick={() => setLgShow(false)} className="button-form-modal-sm">Close</button> </div>
              

            </center>





          </div>


        </Modal.Body>
      </Modal>
    </>
  );
});

export default CustomerSearch;