import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/products", { params,headers: authHeader() });
};

const getAllSearchByType = (params) => {
  return http.get("/productsearch", { params,headers: authHeader() });
};

const getAllSearchWithName = (params) => {
  return http.get("/productssearchwithname", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/product/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/products", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/product/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/product/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/tutorials`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const createInitialStock= () => {
   return http.get("/initialemptystock", { headers: authHeader() });
};

const getAvairableProductQty = (data) => {

 // return http.get("/getqty", { headers: authHeader() });

  return http.post("/avairableqty", data,{ headers: authHeader() });
};

const synchSupplyPrice = () => {
  return http.get(`/sync_supply_price`,{ headers: authHeader() });
};


const ProductsService = {
  getAll,
  getAllSearchByType,
  getAllSearchWithName,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  createInitialStock,
  getAvairableProductQty,
  synchSupplyPrice
};

export default ProductsService;
